<template>
<div>
        <div v-for="(form, idx) in data" :key="idx">
          <div class="font-bold text-gray text-xs mb-3">#Resolution {{(idx + 1)}}</div>
          <b-row>


            <b-col cols="12" sm="12" md="6" lg="6">
              <label class="text-gray text-xs">Organ</label>
              <b-form-group class="w-full">
                <b-form-select  disabled v-model="form.organ"  class="form-select noFocus text-gray text-xs">
                  <b-select-option :value="null">--Select Company Organ--</b-select-option>
                  <b-select-option value="BOARD">Board Meeting</b-select-option>
                  <b-select-option value="GENERAL_MEETING">General Meeting</b-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>
            <b-col cols="12" sm="12" md="6" lg="6">
              <label class="text-gray text-xs">Resolution Type</label>
              <b-form-group class="w-full">
                <b-form-select
                    disabled
                    class="form-select noFocus text-gray text-xs"
                    v-model="form.resolutionName">
                  <b-select-option :value="null">--Select Resolution Type--</b-select-option>
                  <b-select-option value="BOARD_RESOLUTION">Board Resolution</b-select-option>
                  <b-select-option value="SPECIAL_RESOLUTION">Special Resolution</b-select-option>
                  <b-select-option value="ORDINARY_RESOLUTION">Ordinary Resolution</b-select-option>
                </b-form-select>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="6">
              <label class="text-gray text-xs">Subject</label>
              <b-form-group
                  id="input-group-1"
                  label-for="input-1">
                <b-form-input
                    disabled
                    class="form-text noFocus text-xs"
                    type="text"
                    v-model="form.subject"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="6">
              <label class="text-gray text-xs">Date of Meeting</label>
              <b-form-group
                  id="input-group-1"
                  label-for="input-1">
                <b-form-input
                    disabled
                    class="form-text noFocus text-xs"
                    type="text"
                    v-model="form.dateOfMeeting"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>

            <b-col cols="12" sm="12" md="12" lg="6">
              <label class="text-gray text-xs">Venue of meeting</label>
              <b-form-textarea
                  disabled
                  id="venue"
                  class="form-text noFocus text-xs"
                  type="text"
                  v-model="form.venue"
                  required>
              </b-form-textarea>
            </b-col>

          </b-row>

         <div v-if="form.signatories && (form.signatories.length > 0)">
           <div class="font-bold text-gray text-xs mt-4 mb-1">#Signatories</div>

           <div v-for="(info, idx) in form.signatories" :key="'f'+idx">
             <div v-if="info.individual" class="flex">
               <div class="mr-2 text-gray mt-2">{{(idx+1)}}</div>

               <div style="width: 50%;">
                 <b-form-group
                     id="input-group-1"
                     label-for="input-1">
                   <b-form-input
                       disabled
                       class="form-text noFocus text-xs"
                       type="text"
                       v-model="info.individual.fullName"
                       required>
                   </b-form-input>
                 </b-form-group>
               </div>
             </div>
           </div>
         </div>

          </div>

</div>
</template>

<script>

export default {
  name: "AmalgamationSingleResolution",
  components: {

  },
  props: {
    data: {},
  },
  data() {
    return {
      form: {
        organ: null,
        resolutionName: null,
        subject: null,
        dateOfMeeting: null,
        venue: null,
      },
      disableOrgan: false,
      disableResolution: false,
    };
  },
  mounted() {
    this.fetchDraftData()
  },
  methods: {
 /*   disableWhenOrganIsBord(organ){
      if(organ){
        if(organ === 'BOARD'){
          this.form.resolutionName = 'BOARD_RESOLUTION'
          this.disableResolution = true
        }else {
          this.form.resolutionName = null
          this.disableResolution = false
        }

      }

    },*/
   /* fetchDraftData(){
      let draft = this.resolution
      if(draft){
        this.form = draft

        if(draft.organ === 'BOARD'){
          this.disableResolution = true
        }else {
          this.disableResolution = false
        }
      }
    }*/
  },
  watch: {
  /*  form: {
      handler: function (val) {
        val.index = this.index;
        this.$emit("getResolutionData", val);
      },
      deep: true,
    },
    resolution(data){
      if(data){
        this.fetchDraftData()
      }
    },*/
  },


};
</script>

<style scoped>

</style>