<template>
  <div class="mb-2 mt-1">
    <b-row>
      <b-col cols="12">

        <b-row class="mt-1">
          <b-col cols="12">
            <b-row>
              <b-col cols="12" sm="12" md="12" lg="6">
                <label class="text-gray text-xs">Total Nominal Share Capital</label>
                <b-form-group>
                  <b-form-input
                      @keypress="onlyNumberValidator($event)"
                      id="nominalShareCapital"
                      class="form-text mb-3 noFocus text-xs"
                      type="text"
                      disabled
                      v-model="form.newNominalShareCapital"
                      v-on:blur="computeCommaData(form.newNominalShareCapital)"
                      required>
                  </b-form-input>
                </b-form-group>
              </b-col>
              <b-col cols="12" sm="12" md="12" lg="6">
                <label class="text-gray text-xs">Total Number of Shares <i style="font-size: 10px"
                                                                                            class="fas fa-asterisk ml-1 text-red"></i></label>
                <b-form-group
                    id="input-group-1"
                    label-for="input-1">
                  <b-form-input
                      disabled
                      @keypress="onlyNumberValidator($event)"
                      class="form-text mb-3 noFocus text-xs"
                      type="text"
                      v-model="form.newNominalNumberOfShares"
                      v-on:blur="computeCommaDataShares(form.newNominalNumberOfShares)"
                      required>
                  </b-form-input>
                </b-form-group>
              </b-col>
            </b-row>


            <b-row>
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-2">
                  <thead>
                  <tr class="border-b border-t border-grey">
                    <th class="t-header">Share Class</th>
                    <th class="t-header">Number</th>
                    <th class="t-header">Value (UGX)</th>
                    <th class="t-header">Nominal Value (UGX)</th>
                    <th class="t-header text-center">Action</th>
                  </tr>
                  <tr v-if="errorMessage" class="bg-red py-1 text-white">
                    <th colspan="12" class="text-center">
                        <span class="font-bold">
                          {{errorMessage}}
                        </span>
                    </th>
                  </tr>
                  </thead>
                  <tbody v-for="(share, idx) in form.newNominalShareClassifications" :key="idx">
                  <tr class="bg-header_grey" >
                    <td class="t-body text-capitalize">
                      <b-row>
                        <b-col>
                          <b-form-group>
                            <b-form-select
                                id="entityType"
                                disabled
                                class="form-select noFocus text-gray text-xs"
                                v-model="share.nominalClass"
                                :options="classificationOptions">
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col cols="12" v-if="share.nominalClass === 'OTHER'">
                          <b-form-input
                              class="noFocus form-text"
                              placeholder="Name Share Class text-xs"
                              type="text"
                              disabled
                              v-model="share.otherClassName"
                              required>
                          </b-form-input>
                        </b-col>
                      </b-row>
                    </td>
                    <td class="t-body text-center">
                      <span v-if="share.subClasses && share.subClasses.length > 0" class="font-bold text-gray h5">{{share.number.toLocaleString()}}</span>
                      <b-form-input v-else
                                    @keypress="onlyNumberValidator($event)"
                                    class="noFocus form-select-sm text-xs"
                                    type="text"
                                    disabled
                                    v-model="share.number"
                                    v-on:blur="computeShares('shares', share.number, idx)"
                                    required>
                      </b-form-input>
                    </td>
                    <td class="t-body text-center">
                      <span v-if="share.subClasses && share.subClasses.length > 0" class="font-bold text-gray h5">{{share.value.toLocaleString()}}</span>
                      <b-form-input
                          v-else
                          @keypress="onlyNumberValidator($event)"
                          class="noFocus form-select-sm text-xs"
                          type="text"
                          disabled
                          v-model="share.value"
                          v-on:blur="computeShares('sharesValue', share.value, idx)"
                          required>
                      </b-form-input>
                    </td>
                    <td class="t-body text-center">
                      <span class="font-bold text-gray text-sm">{{share.totalNominalValue.toLocaleString()}}</span>
                    </td>
                    <td class="t-body text-center">
<!--                      <i style=" font-size: 20px; cursor: pointer;" @click="removeShares(idx)"  class="fas fa-times text-red"></i>-->
                    </td>
                  </tr>
                  <tr class="bg-header_grey" v-for="(subClass, idx2) in share.subClasses" :key="idx2">
                    <td>
                      <b-row class="px-2">
                        <b-col cols="4"></b-col>
                        <b-col cols="8">
                          <b-form-group>
                            <b-form-select
                                disabled
                                id="entityType"
                                class="form-select noFocus text-gray text-xs"
                                v-model="subClass.nominalClass">
                              <option value="">Sub Class</option>
                              <option value="A">Class A</option>
                              <option value="B">Class B</option>
                              <option value="C">Class C</option>
                            </b-form-select>
                          </b-form-group>
                        </b-col>
                      </b-row>
                    </td>
                    <td class="t-body">
                      <b-form-input
                          @keypress="onlyNumberValidator($event)"
                          class="noFocus form-select-sm text-xs"
                          type="text"
                          disabled
                          v-model="subClass.number"
                          v-on:blur="computeShares('subShares', subClass.number, idx, idx2)"
                          required>
                      </b-form-input>
                    </td>
                    <td class="t-body">
                      <b-form-input
                          @keypress="onlyNumberValidator($event)"
                          class="noFocus form-select-sm text-xs"
                          type="text"
                          disabled
                          v-model="subClass.value"
                          v-on:blur="computeShares('subSharesValue', subClass.value, idx, idx2)"
                          required>
                      </b-form-input>
                    </td>
                    <td class="t-body text-center">
                      <span class="font-bold text-gray text-sm">{{subClass.totalNominalValue.toLocaleString()}}</span>
                    </td>
                    <td></td>
                  </tr>
                  </tbody>
                  <tbody>
                  <tr class="bg-header_grey" >
                    <td class="t-body"></td>
                    <td class="t-body"></td>
                    <td class="t-body float-end">
                      <span class="font-bold text-sm text-gray">TOTAL</span>
                    </td>
                    <td class="t-body text-center">
                      <span class="font-bold text-green text-sm">{{totalValue.toLocaleString()}}</span><br/>
                      <span class="font-bold text-blue ml-3 text-sm">Available: {{Number(String(form.newNominalShareCapital).replace(/,/g, "") - totalValue).toLocaleString()}}</span>
                    </td>
                    <td class="t-body"></td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>

          </b-col>
        </b-row>






      </b-col>
    </b-row>




  </div>
</template>

<script>

export default {
  name: "SharesAmalgamation",
  components: {},
  props:{
    draftData:{}
  },
  data(){
    return{
      occupationList:[],
      errorMessage:null,
      selectedSubscriber:"",
      subscriberInfoList:[],
      subscriberInfoListInfo:[],
      loading:false,
      form: {
        newNominalShareCapital: null,
        newNominalNumberOfShares: null,
        newNominalShareClassifications: [],
      },
      formA1DataInfo:null,
      modalShareClass:false,
      isFullyClassified:false,
      totalShares:0,
      totalValue:0,
      share:{
        nominalClass: "",
        subClasses:[
          {
            nominalClass: "A",
            number: 0,
            value: 0,
            totalNominalValue: 0
          },
          {
            nominalClass: "B",
            number: 0,
            value: 0,
            totalNominalValue: 0
          },
          {
            nominalClass: "C",
            number: 0,
            value: 0,
            totalNominalValue: 0
          }
        ],
        otherClassName:null,
        number: 0,
        value: 0,
        totalNominal:0
      },
      nominalClassOther:null,
      directorPositionOptions: [
        { value: null, text: "--Select Position--" },
        { value: "DIRECTOR", text: "Director" },
        { value: "SECRETARY", text: "Secretary" },
        { value: "ADVOCATE", text: "Advocate" },
      ],
      classificationOptions: [
        { value: "", text: "--Select Class--" },
        { value: "ORDINARY", text: "Ordinary" },
        { value: "PREFERENTIAL", text: "Preferential" },
        { value: "OTHER", text: "Other" },
      ],
      numOfShares:null,
      tempNumOfShares:null,
      shareCapital:0,
      classifiedShares:0,
      entityType:"INDIVIDUAL",
      entityIndividualTypeList:[
        {
          value:"",
          text:"--Select Subscriber type--"
        },
        {
          value:"INDIVIDUAL",
          text:"Individual"
        },
        {
          value:"CORPORATE",
          text:"Entity"
        },
      ],
      newClassifications:[],
    }
  },
  mounted() {
    this.filterDraft()
  },
  methods:{
    filterDraft(){
      let metaData = this.draftData;
      this.form.newNominalNumberOfShares = metaData.newNominalNumberOfShares ? metaData.newNominalNumberOfShares : null
      this.form.newNominalShareCapital = metaData.newNominalShareCapital ?  metaData.newNominalShareCapital : null
      this.form.newNominalShareClassifications = metaData.newNominalShareClassifications ?  this.returnClassClassification(metaData.newNominalShareClassifications) : this.newClassifications
      this.routine()

    },
    routine(){
      if(this.form.newNominalShareCapital){
        let total = 0
        let sharesTotal = 0

        for(let i = 0; i < this.form.newNominalShareClassifications.length; i++){
          let shareClass = this.form.newNominalShareClassifications[i]
          shareClass.totalNominalValue = 0
          if(shareClass.subClasses && shareClass.subClasses.length > 0){
            shareClass.number = 0
            shareClass.value = 0
            for(let j = 0; j < shareClass.subClasses.length; j++){
              let subClass = shareClass.subClasses[j]
              if(subClass.number && subClass.value){
                subClass.totalNominalValue =  (subClass.number * 1) * (subClass.value * 1)
              }

              shareClass.totalNominalValue += (subClass.totalNominalValue * 1)
              shareClass.number += (subClass.number * 1)
            }
          }else {
            shareClass.totalNominalValue = (shareClass.number * 1) * (shareClass.value *1)
          }
          sharesTotal += parseFloat(this.form.newNominalShareClassifications[i].number)
          total += this.form.newNominalShareClassifications[i].totalNominalValue
        }


        this.totalValue = total
        // if(sharesTotal > this.form.newNominalNumberOfShares.replace(/,/g, "")){
          if(sharesTotal > String(this.form.newNominalNumberOfShares).replace(/,/g, "")){
          this.errorMessage = "Classified Shares more than Nominal Share Number"
        }else{
          if(total > String(this.form.newNominalShareCapital).replace(/,/g, "")){
            this.errorMessage = "Classification Total Nominal Value more than Share Capital"
          }else{
            this.errorMessage = null
          }
        }
      }else{
        this.errorMessage = "You have no share capital. Please go back to Form S18 and add it"
      }


      if(this.form.newNominalShareClassifications && this.form.newNominalShareClassifications.length > 0){
        this.form.newNominalShareClassifications.forEach(classes => {

          let totalSubValue = 0
          let totalSubNumber = 0
          if(classes.subClasses && classes.subClasses.length > 0){
            classes.subClasses.forEach(sub_classes => {
              sub_classes.value = (sub_classes.value * 1)
              sub_classes.number = (sub_classes.number * 1)
              totalSubValue = totalSubValue + (sub_classes.value * 1)
              totalSubNumber = totalSubNumber + (sub_classes.number * 1)
            })
            classes.value = totalSubValue > 0 ? totalSubValue : classes.value
            classes.number = totalSubNumber > 0 ? totalSubNumber : classes.number
          }
        })
      }

      this.isFullyClassified = (parseFloat(String(this.form.newNominalShareCapital).replace(/,/g, "")).toFixed(0) - this.totalValue.toFixed(0)) === 0;


    },
    getSubscriberData(data){
      this.updateSubscriberData(data.data)
    },
    corporateData(data){
      this.corporateInfo(data.data)
    },
    corporateInfo(data){
      if(!this.form.advocate){
        this.form.advocate = {
          entityType:"CORPORATE",
          individual: null,
          corporate: null
        }
      }
      if(data) {
        this.form.advocate.corporate = data
        this.form.advocate.entityType = "CORPORATE"
        this.form.advocate.individual = null
      }
    },
    updateSubscriberData(data){
      if(!this.form.advocate){
        this.form.advocate = {
          entityType:"CORPORATE",
          individual: null,
          corporate: null
        }
      }
      if(data) {
        this.form.advocate.individual = data
        this.form.advocate.entityType = "INDIVIDUAL"
        this.form.advocate.corporate = null
      }
    },
    computeCommaData(data){
      this.form.newNominalShareCapital = this.returnComputedData(data) // this.addCommasToNumber(data)
    },
    computeCommaDataShares(data){
      this.form.newNominalNumberOfShares = this.returnComputedData(data)
    },
    returnComputedData(data){
      let value = data.replace(/,/g, "");
      try {
        // Use Function constructor to safely evaluate the expression
        value = new Function(`return ${value}`)();
        return value.toLocaleString();
      } catch (error) {
        // return "Invalid expression";
      }
    },

    computeShares(){
      this.routine()
    },
    returnClassClassification(arr1){
      return  arr1.map(item => {
        const baseItem = {
          nominalClass: item.nominalClass,
          otherClassName: item.otherClassName,
          number: String(item.number),
          value: String(item.value), // Keep the original value
          totalNominalValue: item.totalNominalValue // Keep the original totalNominalValue
        };

        // If there are subClasses, convert them as well
        if (item.subClasses) {
          baseItem.subClasses = item.subClasses.map(subItem => ({
            nominalClass: subItem.nominalClass,
            otherClassName: subItem.otherClassName,
            number: String(subItem.number),
            value: String(subItem.value), // Keep original value for subItem
            totalNominalValue: subItem.totalNominalValue // Keep original totalNominalValue for subItem
          }));
        } else {
          baseItem.subClasses = []; // Initialize as empty array if there are no subClasses
        }

        return baseItem;
      });
    }
  },
  watch:{
    draftData(data) {
      if (data) {
        this.filterDraft()
      }
    }
  }
}
</script>

<style scoped>

.button {
  @apply bg-blue text-white py-2 rounded;
}

.button:hover {
  @apply bg-blue-dark;
}


.back-button {
  @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
  @apply bg-gray text-white;
}
</style>