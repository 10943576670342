<template>
  <b-row>
    <b-col>

      <b-row class="mt-1">
        <b-col >
            <table class="min-w-full leading-normal mt-1 text-gray"
                   style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Current Members</td>
                </tr>
                </thead>
                <tbody>
            <BSIMembers class="p-1" :persons="buildingSociety.members"/>
                </tbody>
            </table>
        </b-col>
        <b-col>

            <b-row class="mt-2">
                <b-col cols="12">
                    <table class="min-w-full leading-normal mt-1 text-gray"
                           style="border: solid 1px #dedede;">
                        <thead>
                        <tr class="bg-gray-pale">
                            <td colspan="12" class="px-2 py-2 font-bold">New Members</td>
                        </tr>
                        </thead>
                        <tbody>
                        <BSIMembers class="p-1" :persons="data.members"/>
                        </tbody>
                    </table>
                </b-col>
            </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import BSIMembers from "@/views/home/services/bsi_incorporation/BSIMembers.vue";
import {api} from "@/app/services/api.service";

export default {
  name: "BsiChangeOfMembership",
  components: {BSIMembers },
  props:{
    data:Object,
      brn: String,

  },
    data(){
        return{
        buildingSociety:{
            members:[]
        }
        }
    },
  mounted() {
      this.fetchBuildingSocietyMembers(this.brn)

  },
  methods:{
      async fetchBuildingSocietyMembers(brn) {
          await api()
              .get("building-societies/societies/registry/get-registered-members/" + brn)
              .then(response => {
                  this.buildingSociety.members = response.data.data;
              }).catch(error => {
                  this.errorToast(JSON.stringify(error.response));
              });
      },
    ...mapActions(["fetchCompanySubscribers"]),
  },
  computed:{
    ...mapGetters(["getCompanySubscribers"])
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}

.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>