<template>
    <b-col>
        <b-row v-if="isFiled">
            <b-overlay :show="loading" rounded="sm">
                <b-col v-if="bsiAddress" cols="12" class="mx-auto mt-1">

                    <label class="text-sm text-blue font-bold">Physical Address</label>
                    <b-row class="mx-1 py-1">
                        <b-col>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Country"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.address.physicalAddress.country.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Region"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.address.physicalAddress.region.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="District"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.address.physicalAddress.district.name.toLowerCase()"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Sub County"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.address.physicalAddress.subcounty.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Parish"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.address.physicalAddress.parish.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Village"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.address.physicalAddress.village.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Plot"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.address.plot"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Street"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.address.street"
                                        required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Postal Address"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.address.postalAddress"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Description"
                                    label-for="input-1">
                                <b-form-textarea
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        rows="4"
                                        disabled="true"
                                        :value="bsiAddress.address.description"
                                        required>
                                </b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                    <label class="text-sm text-blue font-bold">Meeting Place </label>
                    <b-row class="mx-1 py-1">
                        <b-col>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Country"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.physicalAddress.country.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Region"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.physicalAddress.region.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="District"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.physicalAddress.district.name.toLowerCase()"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Sub County"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.physicalAddress.subcounty.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Parish"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.physicalAddress.parish.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Village"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.physicalAddress.village.name"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Plot"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.plot"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Street"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.street"
                                        required>
                                </b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Postal Address"
                                    label-for="input-1">
                                <b-form-input
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.postalAddress"
                                        required>
                                </b-form-input>
                            </b-form-group>
                            <b-form-group
                                    class="text-sm my-1"
                                    id="input-group-1"
                                    label="Description"
                                    label-for="input-1">
                                <b-form-textarea
                                        id="companyName"
                                        class="form-text text-sm noFocus text-capitalize"
                                        type="text"
                                        rows="4"
                                        disabled="true"
                                        :value="bsiAddress.meetingPlace.description"
                                        required>
                                </b-form-textarea>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </b-col>
            </b-overlay>
        </b-row>
    </b-col>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {api} from "@/app/services/api.service";

export default {
    name: "BsiAddress",
    props: {
        brn: String
    },
    data() {
        return {
            isFiled: true,
            lockDown: false,
            loading: false,
            bsiAddress: null
        }
    },
    mounted() {
        this.fetchAddress(this.brn)
    },
    methods: {
        ...mapActions(["fetchCompanyAddress", "fetchFilingCompliance"]),
        async fetchAddress(brn) {
            this.loading = true
            api().get("/building-societies/societies/registry/get-registered-address/" + brn).then(response => {
                this.bsiAddress = response.data.data;
                this.loading = false
            }).catch(error => {
                this.errorToast(JSON.stringify(error.response));
                this.loading = false
            });

        },
    },
        computed: {
            ...mapGetters(["getCompanyAddress", "getFilingCompliance"])
        },
        watch: {
            getCompanyAddress() {
                this.loading = false
            },
            getFilingCompliance(data) {
                this.lockDown = this.determineLockDown(data.data)
            }
        }
    }
</script>

<style scoped>

</style>