<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Summary of changes
          </b-badge>
            <table class="min-w-full leading-normal my-2 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr>

                    <th class="px-2 py-1">#</th>
                    <th class="px-2 py-1">Changed</th>
                    <th class="px-2 py-1">From</th>
                    <th class="px-2 py-1">To</th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="(change, idx) in data.changes" :key="idx" style="border: solid 1px #dedede;">
                    <td class="px-2 py-1">{{idx+1}}</td>
                    <td class="px-2 py-1">{{cleanUnderscores(change.type)}}</td>
                    <td class="px-2 py-1 text-capitalize">{{change.from}}</td>
                    <td class="px-2 py-1 text-capitalize">{{change.to}}</td>

                </tr>
                </tbody>
            </table>
        </b-col>
      </b-row>
      <b-row class="mt-1">
        <b-col cols="6">
            <b-card header="Current Address">
          <BsiAddress  :brn="brn" ></BsiAddress>
            </b-card>
        </b-col>
        <b-col cols="6">
          <BsiForm18Resolution badge="New Address" :data="data"></BsiForm18Resolution>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import BsiForm18Resolution from "@/views/home/services/continuous-filing/task/forms/BsiForm18Resolution.vue";
import BsiAddress from "@/views/home/services/registry/business/files/tabs/company_data/BsiAddress.vue";
export default {
  name: "BsiChangeOfAddress",
  components: {BsiAddress, BsiForm18Resolution },
  props:{
    data:Object,
    brn:String
  },
  mounted() {

  },
  methods:{
    ...mapActions(["fetchCompanyAddress"])
  },
  computed:{
    ...mapGetters(["getCompanyAddress"])
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}

.pdf{
  width: 100%;
  height: 800px;
  overflow-x: auto;
  border:1px solid #b1b1b1;
}
</style>