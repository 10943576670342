<template>
  <div>
    <b-row class="mb-2">
      <b-col cols="6">
        <div>
          <label class="text-gray text-xs">Reference Date</label>
          <b-form-group>
            <b-form-input
                class="form-text text-xs noFocus"
                type="text"
                :disabled="true"
                v-model="form.amalgamationReferenceDate"
                required>
            </b-form-input>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="6">

      </b-col>
    </b-row>

    <div>
      <div class="mb-4">
        <p class="font-bold text-gray text-xs">Registered Office Address</p>
        <hr />
      </div>



      <amalgamation-office-and-records :addressInfo="registeredOfficeDataInfo" @addressRecords="registeredOfficeInfo"></amalgamation-office-and-records>
    </div>

    <div>
      <div class="mb-4">
        <p class="font-bold text-gray text-xs">Records Address</p>
        <hr />
      </div>
      <amalgamation-office-and-records :addressInfo="recordsAddressDataInfo" @addressRecords="recordsAddressInfo"></amalgamation-office-and-records>
    </div>

    <div>
      <div class="text-gray">
        <p class="font-bold text-gray text-xs">Restrictions, if any of the amalgamated company’s capacity and powers:</p>
        <hr />
      </div>
      <div class="mb-2">
        <restrictions-internal-management :draft="restrictionsDataInfo" @restrictionsInternal="restrictionsInfo" type="Restriction"></restrictions-internal-management>
      </div>
    </div>

    <div>
      <div class="text-gray">
        <p class="font-bold text-gray text-xs">Any matters relating to the internal management of the amalgamated company:</p>
        <hr />
      </div>
      <div class="mb-2">
        <restrictions-internal-management :draft="internalManagementDataInfo" @restrictionsInternal="internalManagementInfo" type="Internal Management"></restrictions-internal-management>
      </div>
    </div>


  </div>
</template>

<script>

import {api} from "@/app/services/api.service";
import AmalgamationOfficeAndRecords
  from "@/views/home/services/continuous-filing/task/components/AmalgamationOfficeAndRecords.vue";
import RestrictionsInternalManagement
  from "@/views/home/services/continuous-filing/task/components/RestrictionsInternalManagement.vue";

export default {
  name: "OfficeAndRecords",
  components: {RestrictionsInternalManagement, AmalgamationOfficeAndRecords},
  props:{
    draft:String,
  },
  data(){
    return{
      loading:false,
      searchLoading:false,
      brn: null,
      trackingNo: null,
      form: {
        registeredOffice: {
          plot: null,
          street: null,
          postalAddress: null,
          description: null,
        },
        registeredOfficePhysicalAddressId: null,
        recordsAddress: {
          plot: null,
          street: null,
          postalAddress: null,
          description: null,
        },
        recordsPhysicalAddressId: null,
        amalgamationReferenceDate: null,
        restrictions: [],
        internalManagement: [],
        formCode: null
      },
      restrictionsDataInfo:[],
      internalManagementDataInfo:[],
      registeredOfficeDataInfo:{},
      recordsAddressDataInfo:{}
    }
  },
  mounted() {
    this.filterDraft()
  },
  methods:{
    filterDraft(){
      let info = this.draft
      if(info){


          this.form.amalgamationReferenceDate = info.amalgamationReferenceDate;
          this.form.registeredOffice = info.registeredOffice;
          this.form.registeredOfficePhysicalAddressId = info.registeredOfficePhysicalAddressId;
          this.form.recordsAddress = info.recordsAddress;
          this.form.recordsPhysicalAddressId = info.recordsPhysicalAddressId;
          this.form.restrictions = info.restrictions;
          this.form.internalManagement = info.internalManagement;

          this.registeredOfficeDataInfo = info.registeredOffice;
          this.registeredOfficeDataInfo.address = info.recordsPhysicalAddress

          this.recordsAddressDataInfo = info.recordsAddress;
          this.recordsAddressDataInfo.address = info.registeredOfficePhysicalAddress;


          this.restrictionsDataInfo = info.restrictions;
          this.internalManagementDataInfo = info.internalManagement;


      }
    },
    registeredOfficeInfo(data){
      if(data){
        this.form.registeredOfficePhysicalAddressId = data.villageId;
        this.form.registeredOffice = data.address;
      }

    },
    recordsAddressInfo(data){
      if(data){
        this.form.recordsPhysicalAddressId = data.villageId;
        this.form.recordsAddress = data.address;
      }
    },
    restrictionsInfo(data){
      if(data) {
        this.form.restrictions = data;
      }
    },
    internalManagementInfo(data) {
      if (data) {
        this.form.internalManagement = data;
      }
    },
    onSubmit(){
      this.loading = true
      let formData = {}
      let formInfo = this.form

      formInfo.amalgamationReferenceDate = String(this.form.amalgamationReferenceDate).includes('T')
          ? this.form.amalgamationReferenceDate.toISOString().split('T')[0]
          : this.form.amalgamationReferenceDate;


      formData.form = formInfo
      formData.trackingNo = this.trackingNo
      this.saveMetaData(formData)
    },
    async saveMetaData(data) {
      await api().post("/incorporation/companies/continuous-filing/file-amalgamation/registered-office-and-reference", data)
          .then((response) => {
            if(response) {
              let trackingNo = response.data.data
              // sessionStorage.setItem("tracking-number", trackingNo)
              this.$emit("nextTab", trackingNo);
              this.loading = false;
            }
          })
          .catch((error) => {
            if(error && error.response) {
              this.loading = false;
              this.errorToast("Error", error.response.data.message);
            }
          });
    },

  },
  watch:{
    draft(data) {
      if (data) {
        this.filterDraft()
      }
    }
  }
}
</script>

<style scoped>

.resolution {
  @apply border border-header_grey shadow px-2 pt-3 pb-5 my-2 rounded mb-4;
}

.inert-button {
  @apply  bg-gray text-white py-2 rounded;
}

.button {
  @apply bg-blue text-white py-2 rounded;
}

.button:hover {
  @apply bg-blue-dark;
}

.back-button {
  @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
  @apply bg-gray text-white;
}

.alertStyle {
  border: 1px solid red;
}
</style>



