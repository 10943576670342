<script>
export default {
  name: "RestrictionsInternalManagement",
  props: {
    draft:[]
  },
  data() {
    return {
      loading: false,
      reasonsList: [],
      form: [
        {
          text: null,
          formIndex: 1
        }
      ],
    }
  },
  mounted() {
    this.filterData()
  },
  methods: {
    filterData(){
      if(this.draft && (this.draft.length > 0)){
        this.form = this.draft
      }
    }
  },
  watch:{
    draft(data){
      if(data){
       this.filterData()
      }
    }
  }
}
</script>

<template>
  <div>
    <b-row class="mt-3">
      <b-col cols="12">
        <div>

          <b-row class="mt-2">
            <b-col cols="6" v-for="(list, index) in form" :key="index">
              <div>
                <b-input-group>
                  <b-form-textarea
                      maxlength="1000"
                      id="reasons"
                      class="form-text noFocus text-xs"
                      type="text"
                      disabled
                      v-model="list.text"
                      required>
                  </b-form-textarea>
                </b-input-group>
              </div>
            </b-col>
          </b-row>

        </div>
      </b-col>
    </b-row>
  </div>
</template>

<style scoped>
/* Add your scoped styles here */
</style>