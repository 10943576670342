import { render, staticRenderFns } from "./AmalgamationContinuousFilingFiles.vue?vue&type=template&id=8ce4ef50&scoped=true&"
import script from "./AmalgamationContinuousFilingFiles.vue?vue&type=script&lang=js&"
export * from "./AmalgamationContinuousFilingFiles.vue?vue&type=script&lang=js&"
import style0 from "./AmalgamationContinuousFilingFiles.vue?vue&type=style&index=0&id=8ce4ef50&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "8ce4ef50",
  null
  
)

export default component.exports