<template>
  <div class="mb-2">
    <b-row>
      <b-col cols="12">
        <b-row>
          <b-col>
            <b-row>
              <b-col>
                <span class="font-bold text-xs">
                  Current Shares Information
                </span>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="6" class="text-xs border mt-2 rounded ml-2">
                <b-table-simple hover small responsive>
                  <b-tbody>
                    <b-tr>
                      <b-td colspan="6">
                        <span>Total Number of shares</span>
                      </b-td>
                      <b-td colspan="6">
                        <span class="ml-2">{{totalNumberOfShares.toLocaleString()}}</span>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-td colspan="6">
                        <span>Remaining Shares</span>
                      </b-td>
                      <b-td colspan="6">
                        <span class="ml-2">{{(parseInt(sharesRemaining) - parseInt(totalNumberOfShares)).toLocaleString()}}</span>
                      </b-td>
                    </b-tr>

                    <b-tr>
                      <b-td colspan="6">
                        <span>Total Amount</span>
                      </b-td>
                      <b-td colspan="6">
                        <span class="ml-2">{{totalAmount.toLocaleString()}}</span>
                      </b-td>
                    </b-tr>

                  </b-tbody>
                </b-table-simple>
              </b-col>
            </b-row>
          </b-col>
        </b-row>



        <b-row class="my-2">
          <b-col>
            <p class="font-bold text-xs text-uppercase mb-2 text-gray">
              Particulars of Allotment
            </p>
          </b-col>
        </b-row>

        <b-alert variant="danger" show class="p-2 text-sm" v-if="(parseInt(sharesRemaining) < parseInt(totalNumberOfShares))">
         Total number of allowed shares should not exceed the total number of shares in the company.
        </b-alert>


          <b-row class="mb-3 border rounded shadow-sm mx-auto" v-for="(allotment, idx1) in form.allotments" :key="idx1">

            <b-col cols="12">
              <b-row>
                <b-col cols="12">
                  <table class="text-xs">
                    <thead>
                    <tr class="border-b border-grey">
                      <th class="px-2 text-blue" colspan="12">
                        <div class="font-semibold text-gray capitalize">{{allotment.name ? allotment.name : ""}} </div>
                      </th>
                    </tr>
                    <tr class="border-b border-t border-grey">
                      <th class="px-2 py-1 text-xs w-[5%]">
                       #
                      </th>
                      <th class="px-2 py-1 text-xs text-uppercase border-grey">
                        Share Class
                      </th>
                      <th class="px-2 py-1 text-xs text-uppercase text-center border-grey">
                        Shares (In Cash)
                      </th>
                      <th class="px-2 py-1 text-xs text-uppercase text-center border-grey">
                        Shares (Non Cash)
                      </th>
                      <th class="px-2 py-1 text-xs text-uppercase text-center border-grey">
                        Non Cash Consideration
                      </th>
                      <th class="px-2 py-1 text-xs text-uppercase border-grey">
                        Total Shares
                      </th>
                      <th class="px-2 py-1 text-xs text-uppercase border-grey">
                       Amount Paid (Cash)
                      </th>
                      <th class="px-2 py-1 text-xs text-uppercase border-grey">
                        Amount Paid (Non Cash)
                      </th>
                      <th class="px-2 py-1 text-xs text-uppercase border-grey">
                        Total Amount paid
                      </th>
                    </tr>
                    </thead>
                    <tbody v-for="(allot, idx2) in allotment.shares" :key="idx2">

                    <tr>
                      <td>
                        {{(idx2 + 1)}}
                      </td>
                      <td>
                        {{allot.nominalClass ? allot.nominalClass : 'OTHER'}}
                      </td>
                      <td>
                        <b-form-input
                            @keypress="onlyNumberValidator($event)"
                            disabled
                            class="form-text text-xs shadow-none"
                            type="text"
                            placeholder="Amount Paid Non Cash"
                            v-model="allot.numberOfSharesPaidInCash">
                        </b-form-input>
                      </td>
                      <td>
                        <b-form-input
                            @keypress="onlyNumberValidator($event)"
                            disabled
                            class="form-text text-xs shadow-none"
                            type="text"
                            placeholder="Amount Paid Non Cash"
                            v-model="allot.numberOfSharesPaidInNonCashConsiderations"
                        ></b-form-input>
                      </td>
                      <td>
                        <b-form-textarea
                            disabled
                            size="sm"
                            v-model="allot.nonCashConsiderationDescription"
                            class="noFocus text-xs"
                            required
                            style="height: 40px;"
                            rows="1"
                            type="text">
                        </b-form-textarea>
                      </td>

                      <td>
                       <div class="ml-2">{{allot.numberOfShares ? allot.numberOfShares.toLocaleString() : 0}}</div>
                      </td>

                      <td>
                        <div class="ml-2">{{allot.amountPaidInCash ? allot.amountPaidInCash.toLocaleString() : 0}}</div>
                      </td>

                      <td>
                        <div class="ml-2"> {{allot.amountPaidInNonCash ? allot.amountPaidInNonCash.toLocaleString() : 0}}</div>
                      </td>

                      <td>
                        <div class="ml-2">{{allot.amountPaid ? allot.amountPaid.toLocaleString() : 0}}</div>
                      </td>


                    </tr>


                 <tr v-for="(sub, idx3) in allot.subClasses" :key="'j'+idx3">
                            <td>
&nbsp;
                            </td>
                            <td style="width: 6%;">
                                <div  style="margin-left: 10px;">
                                  {{sub.nominalClass ? sub.nominalClass : 'OTHER'}}
                                </div>
                            </td>
                            <td style="width: 14%;">
                              <b-form-input
                                  disabled
                                  @keypress="onlyNumberValidator($event)"
                                  class="form-text text-xs shadow-none"
                                  type="text"
                                  placeholder="Amount Paid Non Cash"
                                  v-model="sub.numberOfSharesPaidInCash"
                                  @blur="calculateSubclasses(allot.subClasses, idx1, idx2)"
                              ></b-form-input>
                            </td>
                            <td class="" style="width: 14%;">
                              <b-form-input
                                  disabled
                                  @keypress="onlyNumberValidator($event)"
                                  class="form-text text-xs shadow-none"
                                  type="text"
                                  placeholder="Amount Paid Non Cash"
                                  v-model="sub.numberOfSharesPaidInNonCashConsiderations"
                                  @blur="calculateSubclasses(allot.subClasses, idx1, idx2)"
                              ></b-form-input>
                            </td>
                            <td style="width: 15%;">
                              <div class="ml-2">&nbsp;</div>
                            </td>
                            <td style="width: 9%;">
                              <div class="ml-2">{{sub.numberOfShares ? sub.numberOfShares.toLocaleString() : 0}}</div>
                            </td>

                            <td style="width: 12%;">
                              <div class="ml-2">{{sub.amountPaidInCash ? sub.amountPaidInCash.toLocaleString() : 0}}</div>
                            </td>

                            <td style="width: 14%;">
                              <div class="ml-2"> {{sub.amountPaidInNonCash ? sub.amountPaidInNonCash.toLocaleString() : 0}}</div>
                            </td>

                            <td>
                              <div class="ml-2">{{sub.amountPaid ? sub.amountPaid.toLocaleString() : 0}}</div>
                            </td>

                          </tr>


                    </tbody>

                  </table>
                </b-col>

              </b-row>
            </b-col>


          </b-row>


      </b-col>
    </b-row>

  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {api} from "@/app/services/api.service";

export default {
  name: "AmalgamationShareholdingForm10",
  props:{
    draftData:{}
  },
  data(){
    return{
      isNotGenerated:true,
      errorMessage:null,
      errorStatus:false,
      noOfSharesIndex:0,
      amountPaidIndex:0,
      amountUnpaidIndex:0,
      rightsAttachingIndex:0,
      allottedShares:0,
      calculating:false,
      totalNumberOfShares:0,
      totalAmount:0,
      sharesRemaining:0,
      form: {
        nominalAmount: 0,
        nonCashSharesAllotted: 0,
        sharesPayable: 0,
        allotments: [
          {
            shareholderId: null,
            shares: [
              {
                classificationId: null,
                nominalClass: null,
                numberOfSharesPaidInCash: 0,
                numberOfSharesPaidInNonCashConsiderations: 0,
                nonCashConsiderationDescription: null,
                subClasses: null
              }
            ]
          },
        ]
      },
      form1:{
        returnDateFrom:null,
        returnDateTo:null,
        unAllocated:[],
        sharesPayable: 0,
        nominalAmount: 0,
        amountPaidDueAndPayablePerShare: 0,
        amountPaidPerShare: 0,
        nonCashSharesAllotted: 0,
        considerationDescription: null,
        allotments:[],
        shareCapital:{
          numberOfShares: [],
          amountPaid: [],
          amountUnPaid: [],
          totalNominalValue: [],
          rightsAttachingToShares: [],
        },
        edited:false,
        formCode:"form10"
      },
      classifications:[],
      shareholders:[],
      shareholderOptions:[],
      selectedShareHolder:null,
      numberOfShares: 0,
      classValues:Map,
      draft:null,
      sharesStructure:null,
      loading:false,
    }
  },
  mounted(){
    this.filterDraft()
  },
  methods:{
    ...mapActions(
        [
          "fetchCompanyShareClassification",
          "fetchCompanySubscribers",
          "fetchCompanyShareholding",
        ]
    ),
    prev(){
      this.$emit("backTab");
    },
    onSubmit(){
      this.loading = true
      let formData = {}
      formData.form = this.form
      formData.trackingNo = this.trackingNo
      this.saveShareholding(formData)
    },
    async saveShareholding(data) {
      await api().post("/incorporation/companies/continuous-filing/amalgamation-shareholding", data)
          .then((response) => {
            if(response) {
              let trackingNo = response.data.data
              sessionStorage.setItem("tracking-number", trackingNo)
              this.$emit("nextTab", trackingNo);
              this.loading = false;
            }
          })
          .catch((error) => {
            if(error && error.response) {
              this.loading = false;
              this.errorToast("Error", error.response.data.message);
            }
          });
    },
    calculateSubclasses(subClasses, idx1, idx2){

     let totalNumberOfSharesPaidInCash = 0
      let totalNumberOfSharesPaidInNonCashConsiderations =  0
      let totalAmountPaidInCash = 0
      let totalAmountPaidInNonCash = 0

      subClasses.forEach(subclass =>{
        totalNumberOfSharesPaidInCash += Number(subclass.numberOfSharesPaidInCash)
        totalNumberOfSharesPaidInNonCashConsiderations += Number(subclass.numberOfSharesPaidInNonCashConsiderations)
        totalAmountPaidInCash += Number(subclass.amountPaidInCash)
        totalAmountPaidInNonCash += Number(subclass.amountPaidInNonCash)
      })

      this.form.allotments[idx1].shares[idx2].numberOfSharesPaidInCash = totalNumberOfSharesPaidInCash
      this.form.allotments[idx1].shares[idx2].numberOfSharesPaidInNonCashConsiderations = totalNumberOfSharesPaidInNonCashConsiderations
      this.form.allotments[idx1].shares[idx2].amountPaidInCash = totalAmountPaidInCash
      this.form.allotments[idx1].shares[idx2].amountPaidInNonCash = totalAmountPaidInNonCash

     },
    addAllotment(selectedShareHolder){
      if(selectedShareHolder){

        if(!this.form.allotments[0].shareholderId){
          this.form.allotments = []
        }


        let sharesArr = []
        this.sharesStructure.forEach(lst => {
          let subClasses = []
          if(lst.subClasses && lst.subClasses.length > 0){
            lst.subClasses.forEach(sub => {
              subClasses.push({
                classificationId: sub.classificationId,
                nominalClass: sub.displayName,
                numberOfSharesPaidInCash: 0,
                numberOfSharesPaidInNonCashConsiderations: 0,
                nonCashConsiderationDescription: null,
              })
            })

          }
          sharesArr.push({
            classificationId: lst.classificationId,
            nominalClass: lst.displayName,
            numberOfSharesPaidInCash: 0,
            numberOfSharesPaidInNonCashConsiderations: 0,
            nonCashConsiderationDescription: null,
            subClasses: subClasses
          })
        })

          let obj = {
              shareholderId: selectedShareHolder,
              shares: sharesArr
            }


        const exists = this.form.allotments.some(allotment => allotment.shareholderId === selectedShareHolder);

        if (!exists) {
          this.form.allotments.push(obj);
        }

      }
    },
    doShareCalculations(val){
      if(val){
        let draft = [...this.form.allotments]
        let sharesCount = 0
        let amountCount = 0
        draft.forEach(allotment => {
          allotment.shares.forEach(share => {
            sharesCount += (Number(share.numberOfSharesPaidInCash) + Number(share.numberOfSharesPaidInNonCashConsiderations))
            share.numberOfShares = (Number(share.numberOfSharesPaidInCash) + Number(share.numberOfSharesPaidInNonCashConsiderations))
            // share.amountPaidInCash = (Number(share.numberOfSharesPaidInCash) * Number(share.classificationValue))
            // share.amountPaidInNonCash = (Number(share.numberOfSharesPaidInNonCashConsiderations) * Number(share.classificationValue))
            share.amountPaid = (Number(share.amountPaidInCash) + Number(share.amountPaidInNonCash))
            amountCount += (Number(share.amountPaidInCash) + Number(share.amountPaidInNonCash))
            if(share.subClasses && (share.subClasses.length > 0)){
              share.subClasses.forEach(sub => {
                sub.numberOfShares = (Number(sub.numberOfSharesPaidInCash) + Number(sub.numberOfSharesPaidInNonCashConsiderations))
                sub.amountPaidInCash = (Number(sub.numberOfSharesPaidInCash) * Number(sub.classificationValue))
                sub.amountPaidInNonCash = (Number(sub.numberOfSharesPaidInNonCashConsiderations) * Number(sub.classificationValue))
                sub.amountPaid = (Number(sub.amountPaidInCash) + Number(sub.amountPaidInNonCash))
              })
            }else {
              share.amountPaidInCash = (Number(share.numberOfSharesPaidInCash) * Number(share.classificationValue))
              share.amountPaidInNonCash = (Number(share.numberOfSharesPaidInNonCashConsiderations) * Number(share.classificationValue))
            }
          })
        })
        this.totalAmount = amountCount
        this.totalNumberOfShares = sharesCount
      }
    },
    filterDraft(){
      let data = this.draftData;
      if (data) {
        this.loading = false;
        this.draftData = data;
        this.trackingNo = data.trackingNo;

        if(data && data.AMALGAMATION_SHAREHOLDING) {
          let metaData = data.AMALGAMATION_SHAREHOLDING.data;
          this.form.allotments = metaData.allotments;
        }

        if(data && data.AMALGAMATION_SHARE_STRUCTURE) {
          let metaData = data.AMALGAMATION_SHARE_STRUCTURE.data;
          this.sharesRemaining = metaData.newNominalNumberOfShares;
        }
      }

    },
  },
  computed:{
    ...mapGetters(["getCompanyShareClassification", "getCompanySubscribers", "getCompanyShareholding"])
  },
  watch:{
    draftData(data) {
      if (data) {
        this.filterDraft()
      }
    },
    form:{
      deep:true,
      handler(val){
       if(val){
         this.doShareCalculations(val)
       }
      }
    },
   /* getFilingDraftDataInfo(data) {
      if (data) {
        this.loading = false;
        this.draftData = data;
        this.trackingNo = data.trackingNo;

        if(data.embeddedChanges && data.embeddedChanges.AMALGAMATION_SHAREHOLDING) {
          let metaData = data.embeddedChanges.AMALGAMATION_SHAREHOLDING.data;
          this.form.allotments = metaData.allotments;
        }

        if(data.embeddedChanges && data.embeddedChanges.AMALGAMATION_SHARE_STRUCTURE) {
          let metaData = data.embeddedChanges.AMALGAMATION_SHARE_STRUCTURE.data;
          this.sharesRemaining = metaData.newNominalNumberOfShares;
        }
      }
    },*/
  }
}
</script>

<style scoped>
@import "../../../../../../assets/styles/table.css";

th td{
  font-size: 12px;
}

.button {
  @apply bg-blue text-white py-2 rounded;
}

.button:hover {
  @apply bg-blue-dark;
}

.back-button {
  @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
  @apply bg-gray text-white;
}

</style>