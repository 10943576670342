<template>
  <b-row class="py-2">
    <b-col>

      <b-row>
        <b-col>
          <b-badge class="bg-blue">
            <i class="fa fa-file mr-2"></i>
            Amalgamation
          </b-badge>
        </b-col>
      </b-row>

      <b-row>
        <b-col>

        <div>

          <div class="mb-3 mt-3">
            <div class="border rounded-md border-gray-200">
              <div class="bg-gray-pale">
                <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Additional Resolutions</p>
              </div>
              <div class="p-2">

                  <AmalgamationSingleResolution
                      v-if="application.data.embeddedResolutions && (application.data.embeddedResolutions.length > 0)"
                      :data="application.data.embeddedResolutions"
                  />

              </div>
            </div>
          </div>

          <div class="mb-3 mt-3">
            <div class="border rounded-md border-gray-200">
              <div class="bg-gray-pale">
                <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Amalgamation Companies</p>
              </div>
              <div class="p-2">
                <amalgamation-meta-data :draftData="draftData"></amalgamation-meta-data>
              </div>
            </div>
          </div>


          <div class="mb-3 mt-3">
            <div class="border rounded-md border-gray-200">
              <div class="bg-gray-pale">
                <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Share Classification</p>
              </div>
              <div class="p-2">
                <shares-amalgamation :draftData="draftData.AMALGAMATION_SHARE_STRUCTURE.data"></shares-amalgamation>
              </div>
            </div>
          </div>


          <div class="mb-3 mt-3">
            <div class="border rounded-md border-gray-200">
              <div class="bg-gray-pale">
                <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Shareholding</p>
              </div>
              <div class="p-2">
                <amalgamation-shareholding-form10
                    :draftData="draftData"
                ></amalgamation-shareholding-form10>
              </div>
            </div>
          </div>


          <div class="mb-3 mt-3">
            <div class="border rounded-md border-gray-200">
              <div class="bg-gray-pale">
                <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Particulars of individual directors</p>
              </div>
              <div class="p-2">
                <legal-persons-entity-details class="p-1" :persons="draftData.AMALGAMATION_DIRECTOR_AND_SECRETARIES.data.directors.filter(function (el) { return el.individual != null;})"></legal-persons-entity-details>
              </div>
            </div>
          </div>


          <div class="mb-3 mt-3">
            <div class="border rounded-md border-gray-200">
              <div class="bg-gray-pale">
                <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Particulars of corporate directors</p>
              </div>
              <div class="p-2">
                <legal-persons-entity-details class="p-1" :persons="draftData.AMALGAMATION_DIRECTOR_AND_SECRETARIES.data.directors.filter(function (el) { return el.corporate != null;})"></legal-persons-entity-details>
              </div>
            </div>
          </div>


          <div class="mb-3 mt-3">
            <div class="border rounded-md border-gray-200">
              <div class="bg-gray-pale">
                <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Particulars of individual / corporate secretary</p>
              </div>
              <div class="p-2">
                <legal-persons-entity-details class="p-1" :persons="[draftData.AMALGAMATION_DIRECTOR_AND_SECRETARIES.data.secretary]"></legal-persons-entity-details>
              </div>
            </div>
          </div>



           <div class="border rounded-md border-gray-200 mb-2">
             <div class="bg-gray-pale">
               <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Office and Records</p>
             </div>
             <div class="p-2">
               <office-and-records :draft="draftData.AMALGAMATION_OFFICE_AND_REFERENCE.data"></office-and-records>
             </div>
           </div>



         <div>
           <div v-for="(info, idx) in companiesToBeAmalgamated" :key="idx">
             <div class="border rounded-md border-gray-200">
               <div class="bg-gray-pale">
                 <p class="text-gray mb-2 ml-2 font-bold text-sm p-1">Resolution for {{info.name ? info.name : ""}}</p>
               </div>
               <div class="p-2">
                 <AmalgamationSingleResolution
                     :data="[...draftData[`RESOLUTION_FOR_${info.brn}`].data]"
                 />
               </div>
             </div>
           </div>
         </div>




        </div>

        </b-col>
      </b-row>

    </b-col>
  </b-row>
</template>

<script>
import LegalPersonsEntityDetails
  from "@/views/home/services/registry/business/files/tabs/company_data/LegalPersonsEntityDetails.vue";
import OfficeAndRecords from "@/views/home/services/continuous-filing/task/components/OfficeAndRecords.vue";
import SharesAmalgamation from "@/views/home/services/continuous-filing/task/components/SharesAmalgamation.vue";
import AmalgamationShareholdingForm10
  from "@/views/home/services/continuous-filing/task/components/AmalgamationShareholdingForm10.vue";
import AmalgamationMetaData from "@/views/home/services/continuous-filing/task/components/AmalgamationMetaData.vue";
import AmalgamationSingleResolution
  from "@/views/home/services/continuous-filing/task/components/AmalgamationSingleResolution.vue";

export default {
  name: "CompanyAmalgamation",
  components: {
    AmalgamationSingleResolution,
    AmalgamationMetaData,
    AmalgamationShareholdingForm10, SharesAmalgamation, OfficeAndRecords, LegalPersonsEntityDetails},
  props:{
    application:Object,
    draftData:Object,
    brn:String,
    type:String
  },
  data(){
    return{
      companiesToBeAmalgamated:[]
    }
  },
  mounted() {
    this.filterData()
  },
  methods: {
    filterData(){
      let draft = this.draftData
      if(draft){
        let metaData = draft.AMALGAMATION_METADATA.data;
        if(metaData.amalgamationEntityBrn){
          this.companiesToBeAmalgamated = metaData.companiesToBeAmalgamated.filter(item => item.brn !== metaData.amalgamationEntityBrn)
        }else {
          this.companiesToBeAmalgamated = metaData.companiesToBeAmalgamated.filter(item => item.brn !== this.application.data.brn)
        }

      }
    }
  },
  watch: {
    draftData(data){
      if(data){
        this.filterData()
      }
    }
  }
};
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>