<template>
  <b-row>
    <b-col>
      <b-row>
        <b-modal id="modal-assign" hide-footer ref="assignSubmissions" title="Assign Submission">
          <Assignment :id="taskId" type="insolvency" permission="can_assign_insolvency_application"/>
        </b-modal>
          <b-modal id="modal-certificate" hide-footer ref="previewCertificate" size="xl" title="Preview Certificate">
              <certificate-preview :pdf-url="pdfUrl" ref="certPreview" ></certificate-preview>
          </b-modal>
          <b-modal id="modal-assign" hide-footer ref="addRuling" title="Add Ruling ">
              <b-form-group>
                  <label>Issuing court</label>
                  <b-form-input class="form-control my-1" placeholder="issuingcourt "
                                v-model="rulingForm.issuingCourt"/>

                  <label>Date Of ruling</label>
                  <div class="my-1">
                      <datepicker
                              :disabled-dates="{from:new Date()}"
                              placeholder="dd/mm/yyyy"
                              class="noFocus text-sm"
                              :typeable="false"
                              bootstrap-styling
                              id="dateOfBirth"
                              format="dd/MM/yyyy"
                              required
                              v-model="rulingForm.courtDate">
                      </datepicker>
                  </div>


                  <label>Case Number</label>
                  <b-form-input class="form-control my-1" placeholder="caseNumber" v-model="rulingForm.caseNumber"/>
                  <!--                <label>Court Venue</label>-->
                  <!--                <b-form-input class="form-control my-1" placeholder="venue" v-model="rulingForm.venue" />-->
                  <!--                <label>List of emails (comma separated) </label>-->
                  <!--                <b-textarea class="form-control my-1" placeholder="emailList" v-model="rulingForm.emailList" />-->
                  <label>Does the ruling close the Liquidation process ?</label>

                  <b-form-select class="form-select my-1" v-model="rulingForm.closeLiquidation">
                      <b-form-select-option :value="true">Yes</b-form-select-option>
                      <b-form-select-option :value="false">No</b-form-select-option>
                  </b-form-select>

                  <label>Summary</label>
                  <b-textarea class="form-control my-1" placeholder="Summary of the ruling"
                              v-model="rulingForm.summary"></b-textarea>

                  <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Attach copy of the ruling "
                          label-for="input-1">
                      <b-form-file
                              accept="application/pdf"
                              label-size="sm"
                              type="file"
                              placeholder="Please attach a copy of the ruling "
                              drop-placeholder="Drop file here..."
                              v-model="rulingForm.file"
                              class="form-control noFocus removeOnClickBorder rounded-0 mb-2 text-sm"
                              plain></b-form-file>
                  </b-form-group>

                  <button @click="submitRuling" class="bg-blue rounded p-1 text-white float-end my-1 ">Submit</button>

              </b-form-group>
          </b-modal>

          <b-modal ref="transferApplication" title="Transfer Application" size="md" hide-footer>
              <ReAssignInsolvencyApplication :tracking-number="trackingNumber" ></ReAssignInsolvencyApplication>
          </b-modal>
      </b-row>
      <b-row>
        <b-col cols="3">
          <b-form-input
            class="text-gray text-sm noFocus mx-2 my-2"
            style="padding:6px 5px;"
            v-model="search"
            type="text"
            placeholder="Search by TrackingNo"
            required
            v-on:keyup.enter="fetch">
          </b-form-input>
        </b-col>
        <b-col cols="2">
          <b-form-group>
            <b-form-select
              v-model="statuses"
              v-on:change="fetch"
              class="form-select noFocus text-gray mx-2 my-2 text-sm">
              <b-select-option :value="null">--Filter by Status--</b-select-option>
              <b-select-option value="DRAFT">Draft</b-select-option>
              <b-select-option value="SUBMITTED">In Queue</b-select-option>
              <b-select-option value="ASSIGNED">Assigned</b-select-option>
              <b-select-option value="QUERIED">Queried</b-select-option>
              <b-select-option value="APPROVED">Approved</b-select-option>
              <b-select-option value="ESCALATED">Escalated</b-select-option>
              <b-select-option value="INTERIM_REPORT">Interim report</b-select-option>
              <b-select-option value="INTERIM_REPORT_NOTICE">Interim report notice</b-select-option>
              <b-select-option value="RESOLUTION">Resolution</b-select-option>
              <b-select-option value="NOTICE">Notice</b-select-option>
              <b-select-option value="PETITION">Petition</b-select-option>
              <b-select-option value="PRELIMINARY_REPORT">Preliminary report</b-select-option>
              <b-select-option value="FINAL_NOTICE">Final notice</b-select-option>
              <b-select-option value="FINAL_REPORT">Final report</b-select-option>
              <b-select-option value="PRACTITIONER_VERIFICATION">Practitioner verification</b-select-option>
              <b-select-option value="PRELIMINARY_NOTICE">Preliminary notice</b-select-option>
              <b-select-option value="FINAL_REPORT_NOTICE">Final report notice</b-select-option>
              <b-select-option value="PROVISIONAL_APPOINTMENT">Provisional Appointment</b-select-option>
            </b-form-select>
          </b-form-group>
        </b-col>
        <b-col cols="2">
        </b-col>
          <b-col cols="2">

          </b-col>
        <b-col cols="1">
          <b-form-select v-model="limit" class="form-select w-full mt-2 text-xs paginate-details"
                         v-on:change="fetch">
            <b-select-option value="12">12</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col class="mx-auto mt-3">
              <p class="paginate-details text-center text-xs font-bold">
                <span>{{ ((currentPage - 1) * limit) + 1 }}</span> -
                <span v-if="limit > submissions.length ">{{ pagination.totalElements }}</span>
                <span v-else>{{ currentPage * limit }}</span>
                of {{ pagination.totalElements }}
              </p>
            </b-col>
          </b-row>
        </b-col>



      </b-row>
      <div class="mx-auto">
        <b-progress value="100" v-if="loading" variant="#007ac5" height="0.2rem" animated></b-progress>
      </div>
      <b-row>
        <b-col cols="12">

          <table class="min-w-full leading-normal">
            <thead>
            <tr class="border-b border-t border-grey">
              <th class="t-header">#</th>
              <th class="t-header">Tracking No.</th>
              <th class="t-header">BRN</th>
              <th class="t-header">Name</th>
              <th class="t-header">Type</th>
              <th class="t-header text-center">Status</th>
              <th class="t-header text-center">Assignment</th>
                <th class="t-header text-center">Dissolution Date</th>
              <th class="t-header text-center">Action</th>

            </tr>
            </thead>

            <tbody>
            <tr :class="[(idx%2) ? rows.even : rows.odd]" v-for="(submission, idx) in submissions" :key="idx">
              <td class="t-body">{{ (currentPage - 1) * limit + idx + 1 }}</td>
              <td class="t-body text-blue font-bold cursor-pointer hover:underline"
                  @click="showDetails(submission.trackingNumber)">{{ submission.trackingNumber}}
              </td> <td class="t-body text-black font-bold cursor-pointer hover:underline"
                  >{{ submission.brn}}
              </td> <td class="t-body text-black font-bold hover:underline"
                 >{{ submission.companyName}}
              </td>
              <td class="t-body font-bold">{{cleanUnderscores(submission.type)}}
                  <span v-if="submission.type==='WINDING_UP'">[{{submission.windingUpType}}]</span>
              </td>
              <td class="t-body">
                <p class="text-xs px-2 py-1 text-capitalize text-center" style="background: #f1f1f1">
                <span :class="(submission.status.toUpperCase() === 'DRAFT') ? 'text-gray-light' :
                              (submission.status.toUpperCase() === 'PAYMENT_FAILED') ? 'text-red' :
                              (submission.status.toUpperCase() === 'AWAITING_PAYMENT') ? 'text-purple' :
                              (submission.status.toUpperCase() === 'SUBMITTED') ? 'text-gray' :
                              (submission.status.toUpperCase() === 'ASSIGNED')? 'text-blue':
                              (submission.status.toUpperCase() === 'APPROVED') ? 'text-green' :
                              (submission.status.toUpperCase() === 'QUERIED') ? 'text-orange' : ''">
                  <span v-if="submission.status.toUpperCase() === 'AWAITING_PAYMENT'">
                      Confirming Payment
                  </span>
                  <span v-else-if="submission.status.toUpperCase() === 'SUBMITTED'">
                      In Queue
                    </span>
                  <span v-else>
                    {{ submission.status.toLowerCase().replaceAll("_", " ") }}
                  </span>
                </span>
                </p>
              </td>
              <td class="t-body text-center text-capitalize">
                <span class="text-xs" v-if="submission.registrarName">{{ submission.registrarName.toLowerCase()}}</span>
                <span class="text-xs" v-else>Not Assigned</span>
              </td>

                <td class="t-body text-center">
                    {{ dateFormat(submission.dateOfDissolution) }}
                </td>

              <td class="t-body text-center">
                <button
                  v-if="submission.status.toUpperCase() === 'ESCALATED' && canViewActionWithPermission('can_assign_insolvency_application')"
                  class="bg-blue text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-plus mx-1"></i>Assign
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'SUBMITTED' && canViewActionWithPermission('can_assign_insolvency_application')"
                  class="bg-green text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-plus mx-1"></i>Assign
                </button>
                <button
                  v-else-if="submission.status.toUpperCase() === 'ASSIGNED' && canViewActionWithPermission('can_assign_insolvency_application')"
                  class="bg-red text-white px-2 py-1 rounded-sm" @click="assignPrompt(submission)">
                  <i class="fa fa-redo-alt mx-1"></i>Assign
                </button>
                  <button
                          v-else-if="submission.status.toUpperCase() === 'RECEIVED'"
                          class="bg-blue text-white px-2 py-1 rounded-sm"
                          @click="showRulingForm(submission.trackingNumber)">
                      <i class="fa fa-plus mx-1"></i>Add ruling
                  </button>

                  <button
                          v-else-if="(submission.status.toUpperCase() === 'DRAFT' || submission.status.toUpperCase() ==='PROVISIONAL_APPOINTMENT') && adminCanResumeDraft(submission.applicant.nin)"
                          class="bg-gray text-white px-2 py-1 rounded-sm" @click="resumeDraft(submission)">
                      <i class="fa fa-edit mx-1"></i>Continue
                  </button>

                  <span  v-else-if="submission.status.toUpperCase() === 'APPROVED'">
                      <button
                              v-if="submission.status === 'APPROVED' && submission.type === 'WINDING_UP'&& !submission.isClosed "
                              @click="issuerCertificate(submission.trackingNumber,idx)"
                              type="button"
                              class=" text-blue border-blue  draft-btn mx-1  w-6/12">
                        <i class="fa fa-upload"></i> Issue certificate
                          <b-spinner v-if="loadingIndex===idx" small></b-spinner>
                      </button>

                       <button v-if="submission.status === 'APPROVED' && submission.type === 'WINDING_UP'&& !submission.isClosed " class=" btn-certificate" @click="openCertificatePreview(submission.trackingNumber)">
                            <i class="fa fa-eye text-green mr-1"></i>Certificate
                        </button>

                  </span>
                  <span v-else-if="!(submission.status.toUpperCase() === 'ABORTED')">
                         <button
                                 v-if="canViewActionWithPermission('can_abort_insolvency_application') && (submission.status !== 'APPROVED' && submission.status !== 'DRAFT')"
                                 @click="abortApplication(submission)"
                                 type="button"
                                 class="bg-orange rounded text-white mx-1 my-2  w-6/12">
                        <i class="fa fa-user-alt-slash"></i> Abort
                          <b-spinner v-if="loadingIndex===idx" small></b-spinner>
                      </button>

                  </span>

                  <span v-else >
                      <button
                              v-if="canViewActionWithPermission('can_remove_appointed_practitioner') && (submission.status !== 'APPROVED' && submission.status !== 'DRAFT')"
                              @click="showTransferForm(submission)"
                              type="button"
                              class="bg-red rounded text-white mx-1 py-1  w-6/12">
                        <i class="fa fa-user-lock"></i> Change Practitioner
                          <b-spinner v-if="loadingIndex===idx" small></b-spinner>
                      </button>
                  </span>



              </td>

            </tr>
            <tr>
              <td v-if="submissions.length === 0" colspan="12" class="bg-inertgrey text-center py-1">
                <span class="text-sm">No results found</span>
              </td>
            </tr>
            <tr>
              <td v-if="hasError" colspan="12" class="bg-red text-center py-1 text-white">
                <p class="text-sm">Failed to load tasks, Try again later.</p>
                <p class="text-sm">{{ errorMessage }}</p>
                <p class="text-sm">
                  <button class="bg-white text-red px-2 rounded-sm" @click="fetch">Reload
                    <b-spinner v-if="loading" small></b-spinner>
                  </button>
                </p>
              </td>
            </tr>
            </tbody>
          </table>
        </b-col>
      </b-row>
      <b-row class="mx-1" v-if="pagination.totalPages > 1">
        <b-col cols="1">
          <button @click="previous" :class="(currentPage > 1) ? 'paginate-btn' :'inert-btn'">Previous</button>
        </b-col>
        <b-col cols="1">
          <b-row>
            <b-col cols="12">
              <p class="paginate-details text-center">
                <b-badge class="bg-blue text-white">
                  {{ currentPage }}/{{ pagination.totalPages }}
                </b-badge>
              </p>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="1">
          <button @click="next" :class="(currentPage < pagination.totalPages) ? 'paginate-btn' :'inert-btn'">
            Next
          </button>
        </b-col>
        <b-col cols="6"></b-col>
        <b-col cols="1">
          <b-form-select v-model="limit" class="w-full form-select border-0 paginate-details" v-on:change="fetch">
            <b-select-option value="12">12</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
        <b-col cols="2">
          <b-row>
            <b-col class="mx-auto">
              <p class="paginate-details text-center text-xs font-bold">
                <span v-if="currentPage === 1">1</span>
                <span v-else>{{ ((currentPage - 1) * limit) + 1 }}</span> - {{ currentPage * limit }} of
                {{ pagination.totalElements }}
              </p>

            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import Assignment from "@/views/home/commons/Assignment.vue";
import {mapActions, mapGetters} from "vuex";
import {api} from "@/app/services/api.service";
import jwtService from "@/app/services/jwt.service";
import router from "@/router";
import ReAssignInsolvencyApplication
    from "@/views/home/services/insolvency/submissions/ReAssignInsolvencyApplication.vue";
import moment from "moment/moment";
import CertificatePreview from "@/views/home/services/insolvency/practitioners/CertificatePreview.vue";

export default {
  name: "AllInsolvencySubmissions",
  components: {CertificatePreview, ReAssignInsolvencyApplication, Assignment },
  data() {
    return {
      loading: false,
      loadingMoveTask: false,
      loadingRevertTask: false,
      currentPage: 1,
      limit: 12,
      submissions: [],
      pagination: {
        totalElements: 0,
        totalPages: 0
      },
      statuses: null,
      taskId: null,
      level:null,
      rows: {
        odd: "border-t border-grey hover:bg-header_grey",
        even: "border-t border-grey bg-header_grey"
      },
      search: "",
      application: {},
      application_prn: null,
      hasError: false,
        errorMessage: null,
        loadingIndex: null,
        trackingNumber: null,
        rulingForm: {
            issuingCourt: "",
            ruling: "",
            summary: "",
            caseNumber: "",
            emailList: "",
            venue: "",
            closeLiquidation: false,
            trackingNumber: "",
            file: null,

        },
        pdfUrl: null
    };
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchInsolvency", "fetchAllInsolvency", "fetchInsolvencyStatistics"]),
    fetch() {

/*
"DRAFT,SUBMITTED,APPROVED,ASSIGNED,QUERIED,ESCALATED,PAYMENT_FAILED,RESOLUTION,NOTICE," +
            "PRELIMINARY_REPORT,FINAL_NOTICE,FINAL_REPORT,PAYMENTS,PRACTITIONER_VERIFICATION,PRELIMINARY_NOTICE,FINAL_REPORT_NOTICE," +
            "INTERIM_REPORT,INTERIM_REPORT_NOTICE,SUBSTANTIVE_APPOINTMENT,APPOINTMENT_DECLINED,APPOINTMENT_RESIGNED," +
            "PETITION,PROVISIONAL_APPOINTMENT";
 */




      let data = {
        status: this.statuses,
        search: this.search,
        page: this.currentPage,
          pageSize: this.limit,
          type:"WINDING_UP,PETITION"
      };
      this.loading = true;
        let url = Object.entries(data)
            .filter(([ , value]) => value !== null && value !== "")
            .map(([key, value]) => `${key}=${value}`)
            .join("&");

        url = `?${url}`;
      this.fetchAllInsolvency(url);

    },
    assignPrompt(data) {
      this.taskId = data.id;
      this.$refs.assignSubmissions.show();
    },
    showDetails(trackingNumber) {
        this.$emit("openDetails", trackingNumber);
    },
    next() {
      if (this.currentPage < this.pagination.totalPages) {
        this.currentPage++;
        this.fetch();
      }
    },
    previous() {
      if (this.currentPage > 1) {
        this.currentPage--;
        this.fetch();
      } else {
        this.warningToast("Observation", "No more data to show");
      }
    },
    canViewActionWithPermission(permission) {
      return this.$can(permission, "general") === true;
    },
      issuerCertificate(trackingNumber, index) {
          this.loadingIndex = index;
          api().post("/insolvency/process/issue-certificate", {trackingNumber: trackingNumber})
              .then(() => {
                  this.successToast("Success", "Certificate issued successfully");
                    this.loadingIndex = null;
                    this.fetch()
              })
              .catch((error) => {
                  this.errorToast("Error", error.response.data.message);
                    this.loadingIndex = null;
              });
      },
      adminCanResumeDraft(nin) {
        return jwtService.getUser().idNumber === nin;
      },
      resumeDraft(submission) {
          this.loading = true;
          sessionStorage.setItem("tracking-number", submission.trackingNumber);

             // sessionStorage.setItem('insolvencyType', this.returnWindingType(submission));
              router.push({name:"court-order-winding-up"})
      },
      showTransferForm(submission){
        this.trackingNumber  = submission.trackingNumber
        this.$refs.transferApplication.show()
      },
      abortApplication(submission){

          this.showConfirmDialog("Are you sure that you want to abort this application?").then(value => {
              if (value) {
                  this.loading = true;
                  api().post("/insolvency/process/abort", {trackingNumber: submission.trackingNumber})
                      .then(() => {
                          this.successToast("Success", "Application aborted successfully");
                          this.fetch();
                      })
                      .catch((error) => {
                          this.errorToast("Error", error.response.data.message);
                      });
                  this.loading = false;
              }
          });


      },
      showRulingForm(trackingNo) {
          this.$refs.addRuling.show()
          this.rulingForm.trackingNumber = trackingNo;

      },
      submitRuling() {
          this.loading = true;

          let formData = new FormData();
          formData.set("issuingCourt", this.rulingForm.issuingCourt);
          formData.set("caseNumber", this.rulingForm.caseNumber);
          // formData.set("venue", this.rulingForm.venue);
          // formData.set("emailList", this.rulingForm.emailList);
          formData.set("description", this.rulingForm.summary);
          formData.set("closeLiquidation", this.rulingForm.closeLiquidation);
          formData.set("trackingNumber", this.rulingForm.trackingNumber);
          formData.set("courtDate", moment(this.rulingForm.courtDate).format("YYYY-MM-DD"));
          formData.set("file", this.rulingForm.file);


          api().post("/insolvency/petitions/issue-ruling", formData)
              .then(() => {
                  this.loading = false;
                  this.successToast("Success", "Ruling sent successfully");
                  this.$refs.addRuling.hide();
                  this.fetch();
              })
              .catch(() => {
                  this.loading = false;
                  this.errorToast("Error", "Failed to send ruling");
              });
      },
      openCertificatePreview(trackingNo){
          this.pdfUrl = '/insolvency/process/preview-certificate-of-dissolution?trackingNo='+trackingNo+'&format=base64'
          this.$refs.previewCertificate.show()
      },


  },
  computed: {
    ...mapGetters([
      "getInsolvencySubmissions",
      "getAssignmentSuccess", "getAssignmentError", "getInsolvencyError"]),
    hasAction() {
      return this.status === null || (this.status !== "QUERIED" && this.status !== "APPROVED");
    }
  },
  watch:{
    getInsolvencySubmissions(data){
      this.loading = false;
      this.submissions = data.content;
      this.pagination = {
        totalPages: data.totalPages,
        totalElements: data.totalElements
      };

    },
    getInsolvencyError(data){
      this.loading = false
      this.errorToast("Error", data.messages)
    }
  }
};
</script>

<style scoped>

.paginate-details {
  @apply px-3 py-2 bg-white my-2 text-sm text-gray;
}

.paginate-btn {
  @apply px-3 py-2 bg-white border border-white my-2 text-sm text-blue w-full;
}

.paginate-btn:hover {
  @apply border border-blue text-blue rounded-sm;
}

.inert-btn {
  @apply px-3 py-2 bg-white my-2 text-sm text-grey w-full;
}

.btn-certificate{
    @apply border border-green rounded-sm text-green text-sm px-3 py-1 mx-1 bg-white;
}

.btn-certificate:hover{
    @apply text-green-dark;
}

</style>