<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-textarea v-model="description"
                      class="mb-2"
                      rows="4"
                      placeholder="Comments">
          </b-textarea>
          <p v-if="description" class="my-2 text-xs">{{description.length}}/{{maxText}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="9">
          <b-input-group class="border border-grey rounded-sm">
            <b-form-input
                class="form-text text-sm noFocus border-0 text-gray"
                v-model="search"
                v-on:keyup="onSearch"
                type="text"
                placeholder="Search">
            </b-form-input>
            <b-input-group-prepend
                class="bg-white">
              <i class="fas fa-search mx-3 text-gray hover:text-blue" style="margin-top: 12px;" @click="onSearch"></i>
            </b-input-group-prepend>
          </b-input-group>
        </b-col>
        <b-col cols="3">
          <b-form-select v-model="limit" class="w-full form-select paginate-details" v-on:change="fetch">
            <b-select-option value="15">15</b-select-option>
            <b-select-option value="20">20</b-select-option>
            <b-select-option value="50">50</b-select-option>
            <b-select-option value="100">100</b-select-option>
          </b-form-select>
        </b-col>
      </b-row>
      <b-row class="mt-2">
        <b-col cols="12">
          <b-row>
            <b-col>
              <b-progress value="100" v-if="loading"  variant="#007ac5" height="0.2rem" animated></b-progress>
            </b-col>
          </b-row>
          <b-row class="flex py-2 px-3 border-b border-header_grey hover:bg-header_grey"
                 v-for="(registrar, idx) in getRegistrars"
                 :key="idx">
            <b-col cols="9">
              <div class="flex">
                <b-col class="ml-3">
                  <b-row>
                    <b-col>
                      <p class="text-sm text-uppercase" v-if="registrar">
                        <span v-if="registrar.givenName">{{registrar.givenName}}</span>
                        <span class="mx-1" v-if="registrar.surName">{{registrar.surName}}</span>
                        <span class="mx-1" v-if="registrar.middleName">{{registrar.middleName}}</span>
                      </p>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col>
                      <p class="text-xs text-lowercase text-gray italic" v-if="registrar">{{registrar.email}}</p>
                    </b-col>
                  </b-row>
                </b-col>
              </div>
            </b-col>
            <b-col cols="3">
              <button @click="assign(registrar)" class="button">
                Select <i class="text-white fa fa-check-circle mx-1"></i>
                <b-spinner class="ml-1" small v-if="loadingAssignment && selectedId === registrar.id"></b-spinner>
              </button>
            </b-col>
          </b-row>
          <b-row class="flex py-1 px-2 bg-header_grey"
                 v-if="getRegistrars.length === 0">
            <b-col cols="12" class="text-center">
              <p class="text-xs">No Account Found</p>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default  {
  name: "Assignment",
  props:{id:String, type:String, permission:String},
  data(){
    return{
      search:null,
      loading:false,
      loadingAssignment:false,
      description:null,
      selectedId:null,
      limit:15,
      maxText:500
    }
  },
  mounted() {
    this.fetch()
  },
  methods:{
    ...mapActions(["fetchRegistrars", "searchRegistrars", "assignReservationTask",
      "assignEscalationReservationTask", "assignEscalationLegalDocumentTask",
      "assignCITask", "assignDispensationTask", "assignCFTask", "assignBNRTask",
      "assignAmendmentTask", "assignNewBNRTask", "assignBSITask", "assignLegalDocument",
      "assignCertificationTask", "assignEscalatedCertificationTask",
      "assignSearchReportTask", "assignBNDataUpdateTask", "assignDataCorrectionTask",
        "assignCIDataUpdateTask", "assignAnnualReturnsTask","assignDisputeResolutionTask","assignInsolvencyApplication"]),
    fetch(){
      this.loading = true
      this.fetchRegistrars({permission:this.permission, limit:this.limit})
    },
    onSearch(){
      this.loading = true
      this.searchRegistrars({ term: this.search, permission: this.permission })
    },
    assign(person){
      this.selectedId = person.id
      if(this.type === "reservation"){
        let data = {
          taskId:this.id,
          description:this.description,
          toUserId:person.id
        }
        this.loadingAssignment = true
        this.assignReservationTask(data)
      }
      if(this.type === "reservation-as"){
        let data = {
          escalationId:this.id,
          description:this.description,
          toUserId:person.id
        }
        this.loadingAssignment = true
        this.assignEscalationReservationTask(data)
      }
      if(this.type === "bnr"){
        let data = {
          escalationId:this.id,
          description:this.description,
          toUserId:person.id
        }
        if(this.id != null){
          this.loadingAssignment = true
          this.assignBNRTask(data)
        }else{
          this.warningToast("Missing params", "No businessId Provided")
        }
      }
      if(this.type === "bn-data-update"){
        let data = {
          applicationId:this.id,
          description:this.description,
          userId:person.id
        }
        if(this.description == null){
          this.warningToast("Warning", "Enter a message")
        }else if(this.id != null){
          this.loadingAssignment = true
          this.assignBNDataUpdateTask(data)
        }else{
          this.warningToast("Missing params", "No businessId Provided")
        }
      }
      if(this.type === "bnr-n"){
        let data = {
          businessId:this.id,
          description:this.description,
          userId:person.id
        }
        if(this.id != null){
          this.loadingAssignment = true
          this.assignNewBNRTask(data)
        }else{
          this.warningToast("Missing params", "No businessId Provided")
        }
      }
      if(this.type === "amendment"){
        let data = {
          filingApplicationId:this.id,
          description:this.description,
          userId:person.id
        }
        if(this.id != null){
          this.loadingAssignment = true
          this.assignAmendmentTask(data)
        }else{
          this.warningToast("Missing params", "No businessId Provided")
        }
      }
      if(this.type === "ci"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:person.id
        }
        if(this.id != null){
          this.loadingAssignment = true
          this.assignCITask(data)
        }else{
          this.warningToast("Warning", "Select a task please")
        }
      }
      if(this.type === "ci-data-update"){
        let data = {
          applicationId:this.id,
          description:this.description,
          userId:person.id
        }

        if(this.description == null){
          this.warningToast("Warning", "Enter a message")
        }else if(this.id != null){
          this.loadingAssignment = true
          this.assignCIDataUpdateTask(data)
        }else{
          this.warningToast("Warning", "Select a task please")
        }
      }

        if(this.type === "data-correction"){
            let data = {
                taskId:this.id,
                description:this.description,
                userId:person.id
            }

            if(this.description == null){
                this.warningToast("Warning", "Enter a message")
            }else if(this.id != null){
                this.loadingAssignment = true
                this.assignDataCorrectionTask(data)
            }else{
                this.warningToast("Warning", "Select a task please")
            }
        }

        if(this.type === "dispute"){
            let data = {
                disputeId:this.id,
                userId:person.id
            }

            if(this.description == null){
                this.warningToast("Warning", "Enter a message")
            }else if(this.id != null){
                this.loadingAssignment = true
                this.assignDisputeResolutionTask(data)
            }else{
                this.warningToast("Warning", "Select a task please")
            }
        }

      if(this.type === "dispensation"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:person.id
        }
        if(this.id != null){
          this.loadingAssignment = true
          this.assignDispensationTask(data)
        }else{
          this.warningToast("Warning", "Select a task please")
        }
      }

      if(this.type === "cf"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:person.id
        }
        console.log(data)
        if(this.id != null){
          this.loadingAssignment = true
          this.assignCFTask(data)
        }else{
          this.warningToast("Missing Parameter", "Select a task please")
        }
      }

      if(this.type === "bsi"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:person.id
        }
        this.loadingAssignment = true
        this.assignBSITask(data)
      }

      if(this.type === "legal-docs"){
        let data = {
          documentId:this.id,
          description:this.description,
          userId:person.id
        }
        this.loadingAssignment = true
        this.assignLegalDocument(data)
      }

      if(this.type === "legal-docs-as"){
        let data = {
          documentId:this.id,
          description:this.description,
          userId:person.id
        }
        this.loadingAssignment = true
        this.assignEscalationLegalDocumentTask(data)
      }

      if(this.type === "certification"){
        let assignerId = JSON.parse(sessionStorage.getItem("current_user")).userId
        let data = {
          queueTaskId:this.id,
          description:this.description,
          regAssigningId:assignerId,
          userId:person.id
        }
        this.loadingAssignment = true
        this.assignCertificationTask(data)
      }

      if(this.type === "certification-as"){
        let assignerId = JSON.parse(sessionStorage.getItem("current_user")).userId
        let data = {
          escalationId:this.id,
          description:this.description,
          fromUserId:assignerId,
          toUserId:person.id
        }
        this.loadingAssignment = true
        this.assignEscalatedCertificationTask(data)
      }

      if(this.type === "search-report"){
        let assignerId = JSON.parse(sessionStorage.getItem("current_user")).userId
        let data = {
          queueTaskId:this.id,
          description:this.description,
          regAssigningId:assignerId,
          userId:person.id
        }
        this.loadingAssignment = true
        this.assignSearchReportTask(data)
      }

      if(this.type === "annual-returns"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:person.id
        }
        this.loadingAssignment = true
        this.assignAnnualReturnsTask(data)
      }
        if (this.type === "insolvency"){
            let data = {
                insolvencyProcessId:this.id,
                description:this.description,
                userId:person.id
            }
            this.loadingAssignment = true
            this.assignInsolvencyApplication(data)

        }
    },
  },
  computed:{
    ...mapGetters([
      "getRegistrars", "getReservationAssignmentError",
      "getAssignmentSuccess", "getAssignmentError",
      "getLegalDocumentAssignmentSuccess", "getLegalDocumentAssignmentError",
      "getSearchReportAssignmentSuccess", "getSearchReportAssignmentError",
      "getCertificationAssignmentActionSuccess", "getCertificationAssignmentActionError",
      "getAssignmentDataUpdateSuccess", "getCompanyDataUpdateError", "getBNDataUpdateAssignmentSuccess",
      "getBNDataUpdateError", "getAnnualReturnsAssignmentSuccess", "getAnnualReturnsAssignmentError","getDisputeResolutionAssignmentSuccess","getDisputeResolutionAssignmentError","getInsolvencyAssignmentSuccess"]),
  },
  watch:{
    description:{
      handler(data){
        if(data.length >= this.maxText){
          this.errorToast("Text Length Error", "This message is very long")
        }
      },
      deep:true
    },
    getRegistrars(){
      this.loading = false
    },
    getAssignmentSuccess(){
      this.loadingAssignment = false
      this.loading = false
      this.successToast("Success", "Task assigned successfully")
    },
    getAssignmentError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getReservationAssignmentError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.data.message)
    },
    getAnnualReturnsAssignmentSuccess(){
      this.loadingAssignment = false
      this.loading = false
    },
    getLegalDocumentAssignmentSuccess(){
      this.loadingAssignment = false
      this.loading = false
    },
    getCertificationAssignmentActionSuccess(){
      this.loadingAssignment = false
      this.loading = false
      window.location.reload()
    },
    getCertificationAssignmentActionError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getLegalDocumentAssignmentError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getSearchReportAssignmentSuccess(){
      this.loadingAssignment = false
      this.loading = false
      window.location.reload()
    },
    getSearchReportAssignmentError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getAssignmentDataUpdateSuccess(){
      this.loadingAssignment = false
      this.loading = false
      window.location.reload()
    },
    getAnnualReturnsAssignmentError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getBNDataUpdateAssignmentSuccess(){
      this.loadingAssignment = false
      this.loading = false
      window.location.reload()
    },
    getCompanyDataUpdateError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getBNDataUpdateError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getDisputeResolutionAssignmentSuccess(){
      this.loadingAssignment = false
      this.loading = false
      window.location.reload()
    },
      getInsolvencyAssignmentSuccess(){
          this.loadingAssignment = false
          this.loading = false
          window.location.reload()
      },
    getDisputeResolutionAssignmentError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    }
  }
}
</script>

<style scoped>
.button{
  @apply w-full bg-blue mt-1 px-2 py-1 rounded-sm text-white text-xs
}

.button:hover{
  @apply bg-green-dark;
}
</style>