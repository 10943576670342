<template>
  <div>
    <b-row v-if="application !== null">
      <b-col cols="12">
        <b-row v-if="application.reservation !== undefined" class="mx-auto my-2">
          <b-col cols="12 p-0">
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
              <thead>
              <tr class="bg-gray-pale">
                <td colspan="12" class="px-2 py-2">Reservation Details</td>
              </tr>
              <tr>
                <th class="px-2 py-1">Tracking Number</th>
                <th class="px-2 py-1">Name</th>
                <th class="px-2 py-1">Status</th>
                <th class="px-2 py-1">Sub Entity Type</th>
              </tr>
              </thead>
              <tbody>
              <tr style="border: solid 1px #dedede;" >
                <td class="px-2 py-1 font-bold text-gray">
                  <span class="">{{ application.reservation.reservationNo }}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{ application.reservation.name }}</span>
                </td>
                <td class="px-2 py-1">
                  <span :class="statusColorCode(application.reservation.status)">{{statusDataInfo(application.reservation.status)}}</span>
                </td>
                <td class="px-2 py-1">
                  <span class="text-gray text-capitalize">{{ cleanEntityType(application.reservation.subType) }}</span>
                </td>
              </tr>
              </tbody>
            </table>
          </b-col>
        </b-row>

          <b-row v-if="application.resolution"  class="py-2">
              <b-col>
                  <b-row>
                      <b-col>
                          <span class="font-bold text-sm">Resolution </span>
                      </b-col>
                  </b-row>
                  <b-row>
                      <b-col cols="12">
                          <b-form-group
                                  class="text-sm my-1"
                                  id="input-group-1"
                                  label="Organ"
                                  label-for="input-1">
                              <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus"
                                      type="text"
                                      disabled
                                      :value="application.resolution.organ.replaceAll('_', ' ')"
                                      required>
                              </b-form-input>
                          </b-form-group>
                          <b-form-group
                                  class="text-sm my-1"
                                  id="input-group-1"
                                  label="Resolution Name"
                                  label-for="input-1">
                              <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus"
                                      type="text"
                                      disabled
                                      :value="application.resolution.resolutionName.replaceAll('_', ' ')"
                                      required>
                              </b-form-input>
                          </b-form-group>
                          <b-form-group
                                  class="text-sm my-1"
                                  id="input-group-1"
                                  label="Subject"
                                  label-for="input-1">
                              <b-form-input
                                      id="companyName"
                                      class="form-text text-sm noFocus"
                                      type="text"
                                      disabled
                                      :value="application.resolution.subject"
                                      required>
                              </b-form-input>
                          </b-form-group>
                          <b-form-group
                                  class="text-sm my-1"
                                  id="input-group-1"
                                  label="Venue of Meeting"
                                  label-for="input-1">
                              <b-textarea
                                      id="companyName"
                                      class="form-text text-sm noFocus"
                                      type="text"
                                      disabled
                                      :value="application.resolution.venue"
                                      required>
                              </b-textarea>
                          </b-form-group>

                          <table class="min-w-full leading-normal mt-3">
                              <thead class="bg-header_grey border border-header_grey">
                              <tr>
                                  <th class="px-2 py-1" colspan="12">Signatories</th>
                              </tr>
                              </thead>
                              <thead class="border border-gray">
                              <tr>
                                  <th class="px-2 py-1">#</th>
                                  <th class="px-2 py-1">Name</th>
                              </tr>
                              </thead>
                              <tbody>
                              <tr style="border: solid 1px #dedede;" v-for="(signatory, idx) in application.resolution.signatories" :key="idx">
                                  <td class="px-2 py-1">
                                      {{idx+1}}.
                                  </td>
                                  <td class="px-2 py-1">
                                      <span v-if="signatory.entityType === 'INDIVIDUAL'">{{signatory.individual.givenName}} {{signatory.individual.surName}}</span>
                                      <span v-if="signatory.entityType === 'CORPORATE'">{{signatory.corporate.registeredName}}</span>
                                  </td>
                              </tr>
                              </tbody>
                          </table>
                      </b-col>
                  </b-row>
              </b-col>
          </b-row>

          <!-- Applicant -->
          <b-row class="mt-1">
              <b-col cols="12">
                  <table class="min-w-full leading-normal mt-1 text-blue-dark"
                         style="border: solid 1px #bdd7f1;">
                      <thead>
                      <tr class="bg-blue-pale">
                          <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
                      </tr>
                      <tr>
                          <th class="px-2 py-1">Name</th>
                          <th class="px-2 py-1">Email</th>
                          <th class="px-2 py-1">Phone</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr style="border: solid 1px #bdd7f1;" v-if="application.applicant">
                          <td class="px-2 py-1 text-capitalize">{{application.applicant.givenName}} {{application.applicant.surname}}</td>
                          <td class="px-2 py-1">{{application.applicant.email}}</td>
                          <td class="px-2 py-1">{{application.applicant.phoneNumber}}</td>
                      </tr>
                      </tbody>
                  </table>
              </b-col>
          </b-row>
          <!-- /Applicant -->
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "DispensationFormDetails",
  props:{
    application: {
      type: [Object, null],
      default: null,
    }
  },
  data(){
    return{
      hasComments: false,
      currentRoute: "form",
      selectedId:null,
      margin:"2px",
      hasContent:false,
    }
  },
  methods:{
    cleanEntityType(name){
      if (typeof name !== "string") {
        return null
      }
      let n = name.replaceAll("-", " ").replaceAll("_", " ")
      return n.charAt(0).toLowerCase() + n.slice(1)
    },
    cleanString(name){
      if (typeof name !== "string") {
        return null
      }
      let n = name.replaceAll("_", " ").replaceAll("_", " ")
      return n.charAt(0).toLowerCase() + n.slice(1)
    },
    statusDataInfo(status) {
      if (status.toUpperCase() === "APPROVED") {
        return "Approved"
      } else if (status.toUpperCase() === "RESERVED") {
        return "Reserved"
      } else if (status.toUpperCase() === "QUERIED") {
        return "Issues Raised"
      } else if (status.toUpperCase() === "ASSIGNED") {
        return "Being Processed"
      } else if (status.toUpperCase() === "PENDING") {
        return "Submitted"
      } else if (status.toUpperCase() === "APPEALED") {
        return "Appealed"
      }else if (status.toUpperCase() === "REJECTED") {
        return "Rejected"
      }else if (status.toUpperCase() === "DRAFT") {
        return "Draft"
      } else if (status.toUpperCase() === "AWAITING_PAYMENT_CONFIRMATION") {
        return "Confirming Payment"
      }else if (status.toUpperCase() === "PAYMENT_FAILED") {
        return "Payment Failed"
      }
    },
    statusColorCode(status) {
      if (status.toUpperCase() === "APPROVED" || status.toUpperCase() === "RESERVED") {
        return "text-green"
      } else if (status.toUpperCase() === "QUERIED") {
        return "text-orange"
      } else if (status.toUpperCase() === "ASSIGNED") {
        return "text-blue"
      } else if (status.toUpperCase() === "PENDING") {
        return "text-gray"
      } else if (status.toUpperCase() === "APPEALED" ) {
        return "text-red"
      }else if (status.toUpperCase() === "REJECTED") {
        return "text-red"
      }else if (status.toUpperCase() === "DRAFT") {
        return "text-gray"
      } else if (status.toUpperCase() === "AWAITING_PAYMENT_CONFIRMATION") {
        return "text-purple"
      }else if (status.toUpperCase() === "PAYMENT_FAILED") {
        return "text-red"
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>