<template>
    <b-row>
        <b-col cols="12">

            <b-row cols="12" class="my-2">
                <b-col>
<label>
    Contact Information

</label>
                    <b-row>
                        <b-col>
                        <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Email"
                            label-for="input-1">
                            <b-form-input
                                class="form-text text-sm noFocus"
                                type="text"
                                disabled="true"
                                :value="buildingSociety.details.primaryEmail ? buildingSociety.details.primaryEmail : ''"
                                required>
                            </b-form-input>
                        </b-form-group>
                </b-col>
                <b-col>
                        <b-form-group
                            class="text-sm my-1"
                            id="input-group-1"
                            label="Phone Number"
                            label-for="input-1">
                            <b-form-input
                                class="form-text text-sm noFocus"
                                type="text"
                                disabled="true"
                                :value="buildingSociety.details.primaryPhone ? buildingSociety.details.primaryPhone : ''"
                                required>
                            </b-form-input>
                        </b-form-group>
                </b-col>
            </b-row>

            <b-row class="pt-1">
                <b-col cols="12">
                    <div class="flex h-full" style="cursor: pointer;">
                        <div :class="[(currentRoute === 'members') ? 'menu-item-active' : 'menu-item']"
                             @click="select('members')">
                            <div class="flex mt-6">
                                <div style="font-size: 14px">Members</div>
                                <b-badge class="bg-blue mb-1 mx-1 ">
                                    {{ buildingSociety.members.length }}
                                </b-badge>
                            </div>
                        </div>

                        <div :class="[(currentRoute === 'management') ? 'menu-item-active' : 'menu-item']"
                             @click="select('management')">
                            <div class="flex mt-6">
                                <div style="font-size: 14px">Management</div>
                            </div>
                        </div>


                        <div :class="[(currentRoute === 'address') ? 'menu-item-active' : 'menu-item']"
                             @click="select('address')">
                            <div class="flex mt-6">
                                <div style="font-size: 14px">Address</div>
                            </div>
                        </div>
                        <div :class="[(currentRoute === 'documents') ? 'menu-item-active' : 'menu-item']"
                             @click="select('documents')">
                            <div class="flex mt-6">
                                <div style="font-size: 14px">Documents</div>
                                <!--                <b-badge class="bg-blue mx-1 mb-1">-->
                                <!--                  {{society.attachments.length}}-->
                                <!--                </b-badge>-->
                            </div>
                        </div>
                        <div :class="[(currentRoute === 'filing-history') ? 'menu-item-active' : 'menu-item']"
                             @click="select('filing-history')">
                            <div class="flex mt-6">
                                <div style="font-size: 14px">Filing History</div>
                            </div>
                        </div>
                    </div>
                </b-col>
            </b-row>
                </b-col>
            </b-row>
            <b-row>
                <b-col cols="12">
                    <b-row>
                        <BuildingSocietyMembers nature="Members" :members="buildingSociety.members" v-if="currentRoute === 'members'"/>
                        <BuildingSocietyManagement :management="buildingSociety.management.data" v-if="currentRoute === 'management'" ></BuildingSocietyManagement>
                         <BuildingSocietyDetails :file="society" v-if="currentRoute === 'details'"/>
                        <BuildingSocietyDocuments :documents="buildingSociety.documents" v-if="currentRoute === 'documents'"/>
                        <BuildingSocietyFilingHistory :brn="society.brn" v-if="currentRoute === 'filing-history'"/>
                        <BsiAddress v-if="currentRoute==='address'" :brn="society.brn" />
                    </b-row>
                </b-col>
            </b-row>
        </b-col>
    </b-row>
</template>

<script>
import BuildingSocietyMembers
    from "@/views/home/services/registry/business/files/tabs/building_society_data/BuildingSocietyMembers";
import BuildingSocietyDetails
    from "@/views/home/services/registry/business/files/tabs/building_society_data/BuildingSocietyDetails";
import BuildingSocietyDocuments
    from "@/views/home/services/registry/business/files/tabs/building_society_data/BuildingSocietyDocuments";
import {mapActions, mapGetters} from "vuex";
import {api} from "@/app/services/api.service";
import BuildingSocietyManagement
    from "@/views/home/services/registry/business/files/tabs/building_society_data/BuildingScoietyManagement.vue";
import BuildingSocietyFilingHistory
    from "@/views/home/services/registry/business/files/tabs/company_data/BuildingSocietyFilingHistory.vue";
import BsiAddress from "@/views/home/services/registry/business/files/tabs/company_data/BsiAddress.vue";

export default {
    name: "BuildingSocietyFile",
    components: {
        BsiAddress,
        BuildingSocietyFilingHistory,
        BuildingSocietyManagement, BuildingSocietyDocuments, BuildingSocietyDetails, BuildingSocietyMembers},
    props: {
        society: Object
    },
    data() {
        return {
            currentRoute: "members",
            buildingSociety: {
                members: [],
                committee : [],
                directors : [],
                documents: [],
                management:{},
                details : {}
            }
        }
    },
    mounted() {

        this.fetchBuildingSocietyMembers(this.society.brn)
        this.fetchBuildingSocietyCommittee(this.society.brn)
        this.fetchBuildingSocietyRegisteredDocuments(this.society.brn)
        this.fetchBuildingSocietyDetails(this.society.brn)

    },
    methods: {
        ...mapActions(["fetchBuildingSocietyRegisteredDocuments"]),
        select(route) {
            this.currentRoute = route
        },

        async fetchBuildingSocietyMembers( brn) {
            await api()
                .get("building-societies/societies/registry/get-registered-members/"+brn)
                .then(response => {
                   this.buildingSociety.members = response.data.data;
                })
                .catch(error => {
                    this.errorToast(error.response.data.message);
                });
        },
        async fetchBuildingSocietyCommittee(brn) {
            await api()
                .get("building-societies/societies/registry/get-registered-management/"+brn)
                .then(response => {
                    this.buildingSociety.management = response.data;
                })
                .catch(error => {
                    this.errorToast(error.response.data.message);
                });
        },
        async fetchBuildingSocietyRegisteredDocuments(brn) {
            await api()
                .get("building-societies/societies/registry/get-registered-documents/"+brn)
                .then(response => {
                    this.buildingSociety.documents = response.data.data;
                })
                .catch(error => {
                    this.errorToast(error.response.data.message);
                });
        },
        async fetchBuildingSocietyDetails(brn) {
            await api()
                .get("building-societies/societies/registry/"+brn)
                .then(response => {
                    this.buildingSociety.details = response.data.data;
                })
                .catch(error => {
                    this.errorToast(error.response.data.message);
                });
        }


    },
    computed: {
        ...mapGetters(["getBuildingSocietyMembers","getBuildingSocietyDocuments"])
    },

    watch: {
        getBuildingSocietyDocuments(data) {
            this.buildingSociety.documents = data
        }
    }
}
</script>

<style scoped>

</style>