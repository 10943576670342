<template>
  <b-row>
    <b-col>
      <b-row>
        <b-col>
          <b-textarea v-model="description"
                      class="mb-2"
                      rows="4"
                      placeholder="Comments">
          </b-textarea>
          <p v-if="description" class="my-2 text-xs">{{description.length}}/{{maxText}}</p>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <button class="button" @click="assign">Send
            <b-spinner v-if="loading" small class="ml-1"></b-spinner></button>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {mapActions, mapGetters} from "vuex";

export default {
  name: "AssignmentReply",
  props:{id:String, userId:String, escalationId:String, fromUserId:String, type:String},
  data(){
    return{
      search:null,
      loading:false,
      selectedId:null,
      description:null,
      maxText:500
    }
  },
  methods:{
    ...mapActions(["fetchRegistrars", "searchRegistrars", "assignEscalationReservationTask", "assignEscalationLegalDocumentTask",
      "assignCITask", "assignCFTask", "assignBNRTask", "assignDispensationTask", "assignLegalDocument",
      "assignEscalatedCertificationTask", "assignAnnualReturnsTask","assignAmendmentEscalationTask","assignInsolvencyApplication"]),
    assign(){
      this.selectedId = this.userId
      if(this.type === "reservation"){
        let data = {
          escalationId:this.id,
          description:this.description,
          toUserId:this.userId
        }
        this.loading = true
        this.assignEscalationReservationTask(data)
      }
      if(this.type === "bnr"){
        let data = {
          escalationId:this.id,
          description:this.description,
          toUserId:this.userId
        }
        if(this.id != null){
          this.loading = true
          this.assignBNRTask(data)
        }else{
          this.warningToast("Missing params", "No businessId Provided")
        }
      }

        if(this.type === "amendment"){
            let data = {
                escalationId:this.id,
                description:this.description,
                toUserId:this.userId
            }
            if(this.id != null){
                this.loadingAssignment = true
                this.assignAmendmentEscalationTask(data)
            }else{
                this.warningToast("Missing params", "No businessId Provided")
            }
        }

      if(this.type === "ci"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:this.userId
        }
        this.loading = true
        this.assignCITask(data)
      }

      if(this.type === "dispensation"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:this.userId
        }
        this.loading = true
        this.assignDispensationTask(data)
      }

      if(this.type === "cf"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:this.userId
        }
        this.loading = true
        this.assignCFTask(data)
      }

      if(this.type === "annual-returns"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:this.userId
        }
        this.loading = true
        this.assignAnnualReturnsTask(data)
      }

      if(this.type === "bsi"){
        let data = {
          taskId:this.id,
          comment:this.description,
          registrarId:this.userId
        }
        this.loading = true
        this.assignBSITask(data)
      }

      if(this.type === "legal-docs"){
        let data = {
          documentId:this.id,
          description:this.description,
          userId:this.userId
        }
        this.loadingAssignment = true
        this.assignLegalDocument(data)
      }

      if(this.type === "legal-docs-as"){
        let data = {
          documentId:this.id,
          description:this.description,
          toUserId:this.userId,
          escalationId:this.escalationId,
        }
        this.loadingAssignment = true
        this.assignEscalationLegalDocumentTask(data)
      }

      if(this.type === "certification-as"){
        let assignerId = JSON.parse(sessionStorage.getItem("current_user")).userId
        let data = {
          escalationId:this.id,
          description:this.description,
          fromUserId:assignerId,
          toUserId:this.userId,
        }
        this.loadingAssignment = true
        this.assignEscalatedCertificationTask(data)
      }

        if (this.type === "insolvency"){
            let data = {
                insolvencyProcessId:this.id,
                description:this.description,
                userId:this.userId
            }
            this.loadingAssignment = true
            this.assignInsolvencyApplication(data)

        }
    },

  },
  computed:{
    ...mapGetters(["getRegistrars", "getReservationAssignmentError", "getAssignmentSuccess", "getAssignmentError",
      "getLegalDocumentAssignmentSuccess", "getLegalDocumentAssignmentError", "getCertificationAssignmentActionSuccess",
        "getCertificationAssignmentActionError","getInsolvencyAssignmentSuccess"]),
  },
  watch:{
    getRegistrars(){
      this.loading = false
    },
    getAssignmentSuccess(){
      this.loading = false
      window.location.reload()
    },
    getAssignmentError(data){
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getReservationAssignmentError(){
      this.loading = false
    },
    getLegalDocumentAssignmentSuccess(){
      this.loadingAssignment = false
      this.loading = false
        window.location.reload()
    },
    getLegalDocumentAssignmentError(data){
      this.loadingAssignment = false
      this.loading = false
      this.errorToast("Error", data.message)
    },
    getCertificationAssignmentActionSuccess(){
      this.loadingAssignment = false
      this.loading = false
        window.location.reload()
    },
    getCertificationAssignmentActionError(){
      this.loadingAssignment = false
      this.loading = false
    },
      getInsolvencyAssignmentSuccess(){
          this.loadingAssignment = false
          this.loading = false
          window.location.reload()
      },

  }
}
</script>

<style scoped>
.button{
  @apply w-full bg-blue mt-1 px-2 py-2 rounded-sm text-white text-xs
}

.button:hover{
  @apply bg-green-dark;
}
</style>