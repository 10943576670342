<template>
    <div class="my-3">
        <b-row class="mb-2" v-if="getContinuousFilingTask.data.typeOfChangeBeingFiled === 'MULTI_CHANGES'">
            <b-col>
                <b-badge class="bg-green mx-1">Incoming Filings <i class="fa fa-arrow-right text-white"></i></b-badge>
                <b-badge class="bg-blue mx-1"
                         v-for="(file,idx) in getContinuousFilingTask.data.embeddedChangesBeingFiled" :key="idx">
                    {{ filingName(file.typeOfChangeBeingFiled) }}
                </b-badge>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                    <thead>
                    <tr class="bg-gray-pale">
                        <td colspan="12" class="px-2 py-2 font-bold">Resolution Details</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Registration Number</td>
                        <td class="px-2 py-1 text-capitalize">{{ getContinuousFilingTask.data.brn }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Organ of Company</td>
                        <td class="px-2 py-1 text-capitalize">{{ cleanName(getContinuousFilingTask.data.organ) }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Resolution Name</td>
                        <td class="px-2 py-1 text-capitalize">
                            {{ cleanName(getContinuousFilingTask.data.resolutionName) }}
                        </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Subject</td>
                        <td class="px-2 py-1 text-capitalize">{{ getContinuousFilingTask.data.subject }}</td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Date of Meeting</td>
                        <td class="px-2 py-1 text-capitalize">
                            {{ dateFormat(getContinuousFilingTask.data.dateOfMeeting) }}
                        </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Venue of Meeting</td>
                        <td class="px-2 py-1 text-capitalize">
                            <span v-if="getContinuousFilingTask.data.venue">{{ getContinuousFilingTask.data.venue }}</span>
                            <span v-else>N/A</span>
                        </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Filing Date</td>
                        <td class="px-2 py-1 text-capitalize">
                            {{ dateFormat(getContinuousFilingTask.data.filingDate) }}
                        </td>
                    </tr>
                    <tr style="border: solid 1px #dedede;">
                        <td class="px-2 py-1 text-capitalize font-bold">Presented by</td>
                        <td class="px-2 py-1 text-capitalize">{{ getContinuousFilingTask.data.presenter.givenName }}
                        </td>
                    </tr>
                    </tbody>
                </table>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <NameChange v-if="getContinuousFilingTask.data.typeOfChangeBeingFiled === 'CHANGE_OF_NAME'"
                            :data="getContinuousFilingTask.data"
                            :attachments="getContinuousFilingTask.data.attachments"/>

                <Dormancy v-if="getContinuousFilingTask.data.typeOfChangeBeingFiled === 'DORMANCY'"
                          :form="getContinuousFilingTask.data.form"
                          :attachments="getContinuousFilingTask.data.attachments"/>

                <MultiChanges v-if="getContinuousFilingTask.data.typeOfChangeBeingFiled === 'MULTI_CHANGES'"
                              :data="getContinuousFilingTask.data"
                              :task-id="getContinuousFilingTask.taskId"
                              :brn="getContinuousFilingTask.data.brn"/>

                <CompanyReregistration v-if="getContinuousFilingTask.data.typeOfChangeBeingFiled === 'RE_REGISTRATION'"
                                       :type="getContinuousFilingTask.data.resolutionType"
                                       :data="getContinuousFilingTask.data.embeddedChanges"/>

                <CompanyConversion v-if="getContinuousFilingTask.data.typeOfChangeBeingFiled === 'CONVERSION'"
                                   :type="getContinuousFilingTask.data.resolutionType"
                                   :data="getContinuousFilingTask.data.embeddedChanges"/>


                <CompanyAmalgamation v-if="getContinuousFilingTask.data.typeOfChangeBeingFiled === 'AMALGAMATION'"
                                     :type="getContinuousFilingTask.data.resolutionType"
                                     :application="getContinuousFilingTask"
                                     :draftData="getContinuousFilingTask.data.embeddedChanges"/>

                <file-form-a23 v-if="getContinuousFilingTask.data.resolutionType === 'FILE_FORM_A23'"
                               :data="getContinuousFilingTask.data.form"/>
                <FileForm17 v-if="getContinuousFilingTask.data.resolutionType === 'FILE_FORM_17'"
                            :data="getContinuousFilingTask.data.form"/>
                <FileForm18 v-if="getContinuousFilingTask.data.resolutionType === 'FILE_FORM_18'"
                            :data="getContinuousFilingTask.data.form"/>
                <ContinousFilingBeneficialOwners
                        v-if="getContinuousFilingTask.data.resolutionType === 'BENEFICIAL_OWNER_SHIP_FORM_METADATA'"
                        :data="getContinuousFilingTask.data.form"></ContinousFilingBeneficialOwners>
                <ContinuousChangeBeneficialOwners
                v-if="getContinuousFilingTask.data.resolutionType === 'NOTICE_OF_CHANGE_OF_BENEFICIAL_OWNER'"
                :data="getContinuousFilingTask.data.form"
                ></ContinuousChangeBeneficialOwners>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import NameChange from "@/views/home/services/continuous-filing/task/components/NameChange";
import Dormancy from "@/views/home/services/continuous-filing/task/components/Dormancy";
import MultiChanges from "@/views/home/services/continuous-filing/task/components/MultiChanges";
import FileForm18 from "@/views/home/services/continuous-filing/task/components/FileForm18";
import FileForm17 from "@/views/home/services/continuous-filing/task/components/FileForm17.vue";
import FileFormA23 from "@/views/home/services/continuous-filing/task/components/FileFormA23.vue";
import CompanyReregistration from "@/views/home/services/continuous-filing/task/components/CompanyReregistration.vue";
import CompanyConversion from "@/views/home/services/continuous-filing/task/components/CompanyConversion.vue";
import CompanyAmalgamation from "@/views/home/services/continuous-filing/task/components/CompanyAmalgamation.vue";
import ContinousFilingBeneficialOwners
    from "@/views/home/services/continuous-filing/task/ContinousFilingBeneficialOwners.vue";
import ContinuousChangeBeneficialOwners
    from "@/views/home/services/continuous-filing/task/ContinuousChangeBeneficialOwners.vue";

export default {
    name: "ContinuousFilingFormDetails",
    components: {
        ContinuousChangeBeneficialOwners,
        ContinousFilingBeneficialOwners,
        CompanyAmalgamation,
        CompanyConversion,
        CompanyReregistration,
        FileFormA23,
        FileForm17,
        FileForm18,
        MultiChanges,
        Dormancy,
        NameChange
    },
    data() {
        return {
            attachmentUrl: null,
            individualDirectors: [],
            corporateDirectors: [],
            individualSecretary: null,
            corporateSecretary: null,
            adoptionTableText: null
        }
    },
    methods: {
        dateFormat(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        cleanName(name) {
            if (name) {
                let n = name.replaceAll("_", " ")
                return n.toLowerCase()
            } else {
                return "N/A"
            }
        },
        filingName(name) {
            if (name === "APPEND_INFORMATION") {
                return "#resolution"
            }
            return "#" + name.replaceAll("_", "").toLowerCase();
        }
    },
    computed: {
        ...mapGetters(["getContinuousFilingTask"])
    },
}
</script>

<style scoped>
th, td {
    font-size: 12px;
}
</style>