<template>
  <div>
    <b-row >
      <b-col>
        <b-card :header="badge">
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-1"
                label="Date of Effect"
                label-for="street">
                <b-form-input
                  id="street"
                  class="form-text mb-3 noFocus"
                  type="text"
                  v-model="form.dateOfEffect"
                  :disabled="true"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <admin-units
              :villageDataInfo="physicalAddress"
              v-on:selectedVillage="selectedVillage">
          </admin-units>
          <b-row>
            <b-col cols="6">
              <b-form-group
                  id="input-group-1"
                  label="Plot Number"
                  label-for="input-1">
                <b-form-input
                    id="plot"
                    class="form-text mb-3 noFocus"
                    type="text"
                    v-model="form.address.plot"
                    :disabled="true"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col cols="6">
              <b-form-group
                  id="input-group-1"
                  label="Postal Address"
                  label-for="input-1">
                <b-form-input
                    id="postalAddress"
                    class="form-text mb-3 noFocus"
                    type="text"
                    v-model="form.address.postalAddress"
                    :disabled="true"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                  id="input-group-1"
                  label="Street"
                  label-for="street">
                <b-form-input
                    id="street"
                    class="form-text mb-3 noFocus"
                    type="text"
                    v-model="form.address.street"
                    :disabled="true"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col>
              <b-form-group
                id="input-group-1"
                label="Office of this Company is Situated at (More Description)"
                label-for="description">
                <b-form-textarea
                  id="description"
                  class="form-text noFocus"
                  :disabled="true"
                  type="text"
                  v-model="form.description"
                  rows="6"
                  required>
                </b-form-textarea>
              </b-form-group>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import AdminUnits from "@/views/home/commons/AdminUnits";

export default {
  name: "BsiForm18Resolution",
  components: { AdminUnits},
  props:{
    data:{},
    badge:String
  },
  data(){
    return{
      physicalAddress:{},
      form:{
        dateOfEffect:null,
        address: {
          plot:null,
          street:null,
          postalAddress:null,
        },
        villageId:null,
        description:null,
        formCode:"form18"
      },
      villageList:[],
    }
  },
  mounted() {
    this.checkData()
  },
  methods:{
    ...mapActions(["fetchVillage", "fetchVillageDetails"]),
    selectVillage(){
      this.modalShowVillageLocation = true
    },
    selectedVillage(data){
      this.form.villageId = data
    },
    filterDistricts(data){
      let arrayData = []
      let obj = {}
      data.forEach(list => {
        obj = {
          value:list.id,
          text:list.name
        }
        arrayData.push(obj)
      })
      return arrayData
    },
    checkData(){
      if(this.data){
        this.setData(this.data)
      }
    },
    setData(data){
      if(data) {
        this.form.dateOfEffect = data.dateOfEffect ? data.dateOfEffect : ""
        this.form.description = data.description ? data.description : ""
        this.form.villageId = data.villageId ? data.villageId : ""
        this.form.address.plot = data.address.plot ? data.address.plot : ""
        this.form.address.street = data.address.street ? data.address.street : ""
        this.form.address.postalAddress = data.address.postalAddress ? data.address.postalAddress : ""
        this.physicalAddress = data.address.physicalAddress ? data.address.physicalAddress : {}
      }
    }
  },
  computed:{
    ...mapGetters(["getVillageResultsData", "getVillageDetails"])
  },
  watch:{
    address(){
      this.checkData()
    },
    getVillageDetails(data){
      this.physicalAddress = data.data
    }
  }
}
</script>

<style scoped>

</style>