<template>
    <div class="my-3">

        <b-row>
            <b-col>
                <BsiMultiChanges
                              :data="getBsiContinuousFilingTask.data"
                              />

            </b-col>
        </b-row>
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import moment from "moment";
import BsiMultiChanges from "@/views/home/services/continuous-filing/task/components/BsiMultiChanges.vue";
export default {
    name: "BsiContinuousFilingFormDetails",
    components: {
        BsiMultiChanges
    },
    data() {
        return {
            attachmentUrl: null,
            individualDirectors: [],
            corporateDirectors: [],
            individualSecretary: null,
            corporateSecretary: null,
            adoptionTableText: null
        }
    },
    methods: {
        dateFormat(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        cleanName(name) {
            if (name) {
                let n = name.replaceAll("_", " ")
                return n.toLowerCase()
            } else {
                return "N/A"
            }
        },

    },
    computed: {
        ...mapGetters(["getBsiContinuousFilingTask"])
    },
}
</script>

<style scoped>
th, td {
    font-size: 12px;
}
</style>