<template>
  <div>

        <div v-if="companiesToBeAmalgamated && (companiesToBeAmalgamated.length > 0)">

            <div v-for="(info, idx) in companiesToBeAmalgamated" :key="idx">

                <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Attachments for {{info.name ? info.name : ""}}</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">#</th>
                    <th class="px-2 py-1">File Name</th>
                    <th class="px-2 py-1 float-end">Action</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr v-for="(file, idx) in draft[`RESOLUTION_FOR_${info.brn}`].data.attachments" :key="idx" style="border: solid 1px #dedede;">
                    <td class="px-2 py-1">{{idx + 1}}</td>
                    <td class="px-2 py-1 text-capitalize">{{cleanName(file.purpose)}}</td>
                    <td class="px-2 py-1">
                      <button class="float-end bg-gray rounded text-white hover:bg-gray-dark px-2" @click="openFile(file)">Show</button>
                    </td>
                  </tr>
                  </tbody>
                </table>
            </div>

        </div>


  </div>
</template>

<script>
import moment from "moment/moment";

export default {
  name: "AmalgamationContinuousFilingFiles",
  components: {  },
  props:{
    draftData:{}
  },
  data(){
    return{
      attachmentUrl:null,
      draft:null,
      companiesToBeAmalgamated:[]
    }
  },
  mounted() {
    this.filterDraftData()
  },
  methods:{
    filterDraftData(){
      let draft = this.draftData.data.embeddedChanges;
      if(draft){
        this.draft = draft;
        let metaData = draft.AMALGAMATION_METADATA.data;
        this.companiesToBeAmalgamated = metaData.companiesToBeAmalgamated.filter(item => item.brn !== metaData.amalgamationEntityBrn)
      }
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    openFile(data){
      this.$emit("url",data)
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
  },

  watch:{
    draftData(data){
      if(data) {
        this.filterDraftData()
      }
    }
  }
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>