<template>
    <div>

        <b-row>
      <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Escalate Incorporation Further">
        <Escalation :task-id="task.id" type="insolvency" permission="can_assign_insolvency_application"/>
      </b-modal>
            <b-modal id="modal-a-assign" hide-footer ref="assignSubmissions" title="Assign to Registrar">
                <Assignment :id="task.id" type="insolvency" permission="can_assign_insolvency_application"/>
            </b-modal>
            <b-modal id="modal-a-reply" hide-footer ref="replySubmissions" title="Reply to Escalation">
                <AssignmentReply :id="task.id" :user-id="source.id" type="insolvency" permission="can_assign_insolvency_application"/>
            </b-modal>
        </b-row>

        <b-row>
            <b-modal id="modal-assign" hide-footer ref="escalateSubmissions" title="Insolvency Winding Up">
                <Escalation :task-id="task.id" type="insolvency" permission="can_view_insolvency_escalations"/>
            </b-modal>
            <b-modal id="modal-confirm" hide-footer ref="showDissolution" title="Confirm">
                <b-row>
                    <b-col>
                        <div class="mx-auto mb-2">
                            <label class="text-gray text-sm">Date Of Dissolution</label>
                            <datepicker

                                    placeholder="dd/mm/yyyy"
                                    class="noFocus bg-white cursor-pointer text-sm"
                                    bootstrap-styling
                                    id="dateOfBirth"
                                    format="dd/MM/yyyy"
                                    required
                                    v-model="dateOfDissolution">
                            </datepicker>
                        </div>

                        <div class="flex flex-grow justify-between mt-4 mb-3">
                            &nbsp;
                            <button class="px-3 py-1 bg-green rounded-sm text-white hover:bg-green-dark"
                                    @click="saveDissolutionDate">
                                <i class="fa fa-check-circle text-white mr-1"></i> Save Date of dissolution
                            </button>
                        </div>

                    </b-col>
                </b-row>
            </b-modal>
            <b-modal id="modal-confirm" hide-footer ref="confirm" title="Confirm">
                <b-row>
                    <b-col>
                        <p class="text-sm">
                            <span class="h6">Are you sure you are ready to take this action?</span>.<br/>
                            This action can only be reversed by your superiors. Only confirm if you are certain that
                            this is the appropriate Final Decision
                        </p>
                        <p class="mt-3 text-sm italic">
                            <b>Note:</b><br/>
                            All actions performed on <b class="text-blue">OBRS</b> are recorded and details of the
                            account that initiated are tagged to the action.
                        </p>
                    </b-col>
                </b-row>
                <b-row v-if="form.status === 'REJECTED'">
                    <b-col>
                        <b-form class="w-full">
                            <b-form-textarea
                                    v-model="form.description"
                                    type="text"
                                    placeholder="Give reason for rejection"
                                    class="mt-1"
                                    required>
                            </b-form-textarea>
                        </b-form>
                    </b-col>
                </b-row>
                <b-row class="mt-3">
                    <b-col cols="4">
                        <button class="px-3 py-2 border-2 border-blue bg-white rounded-sm text-blue w-full"
                                @click="cancelConfirmation">
                            <i class="fa fa-minus-square text-blue mr-2"></i>Cancel
                        </button>
                    </b-col>
                    <b-col cols="4"></b-col>
                    <b-col cols="4">
                        <button class="px-3 py-2 bg-green rounded-sm text-white w-full hover:bg-green-dark"
                                @click="yesConfirmation">
                            <i class="fa fa-check-circle text-white mr-1"></i> Accept
                        </button>
                    </b-col>
                </b-row>
            </b-modal>
            <b-modal id="modal-query" hide-footer ref="query-incorporation" title="Query Submission">
                <b-col cols="12">
                    <b-form class="w-full">
                        <b-form-textarea
                                v-model="form.description"
                                type="text"
                                placeholder="Give description of query"
                                class="mt-1"
                                required>
                        </b-form-textarea>
                        <p v-if="form.description" class="my-2 text-xs">{{ form.description.length }}/{{ maxText }}</p>
                        <b-row>
                            <b-col cols="4">
                                <b-button class="btn-query-hover" variant="primary" @click="onQuery">
                                    Query
                                    <b-spinner small v-if="loading"></b-spinner>
                                </b-button>
                            </b-col>
                        </b-row>
                    </b-form>
                </b-col>
            </b-modal>
        </b-row>

        <b-row class="mx-3 mb-3s">
            <b-col cols="12">
                <b-row class=" py-2 my-2 shadow-md rounded-sm" style="background: #b0ea75;">
                    <b-col cols="10">
                        <div class="flex">
                            <i class="fa fa-comment-alt text-green mr-1 mt-1"></i>
                            <span class="text-capitalize font-bold underline hover:text-green">{{ source.givenName +" "+source.surname  }}</span>
                        </div>
                        <p class="text-gray mt-2">
                            {{ message }}
                        </p>
                        <span class="italic text-gray"
                              style="font-size: 10px;">{{ dateTimeFormat(task.createdAt) }}</span>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>

        <b-row class="mx-1" v-if="task">
            <b-col cols="12">
                <b-row class="mx-1">
                    <b-col>
                        <InsolvencyMessages :task="task" mode="TOP_MESSAGE"/>
                    </b-col>
                </b-row>
                <b-row class="mt-1">
                    <b-col cols="12">
                        <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                            <thead>
                            <tr class="bg-gray-pale">
                                <td colspan="12" class="px-2 py-2">Insolvency Details</td>
                            </tr>
                            <tr>
                                <th class="px-2 py-1">Tracking Number</th>
                                <th class="px-2 py-1">Registration Number</th>
                                <th class="px-2 py-1">Status</th>
                                <th class="px-2 py-1">Type</th>
                                <th class="px-2 py-1">Name</th>
                                <th class="px-2 py-1">Winding Up Type</th>
                                <th class="px-2 py-1">Has Assets</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr style="border: solid 1px #dedede;">
                                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{ task.trackingNumber }}
                  </span>
                                </td>
                                <td class="px-2 py-1">
                  <span class="text-gray">
                    {{ task.brn }}
                  </span>
                                </td>
                                <td class="px-2 py-1">
                                    <span class="text-blue text-capitalize"
                                          v-if="task.status">{{ task.status.toLowerCase() }}</span>
                                </td>
                                <td class="px-2 py-1">
                                    <span class="text-gray text-capitalize">{{ cleanEntityType(task.entityType) }}</span>
                                </td>
                                <td class="px-2 py-1">
                                    <span class="text-gray text-capitalize"> {{ task.companyName }}</span>
                                </td>
                                <td class="px-2 py-1">
                  <span class="text-gray italic">
                    {{ task.windingUpType }}
                  </span>
                                </td>
                                <td class="px-2 py-1">
                  <span class="text-gray italic">
                {{ task.hasAsset }}
                  </span>
                                </td>
                            </tr>
                            </tbody>
                        </table>
                    </b-col>
                </b-row>

                <b-overlay :show="loading" class="text-center py-5">
                    <b-row class="my-1">
                        <b-col>
                            <div class="flex float-end">
                                <button class="btn-escalate" @click="escalatePrompt">
                                    <i class="fa fa-arrow-up text-blue mr-1"></i>Escalate
                                </button>
                                <button type="button" class="btn-query" v-b-modal.modal-query>
                                    <i class="fa fa-question text-white mr-1"></i> Query
                                </button>
                                <button v-if="canViewActionWithPermission('can_extend_dissolution_date') && (task.stage==='FINAL_REPORT_NOTICE') && (task.type === 'WINDING_UP') && (task.hasAsset === 'YES')"
                                        type="button" class="btn-date" @click="addDissolutionDate">
                                    <i class="fa fa-calendar text-white mr-1"></i> Add Dissolution Date
                                </button>
                                <button v-if="(task.stage==='NOTICE' || task.stage==='GAZETTE_OR_NEWSPAPER_NOTICES'  ) && canViewActionWithPermission('can_extend_dissolution_date')  && (task.type === 'WINDING_UP') && (task.hasAsset === 'NO')"
                                        type="button" class="btn-date" @click="addDissolutionDate">
                                    <i class="fa fa-calendar text-white mr-1"></i> Add Dissolution Date
                                </button>
                                <button v-if="(task.stage==='REPORTS') && canViewActionWithPermission('can_extend_dissolution_date') && (task.type === 'WINDING_UP')"
                                        type="button" class="btn-date" @click="addDissolutionDate">
                                    <i class="fa fa-calendar text-white mr-1"></i> Add Dissolution Date
                                </button>
                                <button v-if=" (task.type === 'WINDING_UP' ||
                 task.type==='FOREIGN_COMPANY_DE_REGISTRATION')"
                                        type="button" class="btn-reject" @click="onApprove('REJECTED')">
                                    <i class="fa fa-check-circle text-white mr-1"></i> Reject
                                </button>
                                <button type="button" class="btn-approve" @click="onApprove('APPROVED')">
                                    <i class="fa fa-check-circle text-white mr-1"></i> Approve
                                </button>

                                <button class="btn-approve" @click="replyPrompt">
                  <i class="fa fa-reply text-white mr-1"></i> Reply
                </button>
                <button class="btn-approve" @click="assignPrompt">
                  <i class="fa fa-hand-pointer text-white mr-1"></i> Assign
                </button>

                            </div>
                        </b-col>
                    </b-row>
                </b-overlay>
                <b-row>
                    <b-col cols="12">
                        <b-row>
                            <b-col cols="12">
                                <div class="flex h-full" style="cursor: pointer;">
                                    <div :class="[(currentRoute === 'forms') ? 'menu-item-active' : 'menu-item']"
                                         @click="select('forms')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px">Company Information</div>
                                        </div>
                                    </div>
                                    <div :class="[(currentRoute === 'attachments') ? 'menu-item-active' : 'menu-item']"
                                         @click="select('attachments')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px">Attachments</div>
                                        </div>
                                    </div>
                                    <div :class="[(currentRoute === 'messages') ? 'menu-item-active' : 'menu-item']"
                                         @click="select('messages')">
                                        <div class="flex mt-6">
                                            <div style="font-size: 14px">Messages</div>
                                        </div>
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <b-row>
                            <b-col cols="12" class="mx-auto">
                                <WindingInsolvencyForms v-if="currentRoute === 'forms'" :task="task"/>
                                <InsolvencyFormFiles v-if="currentRoute === 'attachments'" :task="task"/>
                                <InsolvencyMessages v-if="currentRoute === 'messages'" :task="task" mode="THREAD"/>
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>

            </b-col>
        </b-row>

    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import WindingInsolvencyForms from "@/views/home/services/insolvency/task-queue/WindingInsolvencyForms.vue";
import InsolvencyFormFiles from "@/views/home/services/insolvency/task-queue/InsolvencyFormFiles.vue";
import InsolvencyMessages from "@/views/home/services/insolvency/task-queue/InsolvencyMessages.vue";
import Escalation from "@/views/home/commons/Escalation.vue";
import Datepicker from "vuejs-datepicker";
import {api} from "@/app/services/api.service";
import Assignment from "@/views/home/commons/Assignment.vue";
import AssignmentReply from "@/views/home/commons/AssignmentReply.vue";

export default {
    name: "InsolvencyEscalationFile",
    components: {
        AssignmentReply,
        Assignment,
        Escalation,
        InsolvencyMessages,
        InsolvencyFormFiles,
        WindingInsolvencyForms,
        Datepicker
    },
    props: {
        task: Object,
        taskType: String,
        source:Object,
        destination : Object,
        message : String

    },
    data() {
        return {
            margin: "2px",
            currentRoute: "forms",
            loading: false,
            messages: [],
            action: null,
            maxText: 500,
            snapshot: null,
            snapshots: [],
            dateOfDissolution: null,
            form: {
                insolvencyProcessId: null,
                description: null,
                status: null
            },
            edmsDocuments: [],
            loadingDocuments: false,
            rows: {
                odd: "border-t border-grey hover:bg-header_grey",
                even: "border-t border-grey hover:bg-header_grey"
            },
        }
    },
    mounted() {
        this.checkTask()
    },
    methods: {
        ...mapActions(["updateInsolvencyStatus", "fetchInsolvencyTask"]),
        checkTask() {
            if (this.task) {
                let draft = this.task
                this.dateOfDissolution = draft.dateOfDissolution ? draft.dateOfDissolution : null
            }
        },
        addDissolutionDate() {
            this.$refs.showDissolution.show();
        },
        saveDissolutionDate() {
            let data = {
                trackingNumber: this.task.trackingNumber,
                dateOfDissolution: this.dateOfDissolution
            }
            this.saveDate(data)
        },
        async saveDate(data) {
            await api().post("insolvency/winding-up/add-date-of-dissolution", data)
                .then((response) => {
                    if (response && response.data) {
                        this.fetchInsolvencyTask(this.taskType)
                        this.successToast("Success", response.data.message)
                        this.$refs.showDissolution.hide()
                    }

                })
                .catch((error) => {
                    this.loading = false;
                    this.errorToast("Error", error.response.data.message);
                    this.$emit("formErrorResponse", error.response.data.message);
                });
        },
        onApprove(status) {
            this.form.insolvencyProcessId = this.task.id
            this.form.status = status
            this.action = "final"
            this.$refs.confirm.show();
        },
        onQuery() {
            this.form.insolvencyProcessId = this.task.id
            this.form.status = "QUERIED"
            this.action = "final"
            if (this.form.description != null) {
                this.$refs.confirm.show();
            } else {
                this.errorToast("Missing Information", "Provide a message for this final decision")
            }
        },
        select(route) {
            this.currentRoute = route
        },
        yesConfirmation() {
            if (this.action === "final") {
                if (this.form.status === 'REJECTED') {
                    if (this.form.description === null) {
                        this.errorToast("Error", "Please provide a reason for rejection");
                        return;
                    }
                }
                this.loading = true
                this.updateInsolvencyStatus(this.form)
            }
            if (this.action === "escalate") {
                this.$refs.escalateSubmissions.show();
            }
            this.cancelConfirmation()
        },
        cancelConfirmation() {
            this.action = null
            this.$refs.confirm.hide();
        },
        escalatePrompt() {
            this.action = "escalate"
            this.$refs.confirm.show();
        },
        cleanEntityType(name) {
            if (name) {
                let n = name.replaceAll("-", " ")
                return n.charAt(0).toUpperCase() + n.slice(1)
            }
            return ""
        },
        dateFormat(date) {
            return moment(date).format("DD-MM-YYYY")
        },
        dateTimeFormat(date) {
            return moment(date).format("DD-MM-YYYY HH:mm")
        },
        canViewActionWithPermission(permission) {
            return this.$can(permission, "general") === true;
        },
        replyPrompt() {
            this.$refs.replySubmissions.show();
        },
        assignPrompt() {
            this.$refs.assignSubmissions.show();
        }
    },
    computed: {
        ...mapGetters([
            "getInsolvencyStatusSuccess",
            "getInsolvencyTaskError",
            "getInsolvencyError"
        ])
    },
    watch: {
        task(data) {
            if (data) {
                this.checkTask()
            }
        },
        getInsolvencyTaskError(data) {
            this.loading = false
            this.errorToast("Error", data.messages)
        },
        getInsolvencyStatusSuccess() {
            this.loading = false
            this.successToast("Success!", "Final Decision Successful")
            window.location.reload()
        },
        getInsolvencyError(error) {
            this.errorToast("Insolvency  Error", error.data.message);
        }
    }
}


</script>

<style scoped>

.icon{
    width: 170px;
}

th, td{
    font-size: 12px;
}

.btn-escalate{
    @apply border border-blue rounded-sm text-blue text-sm px-3 py-1 mx-1 bg-white;
}

.btn-escalate:hover{
    @apply text-blue-dark;
}

.btn-approve{
    @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-date {
    @apply bg-green-dark rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-query {
    @apply border border-blue bg-blue rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-reject {
    @apply border border-blue bg-red rounded-sm text-white text-sm px-3 py-1 mx-1;
}

.btn-approve:hover{
    @apply bg-green border-green text-white;
}

.btn-query:hover {
    @apply bg-orange border-orange text-white;
}

.btn-cancel:hover {
    @apply bg-red border-red text-white;
}

.btn-approve-hover {
    @apply bg-green border-green text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-query-hover{
    @apply bg-orange border-orange text-white mt-2 px-4 py-1 rounded-sm;
}

.btn-approve-hover:hover {
    @apply bg-green-dark;
}

.btn-query-hover:hover{
    @apply bg-orange-dark;
}

</style>