<template>
  <div>
    <div class="bg-white rounded-sm m-2">
      <b-row>
        <b-col cols="12" class="m-2">
          <b-row>
            <b-col cols="6">
              <span class="text-xs px-2 py-1 text-red">
                <b>{{getPractitionerCount.total}}</b> Total Submissions
              </span>
              <span class="text-xs px-2 py-1 text-blue ml-2">
                <b>{{ getPractitionerCount.assigned}}</b> Assigned
              </span>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
    <div class="bg-white rounded-sm m-2">
      <b-row class="mx-1 py-2" v-if="getPractitionerTask != null">
        <b-col cols="6" >
          <b-row>
            <b-col>
              <span class="bg-blue text-white px-2 py-1 rounded-sm text-xs font-bold">#InsolvencyPractitioner</span>
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="6"></b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <b-row v-if="getPractitionerTask == null" class="mx-1 py-5">
            <b-col cols="12" class="py-5">
              <b-row>
                <b-col cols="12" class="text-center justify-content-center">
                  <img :src="require('../../../../../assets/images/ic_empty_box.png')" class="icon mx-auto">
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="12" class="text-center">
                  <div class="h5 text-gray mx-auto">There are no Insolvency Practitioner Applications left in the queue</div>
                </b-col>
              </b-row>
              <b-row>
                <b-col cols="12" class="text-center">
                  <div class="text-gray mx-auto">Tasks will be available when clients submit new <b class="text-blue">applications</b> or when <b class="text-orange">queries</b> are responded to.</div>
                </b-col>
              </b-row>
              <b-row class="mt-4">
                <b-col cols="12" class="text-center">
                  <button class="text-white bg-blue rounded-md px-2 py-2 mx-auto hover:bg-blue-dark" @click="checkForUpdates">Check for Updates</button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row v-else>
            <b-col cols="12">
              <InsolvencyPractitionerTask :task="getPractitionerTask"/>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex"
import moment from "moment";
import InsolvencyPractitionerTask from "@/views/home/services/insolvency/practitioners/InsolvencyPractitionerTask";

export default {
  name: "InsolvencyPractitionerTaskQueue",
  components: {
    InsolvencyPractitionerTask
  },
  data(){
    return{
      margin:"2px",
      loading:true,
      message:null,
    }
  },
  mounted() {
      // check if the url contains renwal set type to PRACTITIONER_REGISTRATION else PRACTITIONER_RENEWAL

   let type = "PRACTITIONER_REGISTRATION"
        if(this.$route.path.includes("renewal")){
            type = "PRACTITIONER_RENEWAL"
        }

    this.fetchInsolvencyPractitionerTask(type)
    this.fetchPractitionersQueueCount(type)
  },
  methods:{
    ...mapActions(["fetchInsolvencyPractitionerTask", "fetchPractitionersQueueCount"]),
    checkForUpdates(){
      this.loading = true
      window.location.reload()
    },
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    }
  },
  computed:{
    ...mapGetters(["getPractitionerTask", "getPractitionerCount", "getPractitionerTaskError"]),
  },
  watch:{
  }
}
</script>

<style scoped>
@import "../../../../../assets/styles/menu.css";

.icon{
  width: 170px;
}

</style>