<template>
  <b-col>
    <b-row>
      <b-col cols="12" class="mx-auto mt-4">
        <table class="w-full leading-normal text-blue-dark"
               style="border: solid 1px #dedede;">
          <thead class="bg-blue-pale" >
          <tr>
            <th colspan="12" class="py-2">
              <span class="px-2">{{nature}}</span>
            </th>
          </tr>
          </thead>
          <tbody>
          <LegalPersons v-for="(member,idx) in members" class="p-1" :persons="[{individual:member,entityType:'INDIVIDUAL'}]" :key="idx"/>

          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-col>
</template>

<script>
import LegalPersons from "@/views/home/commons/LegalPersons.vue";

export default {
  name: "BuildingSocietyMembers",
  components: {LegalPersons},
  props:{
      members: [],
      nature: String
  }
}
</script>

<style scoped>

</style>