<template>
  <b-col cols="12" class="mb-4 overflow-hidden">
    <b-row>
      <b-col cols="10" class="my-4"></b-col>
    </b-row>
    <!-- Analytics-->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/dashboard')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_dashboard', 'dashboard')" @click="select('/home/dashboard')">
          <div  class="flex" v-if="canViewMenuItem('can_access_dashboard', 'dashboard')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-home"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Home</div>
              </div>
            </b-col>
          </div>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/dashboard') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/dashboard')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-chart-area mt-2" id="analytics"></i>
            <b-tooltip target="analytics" triggers="hover" placement="right">
              <span>
                Analytics Page
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
   <!--  Reservations -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/reservation')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_reservations', 'reservations')" @click="select('/home/reservation')">
          <div  class="flex" v-if="canViewMenuItem('can_access_reservations', 'reservations')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-book"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Reservations</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/reservation')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_reservation_tasks', 'reservations')">
                  <b-col cols="12" :class="(currentRoute === '/home/reservation/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/reservation/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/reservation/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_reservation_submissions', 'reservations')">
                  <b-col cols="12" :class="(currentRoute === '/home/reservation/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/reservation/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/reservation/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_reservation_extensions', 'reservations')">
                  <b-col cols="12" :class="(currentRoute === '/home/reservation/extensions') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/reservation/extensions')"
                      :class="[compareMenuItem(currentRoute, '/home/reservation/extensions') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Extensions
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{reservationExtensions}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_reservation_appeals', 'reservations')">
                  <b-col cols="12" :class="(currentRoute === '/home/reservation/appeals') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/reservation/appeals')"
                      :class="[compareMenuItem(currentRoute, '/home/reservation/appeals') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Appeals
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{reservationAppeals}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_reservation_escalations', 'reservations')">
                  <b-col cols="12" :class="(currentRoute === '/home/reservation/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/reservation/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/reservation/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{reservationEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_name_search', 'reservations')">
                  <b-col cols="12" :class="(currentRoute === '/home/reservation/search') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/reservation/search')"
                      :class="[compareMenuItem(currentRoute, '/home/reservation/search') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Advanced Search
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/reservation') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/reservation')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-book mt-2" id="rsv"></i>
            <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                Reservations Page
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Business Names -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/businesses')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_business_names', 'registration')" @click="select('/home/businesses')">
          <div  class="flex" v-if="canViewMenuItem('can_access_business_names', 'registration')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-handshake"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Business Names</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/businesses')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_access_bname_reg_tasks', 'registration')">
                  <b-col cols="12" :class="(currentRoute === '/home/businesses/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/businesses/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/businesses/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_bname_reg_submissions', 'registration')">
                  <b-col cols="12" :class="(currentRoute === '/home/businesses/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/businesses/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/businesses/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_bname_reg_escalations', 'registration')">
                  <b-col cols="12" :class="(currentRoute === '/home/businesses/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/businesses/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/businesses/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{businessNameEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/businesses') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/businesses')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-handshake mt-2" id="bn"></i>
            <b-tooltip target="bn" triggers="hover" placement="right">
              <span>
                Business Name Registration
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Companies -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/companies')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_companies', 'incorporation')" @click="select('/home/companies')">
          <div  class="flex" v-if="canViewMenuItem('can_access_companies', 'incorporation')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-briefcase"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Companies</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/companies')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_incorporation_tasks', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/companies/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/companies/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/companies/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_incorporation_submissions', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/companies/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/companies/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/companies/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_incorporation_escalations', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/companies/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/companies/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/companies/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{incorporationEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/companies') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/companies')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-briefcase mt-2" id="ci"></i>
            <b-tooltip target="ci" triggers="hover" placement="right">
              <span>
                Company Incorporations
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Dispensations -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/dispensations')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_dispensations', 'dispensations')" @click="select('/home/dispensations')">
          <div  class="flex" v-if="canViewMenuItem('can_access_dispensations', 'dispensations')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fas fa-strikethrough"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Dispensations</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/dispensations')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_dispensation_tasks', 'dispensations')">
                  <b-col cols="12" :class="(currentRoute === '/home/dispensations/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/dispensations/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/dispensations/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_dispensation_submissions', 'dispensations')">
                  <b-col cols="12" :class="(currentRoute === '/home/dispensations/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/dispensations/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/dispensations/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_dispensation_escalations', 'dispensations')">
                  <b-col cols="12" :class="(currentRoute === '/home/dispensations/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/dispensations/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/dispensations/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{dispensationEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/companies') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/companies')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-remove-format mt-2" id="ci"></i>
            <b-tooltip target="ci" triggers="hover" placement="right">
              <span>
                Dispensation
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Building Societies -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/building-society')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_building_societies', 'building-society-incorporation')"
          @click="select('/home/building-society')">
          <div  class="flex" v-if="canViewMenuItem('can_access_building_societies', 'building-society-incorporation')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-users"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Building Societies</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/building-society')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_building_society_incorporation_tasks', 'building-society-incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/building-society/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/building-society/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/building-society/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_building_society_incorporation_submissions', 'building-society-incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/building-society/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/building-society/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/building-society/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_building_society_incorporation_escalations', 'building-society-incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/building-society/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/building-society/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/building-society/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{societyEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/building-society') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/building-society')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-users mt-2" id="rsv"></i>
            <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                Business Name Registration
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Legal Documents -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/legal-documents')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_legal_documents', 'reservations')" @click="select('/home/legal-documents')">
          <div  class="flex" v-if="canViewMenuItem('can_access_legal_documents', 'legal-documents')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-file"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Legal Documents</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/legal-documents')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_legal_documents_tasks', 'legal-documents')">
                  <b-col cols="12" :class="(currentRoute === '/home/legal-documents/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/legal-documents/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/legal-documents/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_legal_documents_submissions', 'legal-documents')">
                  <b-col cols="12" :class="(currentRoute === '/home/legal-documents/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/legal-documents/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/legal-documents/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_legal_documents_escalations', 'legal-documents')">
                  <b-col cols="12" :class="(currentRoute === '/home/legal-documents/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/legal-documents/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/legal-documents/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{legalDocumentEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/legal-documents') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/legal-documents')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-file mt-2" id="lgd"></i>
            <b-tooltip target="lgd" triggers="hover" placement="right">
              <span>
                Business Name Registration
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Amendments -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/business-amendments')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_bname_amendments', 'reservations')" @click="select('/home/business-amendments')">
          <div  class="flex" v-if="canViewMenuItem('can_access_bname_amendments', 'bname-amendments')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-edit"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Business Amendments</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/business-amendments')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_bname_amendments_tasks', 'bname-amendments')">
                  <b-col cols="12" :class="(currentRoute === '/home/business-amendments/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/business-amendments/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/business-amendments/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_bname_amendments_submissions', 'bname-amendments')">
                  <b-col cols="12" :class="(currentRoute === '/home/business-amendments/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/business-amendments/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/business-amendments/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_bname_amendments_escalations', 'bname-amendments')">
                  <b-col cols="12" :class="(currentRoute === '/home/business-amendments/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/business-amendments/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/business-amendments/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{amendmentEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/business-amendments') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/business-amendments')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-edit mt-2" id="amd"></i>
            <b-tooltip target="amd" triggers="hover" placement="right">
              <span>
                Business Name Amendments
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Continuous Filing -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/continuous-filing')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_continuous_filing', 'continuous-filing')"
          @click="select('/home/continuous-filing')">
          <div  class="flex" v-if="canViewMenuItem('can_access_continuous_filing', 'continuous-filing')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-folder"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Continuous Filing</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/continuous-filing')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_continuous_filing_tasks', 'continuous-filing')">
                  <b-col cols="12" :class="(currentRoute === '/home/continuous-filing/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/continuous-filing/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/continuous-filing/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_continuous_filing_submissions', 'continuous-filing')">
                  <b-col cols="12" :class="(currentRoute === '/home/continuous-filing/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/continuous-filing/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/continuous-filing/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_continuous_filing_escalations', 'continuous-filing')">
                  <b-col cols="12" :class="(currentRoute === '/home/continuous-filing/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/continuous-filing/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/continuous-filing/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{continuousFilingEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/continuous-filing') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/continuous-filing')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-folder mt-2" id="rsv"></i>
            <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                Continuous Filing
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

      <!-- BSI Continuous Filing -->
      <b-row>
          <b-col cols="12" v-if="full">
              <b-row
                      :class="[(compareMenuItem(currentRoute, '/home/bsi-continuous-filing')) ? 'side-menu-item-active' : 'side-menu-item']"
                      v-show="canViewMenuItem('can_access_bsi_continuous_filing', 'continuous-filing')"
                      @click="select('/home/bsi-continuous-filing')">
                  <div  class="flex" v-if="canViewMenuItem('can_access_bsi_continuous_filing', 'continuous-filing')" >
                      <b-col cols="1"></b-col>
                      <b-col cols="2">
                          <i class="fa fa-folder"></i>
                      </b-col>
                      <b-col cols="8">
                          <div>
                              <div class="menu-text mt-1">BSI Continuous Filing</div>
                          </div>
                      </b-col>
                  </div>
              </b-row>
              <b-row v-if="compareMenuItem(currentRoute, '/home/bsi-continuous-filing')">
                  <b-col class="bg-header_grey">
                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_bsi_continuous_filing_tasks', 'continuous-filing')">
                                  <b-col cols="12" :class="(currentRoute === '/home/bsi-continuous-filing/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/bsi-continuous-filing/queue-task')"
                                              :class="[compareMenuItem(currentRoute, '/home/bsi-continuous-filing/queue-task') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Task Queue
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_all_bsi_continuous_filing_submissions', 'continuous-filing')">
                                  <b-col cols="12" :class="(currentRoute === '/home/bsi-continuous-filing/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/bsi-continuous-filing/applications')"
                                              :class="[compareMenuItem(currentRoute, '/home/bsi-continuous-filing/applications') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Applications
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_bsi_continuous_filing_escalations', 'continuous-filing')">
                                  <b-col cols="12" :class="(currentRoute === '/home/bsi-continuous-filing/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/bsi-continuous-filing/escalations')"
                                              :class="[compareMenuItem(currentRoute, '/home/bsi-continuous-filing/escalations') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Escalations
                                          </b-col>
                                          <b-col class="float-end">
                                              <b-badge class="bg-blue float-end">
                                                  {{continuousFilingEscalations}}
                                              </b-badge>
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>
          </b-col>
          <b-col cols="12" v-else>
              <b-row
                      :class="[compareMenuItem(currentRoute, '/home/bsi-continuous-filing') ? 'side-menu-item-active' : 'side-menu-item']"
                      @click="select('/home/bsi-continuous-filing')">
                  <b-col cols="10"  class="mx-auto text-center">
                      <i class="fa fa-folder mt-2" id="rsv"></i>
                      <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                BSI Continuous Filing
              </span>
                      </b-tooltip>
                  </b-col>
              </b-row>
          </b-col>
      </b-row>
    <!-- Annual Returns -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/annual-returns')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_annual_returns', 'continuous-filing')"
          @click="select('/home/annual-returns')">
          <div  class="flex" v-if="canViewMenuItem('can_access_annual_returns', 'continuous-filing')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-calendar"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Annual Returns</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/annual-returns')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_annual_returns_tasks', 'continuous-filing')">
                  <b-col cols="12" :class="(currentRoute === '/home/annual-returns/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/annual-returns/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/annual-returns/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_annual_returns_submissions', 'continuous-filing')">
                  <b-col cols="12" :class="(currentRoute === '/home/annual-returns/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/annual-returns/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/annual-returns/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_annual_returns_escalations', 'continuous-filing')">
                  <b-col cols="12" :class="(currentRoute === '/home/annual-returns/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/annual-returns/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/annual-returns/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{annualReturnsEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/annual-returns') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/annual-returns')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-calendar mt-2" id="rsv"></i>
            <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                Annual Returns
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Business Names Data Update -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/data-update-businesses')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_business_names_data_update', 'registration')" @click="select('/home/data-update-businesses')">
          <div  class="flex" v-if="canViewMenuItem('can_access_business_names_data_update', 'registration')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-pen-square"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Bns.Name DataUpdate</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/data-update-businesses')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_access_bname_data_update_tasks', 'registration')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-businesses/queue-task/level-1') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-businesses/queue-task/level-1')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-businesses/queue-task/level-1') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue Level 1
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
<!--            <b-row>-->
<!--              <b-col cols="3"></b-col>-->
<!--              <b-col cols="9">-->
<!--                <b-row v-if="canViewMenuItem('can_access_bname_data_update_tasks_level2', 'registration')">-->
<!--                  <b-col cols="12" :class="(currentRoute === '/home/data-update-businesses/queue-task/level-2') ? 'sub-side-menu-active' : 'sub-side-menu'">-->
<!--                    <b-row-->
<!--                      @click="select('/home/data-update-businesses/queue-task/level-2')"-->
<!--                      :class="[compareMenuItem(currentRoute, '/home/data-update-businesses/queue-task/level-2') ? 'flex bg-header_grey' : 'flex']">-->
<!--                      <b-col class="ml-1 menu-text">-->
<!--                        Task Queue Level 2-->
<!--                      </b-col>-->
<!--                    </b-row>-->
<!--                  </b-col>-->
<!--                </b-row>-->
<!--              </b-col>-->
<!--            </b-row>-->
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_access_bname_data_update_tasks_level3', 'registration')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-businesses/queue-task/level-3') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-businesses/queue-task/level-3')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-businesses/queue-task/level-3') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue Level 2
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_access_bname_data_update_tasks_level4', 'registration')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-businesses/queue-task/level-4') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-businesses/queue-task/level-4')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-businesses/queue-task/level-4') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue Level 3
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_bname_data_update_submissions', 'registration')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-businesses/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-businesses/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-businesses/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_bname_data_update_escalations', 'registration')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-businesses/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-businesses/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-businesses/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{businessNameDataUpdateEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/data-update-businesses') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/data-update-businesses')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-pen-square mt-2" id="bn"></i>
            <b-tooltip target="bn" triggers="hover" placement="right">
              <span>
                Business Name Data Update
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Companies Data Update-->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/data-update-companies')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_companies_data_update', 'incorporation')" @click="select('/home/data-update-companies')">
          <div  class="flex" v-if="canViewMenuItem('can_access_companies_data_update', 'incorporation')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-pen-square"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Company DataUpdate</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/data-update-companies')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_company_data_update_tasks', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-companies/queue-task/level-1') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-companies/queue-task/level-1')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-companies/queue-task/level-1') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue Level 1
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
<!--            <b-row>-->
<!--              <b-col cols="3"></b-col>-->
<!--              <b-col cols="9">-->
<!--                <b-row v-if="canViewMenuItem('can_view_company_data_update_tasks_level2', 'incorporation')">-->
<!--                  <b-col cols="12" :class="(currentRoute === '/home/data-update-companies/queue-task/level-2') ? 'sub-side-menu-active' : 'sub-side-menu'">-->
<!--                    <b-row-->
<!--                      @click="select('/home/data-update-companies/queue-task/level-2')"-->
<!--                      :class="[compareMenuItem(currentRoute, '/home/data-update-companies/queue-task/level-2') ? 'flex bg-header_grey' : 'flex']">-->
<!--                      <b-col class="ml-1 menu-text">-->
<!--                        Task Queue Level 2-->
<!--                      </b-col>-->
<!--                    </b-row>-->
<!--                  </b-col>-->
<!--                </b-row>-->
<!--              </b-col>-->
<!--            </b-row>-->
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_company_data_update_tasks_level3', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-companies/queue-task/level-3') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-companies/queue-task/level-3')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-companies/queue-task/level-3') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue Level 2
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_company_data_update_tasks_level4', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-companies/queue-task/level-4') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-companies/queue-task/level-4')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-companies/queue-task/level-4') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue Level 3
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_company_data_update_submissions', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-companies/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-companies/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-companies/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_all_company_data_update_submissions', 'registration')">
                          <b-col cols="12" :class="(currentRoute === '/home/data-update-companies/re-instatement-applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/data-update-companies/re-instatement-applications')"
                                      :class="[compareMenuItem(currentRoute, '/home/data-update-companies/re-instatement-applications') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Re-instatement Applications
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_all_reinstatement_submissions', 'incorporation')">
                          <b-col cols="12" :class="(currentRoute === '/home/data-update-companies/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/data-update-companies/applications')"
                                      :class="[compareMenuItem(currentRoute, '/home/data-update-companies/applications') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Reinstatements
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_company_data_update_escalations', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-update-companies/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-update-companies/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/data-update-companies/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{incorporationDataUpdateEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/data-update-companies') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/data-update-companies')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-pen-square mt-2" id="ci"></i>
            <b-tooltip target="ci" triggers="hover" placement="right">
              <span>
                Company Data Update
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Data Correction -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/data-correction')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_data_correction', 'incorporation')" @click="select('/home/data-correction')">
          <div  class="flex" v-if="canViewMenuItem('can_access_data_correction', 'incorporation')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-pencil-ruler"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Data Correction</div>
              </div>
            </b-col>
          </div>
        </b-row>



        <b-row v-if="compareMenuItem(currentRoute, '/home/data-correction')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_access_leve1_data_correction', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-correction/task/level1') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-correction/task/level1')"
                      :class="[compareMenuItem(currentRoute, '/home/data-correction/task/level1') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue Level 1
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_access_leve2_data_correction', 'incorporation')">
                          <b-col cols="12" :class="(currentRoute === '/home/data-correction/task/level2') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/data-correction/task/level2')"
                                      :class="[compareMenuItem(currentRoute, '/home/data-correction/task/level') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Task Queue Level 2
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_data_correction_applications', 'incorporation')">
                  <b-col cols="12" :class="(currentRoute === '/home/data-correction/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/data-correction/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/data-correction/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_access_data_correction_escalations', 'data-correction')">
                          <b-col cols="12" :class="(currentRoute === '/home/data-correction/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/data-correction/escalations')"
                                      :class="[compareMenuItem(currentRoute, '/home/data-correction/escalations') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Escalations
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_data_correction_applications', 'incorporation')">
                          <b-col cols="12" :class="(currentRoute === '/home/data-correction/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/data-correction/applications')"
                                      :class="[compareMenuItem(currentRoute, '/home/data-correction/applications') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Applications
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/data-correction') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/data-correction')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-pen-square mt-2" id="ci"></i>
            <b-tooltip target="ci" triggers="hover" placement="right">
              <span>
                Data Correction
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Search -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/record-search')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_searches', 'certifications')" @click="select('/home/record-search')">
          <div  class="flex" v-if="canViewMenuItem('can_access_searches', 'certifications')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-file-signature"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Search Reports</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/record-search')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_searches', 'certifications')">
                  <b-col cols="12" :class="(currentRoute === '/home/record-search/task-queue') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/record-search/task-queue')"
                      :class="[compareMenuItem(currentRoute, '/home/record-search/task-queue') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_search_report_request', 'certifications')">
                  <b-col cols="12" :class="(currentRoute === '/home/record-search/search-report-requests') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/record-search/search-report-requests')"
                      :class="[compareMenuItem(currentRoute, '/home/record-search/search-report-requests') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_searches', 'certifications')">
                  <b-col cols="12" :class="(currentRoute === '/home/record-search/searches') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/record-search/searches')"
                      :class="[compareMenuItem(currentRoute, '/home/record-search/searches') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        All Searches
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/record-search') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/record-search')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-file-signature mt-2" id="rsv"></i>
            <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                Search Reports
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Certifications -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/certifications')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_certification', 'certifications')" @click="select('/home/certifications')">
          <div  class="flex" v-if="canViewMenuItem('can_access_certification', 'certifications')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-certificate"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Certifications</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/certifications')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_certification_tasks', 'certifications')">
                  <b-col cols="12" :class="(currentRoute === '/home/certifications/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/certifications/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/certifications/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Task Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_certification_submissions', 'certifications')">
                  <b-col cols="12" :class="(currentRoute === '/home/certifications/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/certifications/applications')"
                      :class="[compareMenuItem(currentRoute, '/home/certifications/applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_certification_escalations', 'certifications')">
                  <b-col cols="12" :class="(currentRoute === '/home/certifications/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/certifications/escalations')"
                      :class="[compareMenuItem(currentRoute, '/home/certifications/escalations') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Escalations
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{certificationEscalations}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/certifications') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/certifications')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-certificate mt-2" id="rsv"></i>
            <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                Document Certification
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

      <!-- Dispute Resolution -->
      <b-row>
          <b-col cols="12" v-if="full">
              <b-row
                      :class="[(compareMenuItem(currentRoute, '/home/disputes')) ? 'side-menu-item-active' : 'side-menu-item']"
                      v-show="canViewMenuItem('can_access_disputes', 'disputes')" @click="select('/home/disputes')">
                  <div  class="flex" v-if="canViewMenuItem('can_access_certification', 'disputes')" >
                      <b-col cols="1"></b-col>
                      <b-col cols="2">
                          <i class="fa fa-phone"></i>
                      </b-col>
                      <b-col cols="8">
                          <div>
                              <div class="menu-text mt-1">Official Contacts</div>
                          </div>
                      </b-col>
                  </div>
              </b-row>
              <b-row v-if="compareMenuItem(currentRoute, '/home/disputes')">
                  <b-col class="bg-header_grey">
<!--                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_dispute_tasks', 'disputes')">
                                  <b-col cols="12" :class="(currentRoute === '/home/disputes/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/disputes/queue-task')"
                                              :class="[compareMenuItem(currentRoute, '/home/disputes/queue-task') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Task Queue
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>-->

                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_official_account_tasks', 'disputes')">
                                  <b-col cols="12" :class="(currentRoute === '/home/disputes/official-account-queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/disputes/official-account-queue-task')"
                                              :class="[compareMenuItem(currentRoute, '/home/disputes/official-account-queue-task') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Task Queue
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>

                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_all_dispute_submissions', 'disputes')">
                                  <b-col cols="12" :class="(currentRoute === '/home/disputes/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/disputes/applications')"
                                              :class="[compareMenuItem(currentRoute, '/home/disputes/applications') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Applications
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_dispute_escalations', 'disputes')">
                                  <b-col cols="12" :class="(currentRoute === '/home/disputes/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/disputes/escalations')"
                                              :class="[compareMenuItem(currentRoute, '/home/disputes/escalations') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Escalations
                                          </b-col>
                                          <b-col class="float-end">
                                              <b-badge class="bg-blue float-end">
                                                  {{certificationEscalations}}
                                              </b-badge>
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>
          </b-col>
          <b-col cols="12" v-else>
              <b-row
                      :class="[compareMenuItem(currentRoute, '/home/certifications') ? 'side-menu-item-active' : 'side-menu-item']"
                      @click="select('/home/certifications')">
                  <b-col cols="10"  class="mx-auto text-center">
                      <i class="fa fa-certificate mt-2" id="rsv"></i>
                      <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                Document Certification
              </span>
                      </b-tooltip>
                  </b-col>
              </b-row>
          </b-col>
      </b-row>
    <!-- Insolvency -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/insolvency')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_insolvency', 'insolvency')" @click="select('/home/insolvency')">
          <div  class="flex" v-if="canViewMenuItem('can_access_insolvency', 'insolvency')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-window-close"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Insolvency</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/insolvency')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_insolvency_tasks', 'insolvency')">
                  <b-col cols="12" :class="(currentRoute === '/home/insolvency/queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/insolvency/queue-task')"
                      :class="[compareMenuItem(currentRoute, '/home/insolvency/queue-task') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Winding Up Queue
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_receivership_tasks', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/receivership-queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/receivership-queue-task')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/receivership-queue-task') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                     Receivership Queue
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_administration_tasks', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/administration-queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/administration-queue-task')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/administration-queue-task') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                     Administration Queue
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_de_registration_tasks', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/cessation-queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/cessation-queue-task')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/cessation-queue-task') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      De-registration Queue
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_insolvency_petition_task', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/petitions-queue-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/petitions-queue-task')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/petitions-queue-task') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Verification Queue
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>


              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_insolvency_petitions', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/petition-applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/petition-applications')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/petition-applications') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Verification Applications
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_insolvency_submissions', 'insolvency')">
                  <b-col cols="12" :class="(currentRoute === '/home/insolvency/winding-up-applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/insolvency/winding-up-applications')"
                      :class="[compareMenuItem(currentRoute, '/home/insolvency/winding-up-applications') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                       Winding Up Applications
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>




              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_all_receivership_submissions', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/receivership-applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/receivership-applications')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/receivership-applications') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                     Receivership Applications
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_all_de_registration_submissions', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/cessation-applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/cessation-applications')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/cessation-applications') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      De-registration Applications
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_all_administration_submissions', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/administration-applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/administration-applications')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/administration-applications') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Administration Applications
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="!canViewMenuItem('can_view_insolvency_escalations', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/escalations')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/escalations') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Escalations
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>


              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_insolvency_escalations', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/escalations')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/escalations') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                     Escalations
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>

              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_initiate_court_winding_up', 'insolvency')">
                          <b-col cols="12" :class="(currentRoute === '/home/insolvency/court-winding-up') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/insolvency/court-winding-up')"
                                      :class="[compareMenuItem(currentRoute, '/home/insolvency/court-winding-up') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Court Winding Up
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_all_insolvency_practitioner_complaints', 'reservations')">
                  <b-col cols="12" :class="(currentRoute === '/home/insolvency/practitioner-complaints') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/insolvency/practitioner-complaints')"
                      :class="[compareMenuItem(currentRoute, '/home/insolvency/practitioner-complaints') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Practitioner Complaints
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/insolvency') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/insolvency')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-window-close mt-2" id="ins"></i>
            <b-tooltip target="ins" triggers="hover" placement="right">
              <span>
                Insolvency
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>


      <!-- Bankruptcy -->
      <b-row>
          <b-col cols="12" v-if="full">
              <b-row
                      :class="[(compareMenuItem(currentRoute, '/home/bankruptcy')) ? 'side-menu-item-active' : 'side-menu-item']"
                      v-show="canViewMenuItem('can_access_insolvency', 'insolvency')" @click="select('/home/bankruptcy')">
                  <div  class="flex" v-if="canViewMenuItem('can_access_insolvency', 'insolvency')" >
                      <b-col cols="1"></b-col>
                      <b-col cols="2">
                          <i class="fa fa-hand-holding-heart"></i>
                      </b-col>
                      <b-col cols="8">
                          <div>
                              <div class="menu-text mt-1">Bankruptcy</div>
                          </div>
                      </b-col>
                  </div>
              </b-row>
              <b-row v-if="compareMenuItem(currentRoute, '/home/bankruptcy')">
                  <b-col class="bg-header_grey">

                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_all_insolvency_submissions', 'insolvency')">
                                  <b-col cols="12" :class="(currentRoute === '/home/bankruptcy/task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/bankruptcy/task')"
                                              :class="[compareMenuItem(currentRoute, '/home/bankruptcy/task') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Task Queue
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>

                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_all_insolvency_submissions', 'insolvency')">
                                  <b-col cols="12" :class="(currentRoute === '/home/bankruptcy/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/bankruptcy/applications')"
                                              :class="[compareMenuItem(currentRoute, '/home/bankruptcy/applications') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Bankruptcy Applications
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>

                  </b-col>
              </b-row>
          </b-col>
          <b-col cols="12" v-else>
              <b-row
                      :class="[compareMenuItem(currentRoute, '/home/bankruptcy') ? 'side-menu-item-active' : 'side-menu-item']"
                      @click="select('/home/insolvency')">
                  <b-col cols="10"  class="mx-auto text-center">
                      <i class="fa fa-window-close mt-2" id="ins"></i>
                      <b-tooltip target="ins" triggers="hover" placement="right">
              <span>
                Bankruptcy
              </span>
                      </b-tooltip>
                  </b-col>
              </b-row>
          </b-col>
      </b-row>
      <!-- Registry -->

      <!-- Practitioner -->
      <b-row>
          <b-col cols="12" v-if="full">
              <b-row
                      :class="[(compareMenuItem(currentRoute, '/home/practitioners')) ? 'side-menu-item-active' : 'side-menu-item']"
                      v-show="canViewMenuItem('can_access_insolvency', 'insolvency')" @click="select('/home/practitioners')">
                  <div  class="flex" v-if="canViewMenuItem('can_access_insolvency', 'insolvency')" >
                      <b-col cols="1"></b-col>
                      <b-col cols="2">
                          <i class="fa fa-window-close"></i>
                      </b-col>
                      <b-col cols="8">
                          <div>
                              <div class="menu-text mt-1">Insolvency Practitioners</div>
                          </div>
                      </b-col>
                  </div>
              </b-row>
              <b-row v-if="compareMenuItem(currentRoute, '/home/practitioners')">
                  <b-col class="bg-header_grey">
                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_insolvency_practitioner_tasks', 'insolvency')">
                                  <b-col cols="12" :class="(currentRoute === '/home/practitioners/practitioner-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/practitioners/practitioner-task')"
                                              :class="[compareMenuItem(currentRoute, '/home/practitioners/practitioner-task') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Registration Queue
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_insolvency_practitioner_tasks', 'insolvency')">
                                  <b-col cols="12" :class="(currentRoute === '/home/practitioners/practitioner-renewal-task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/practitioners/practitioner-renewal-task')"
                                              :class="[compareMenuItem(currentRoute, '/home/practitioners/practitioner-renewal-task') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                                Update Queue
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>

<!--                      <b-row>-->
<!--                          <b-col cols="3"></b-col>-->
<!--                          <b-col cols="9">-->
<!--                              <b-row v-if="canViewMenuItem('can_view_insolvency_practitioner_tasks', 'insolvency')">-->
<!--                                  <b-col cols="12" :class="(currentRoute === '/home/practitioners/practitioner-renewal-task') ? 'sub-side-menu-active' : 'sub-side-menu'">-->
<!--                                      <b-row-->
<!--                                              @click="select('/home/practitioners/practitioner-renewal-task')"-->
<!--                                              :class="[compareMenuItem(currentRoute, '/home/practitioners/practitioner-renewal-task') ? 'flex bg-header_grey' : 'flex']">-->
<!--                                          <b-col class="ml-1 menu-text">-->
<!--                                              Annual Returns Queue-->
<!--                                          </b-col>-->
<!--                                      </b-row>-->
<!--                                  </b-col>-->
<!--                              </b-row>-->
<!--                          </b-col>-->
<!--                      </b-row>-->

                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_all_insolvency_practitioner_submissions', 'reservations')">
                                  <b-col cols="12" :class="(currentRoute === '/home/practitioners/practitioner-applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/practitioners/practitioner-applications')"
                                              :class="[compareMenuItem(currentRoute, '/home/practitioners/practitioner-applications') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Applications
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>

                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_insolvency_escalations', 'insolvency')">
                                  <b-col cols="12" :class="(currentRoute === '/home/practitioners/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/practitioners/escalations')"
                                              :class="[compareMenuItem(currentRoute, '/home/practitioners/escalations') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Escalations
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>

                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_all_insolvency_practitioner_complaints', 'reservations')">
                                  <b-col cols="12" :class="(currentRoute === '/home/practitioners/practitioner-complaints') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/practitioners/practitioner-complaints')"
                                              :class="[compareMenuItem(currentRoute, '/home/practitioners/practitioner-complaints') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Practitioner Complaints
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>
          </b-col>
          <b-col cols="12" v-else>
              <b-row
                      :class="[compareMenuItem(currentRoute, '/home/practitioners') ? 'side-menu-item-active' : 'side-menu-item']"
                      @click="select('/home/practitioners')">
                  <b-col cols="10"  class="mx-auto text-center">
                      <i class="fa fa-window-close mt-2" id="ins"></i>
                      <b-tooltip target="ins" triggers="hover" placement="right">
              <span>
                Insolvency Practitioners
              </span>
                      </b-tooltip>
                  </b-col>
              </b-row>
          </b-col>
      </b-row>
    <!-- Registry -->

      <!-- Annual returns -->
      <b-row>
          <b-col cols="12" v-if="full">
              <b-row
                      :class="[(compareMenuItem(currentRoute, '/home/insolvency-annual-returns')) ? 'side-menu-item-active' : 'side-menu-item']"
                      v-show="canViewMenuItem('can_access_insolvency', 'insolvency')" @click="select('/home/insolvency-annual-returns')">
                  <div  class="flex" v-if="canViewMenuItem('can_access_insolvency', 'insolvency')" >
                      <b-col cols="1"></b-col>
                      <b-col cols="2">
                          <i class="fa fa-window-close"></i>
                      </b-col>
                      <b-col cols="8">
                          <div>
                              <div class="menu-text mt-1">Practitioner Annual Returns</div>
                          </div>
                      </b-col>
                  </div>
              </b-row>
              <b-row v-if="compareMenuItem(currentRoute, '/home/insolvency-annual-returns')">
                  <b-col class="bg-header_grey">
                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_practitioner_returns_tasks', 'insolvency')">
                                  <b-col cols="12" :class="(currentRoute === '/home/insolvency-annual-returns/task') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/insolvency-annual-returns/task')"
                                              :class="[compareMenuItem(currentRoute, '/home/insolvency-annual-returns/task') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Task Queue
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>


                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_all_practitioner_returns_submissions', 'reservations')">
                                  <b-col cols="12" :class="(currentRoute === '/home/insolvency-annual-returns/applications') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/insolvency-annual-returns/applications')"
                                              :class="[compareMenuItem(currentRoute, '/home/insolvency-annual-returns/applications') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Applications
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>

                      <b-row>
                          <b-col cols="3"></b-col>
                          <b-col cols="9">
                              <b-row v-if="canViewMenuItem('can_view_practitioner_returns_escalations', 'insolvency')">
                                  <b-col cols="12" :class="(currentRoute === '/home/insolvency-annual-returns/escalations') ? 'sub-side-menu-active' : 'sub-side-menu'">
                                      <b-row
                                              @click="select('/home/insolvency-annual-returns/escalations')"
                                              :class="[compareMenuItem(currentRoute, '/home/insolvency-annual-returns/escalations') ? 'flex bg-header_grey' : 'flex']">
                                          <b-col class="ml-1 menu-text">
                                              Escalations
                                          </b-col>
                                      </b-row>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>

                  </b-col>
              </b-row>
          </b-col>
          <b-col cols="12" v-else>
              <b-row
                      :class="[compareMenuItem(currentRoute, '/home/practitioners') ? 'side-menu-item-active' : 'side-menu-item']"
                      @click="select('/home/insolvency')">
                  <b-col cols="10"  class="mx-auto text-center">
                      <i class="fa fa-window-close mt-2" id="ins"></i>
                      <b-tooltip target="ins" triggers="hover" placement="right">
              <span>
                Insolvency Practitioners
              </span>
                      </b-tooltip>
                  </b-col>
              </b-row>
          </b-col>
      </b-row>
      <!-- Registry -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/registry')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_registry', 'registry')" @click="select('/home/registry')">
          <div  class="flex" v-if="canViewMenuItem('can_access_registry', 'registry')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-database"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">URSB Registry</div>
              </div>
            </b-col>
          </div>
        </b-row>
        <b-row v-if="compareMenuItem(currentRoute, '/home/registry')">
          <b-col class="bg-header_grey">
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_business_registry', 'registry')">
                  <b-col cols="12" :class="(currentRoute === '/home/registry/business') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/registry/business')"
                      :class="[compareMenuItem(currentRoute, '/home/registry/business') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Businesses
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{Number(entityBusiness +  entityCompany + entitySociety).toLocaleString()}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_beneficial_owner_registry', 'registry')">
                  <b-col cols="12" :class="(currentRoute === '/home/registry/beneficial-owners') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/registry/beneficial-owners')"
                      :class="[compareMenuItem(currentRoute, '/home/registry/beneficial-owners') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Bnf. Owners
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{Number(beneficialOwnersCo + beneficialOwnersBn).toLocaleString()}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_document_registry', 'registry')">
                  <b-col cols="12" :class="(currentRoute === '/home/registry/documents') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/registry/documents')"
                      :class="[compareMenuItem(currentRoute, '/home/registry/documents') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Documents
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{registryDocuments.toLocaleString()}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_charges_registry', 'registry')">
                  <b-col cols="12" :class="(currentRoute === '/home/registry/charges') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/registry/charges')"
                      :class="[compareMenuItem(currentRoute, '/home/registry/charges') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Charges
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{registryCharges.toLocaleString()}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="3"></b-col>
              <b-col cols="9">
                <b-row v-if="canViewMenuItem('can_view_practitioner_registry', 'registry')">
                  <b-col cols="12" :class="(currentRoute === '/home/registry/practitioners') ? 'sub-side-menu-active' : 'sub-side-menu'">
                    <b-row
                      @click="select('/home/registry/practitioners')"
                      :class="[compareMenuItem(currentRoute, '/home/registry/practitioners') ? 'flex bg-header_grey' : 'flex']">
                      <b-col class="ml-1 menu-text">
                        Practitioners
                      </b-col>
                      <b-col class="float-end">
                        <b-badge class="bg-blue float-end">
                          {{registryPractitioners.toLocaleString()}}
                        </b-badge>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
              <b-row>
                  <b-col cols="3"></b-col>
                  <b-col cols="9">
                      <b-row v-if="canViewMenuItem('can_view_insolvency_registry', 'registry')">
                          <b-col cols="12" :class="(currentRoute === '/home/registry/insolvency') ? 'sub-side-menu-active' : 'sub-side-menu'">
                              <b-row
                                      @click="select('/home/registry/insolvency')"
                                      :class="[compareMenuItem(currentRoute, '/home/registry/insolvency') ? 'flex bg-header_grey' : 'flex']">
                                  <b-col class="ml-1 menu-text">
                                      Insolvency
                                  </b-col>
                                  <b-col class="float-end">
                                      <b-badge class="bg-blue float-end">
                                          {{registryPractitioners.toLocaleString()}}
                                      </b-badge>
                                  </b-col>
                              </b-row>
                          </b-col>
                      </b-row>
                  </b-col>
              </b-row>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/registry') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/registry')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-database mt-2" id="rsv"></i>
            <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                URSB Registry
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Tracking -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/tracking')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_tracking', 'reports')" @click="select('/home/tracking')">
          <div  class="flex" v-if="canViewMenuItem('can_access_tracking', 'reports')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-stream"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Tracking</div>
              </div>
            </b-col>
          </div>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/tracking') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/tracking')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-stream mt-2" id="rsv"></i>
            <b-tooltip target="rsv" triggers="hover" placement="right">
              <span>
                Application Tracking
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- Reports -->
    <b-row>
      <b-col cols="12" v-if="full">
        <b-row
          :class="[(compareMenuItem(currentRoute, '/home/reports')) ? 'side-menu-item-active' : 'side-menu-item']"
          v-show="canViewMenuItem('can_access_reports', 'reports')" @click="select('/home/reports')">
          <div  class="flex" v-if="canViewMenuItem('can_access_reports', 'reports')" >
            <b-col cols="1"></b-col>
            <b-col cols="2">
              <i class="fa fa-chart-line"></i>
            </b-col>
            <b-col cols="8">
              <div>
                <div class="menu-text mt-1">Reports</div>
              </div>
            </b-col>
          </div>
        </b-row>
      </b-col>
      <b-col cols="12" v-else>
        <b-row
          :class="[compareMenuItem(currentRoute, '/home/reports') ? 'side-menu-item-active' : 'side-menu-item']"
          @click="select('/home/reports')">
          <b-col cols="10"  class="mx-auto text-center">
            <i class="fa fa-chart-line mt-2" id="rpt"></i>
            <b-tooltip target="rpt" triggers="hover" placement="right">
              <span>
                Reports
              </span>
            </b-tooltip>
          </b-col>
        </b-row>
      </b-col>
    </b-row>

  </b-col>
</template>

<script>
import router from "@/router";
import jwtService from "@/app/services/jwt.service";
import {mapActions, mapGetters} from "vuex";

export default {
  name: "SideMenu",
  props:{
    full:Boolean
  },
  data(){
    return{
      currentRoute: this.$route.fullPath,
      userProfile:null,
      currentPage:1,
      limit:12,
      search:"",
      type:"",
      reservationAppeals:0,
      reservationExtensions:0,
      reservationEscalations:0,
      businessNameEscalations:0,
      incorporationEscalations:0,
      businessNameDataUpdateEscalations:0,
      incorporationDataUpdateEscalations:0,
      dispensationEscalations:0,
      continuousFilingEscalations:0,
      annualReturnsEscalations:0,
      amendmentEscalations:0,
      societyEscalations:0,
      legalDocumentEscalations:0,
      registryDocuments:0,
      registryPractitioners:0,
      registryCharges:0,
      entityBusiness:0,
      entityCompany:0,
      entitySociety:0,
      certificationEscalations:0,
      searchReportRequests:0,
      beneficialOwnersCo:0,
      beneficialOwnersBn:0,
    }
  },
  mounted() {
    this.currentRoute = this.$route.fullPath
    this.userProfile = jwtService.getUser()
   /* this.fetchAppeals()
    this.fetchExtensions()
    this.pullReservationEscalations()
    this.pullBusinessNameEscalations()
    // this.pullBSIncorporationEscalations()
    this.pullIncorporationEscalations()
    this.pullDispensationEscalations()
    this.pullLegalDocumentEscalations()
    this.pullEntityRegistry()
    this.pullDocumentRegistry()
    this.pullChargesRegistry()
    this.pullBORegistry()
      this.pullInsolvencyRegistry()*/

  },

  methods:{
    ...mapActions(["fetchReservationAppeals", "fetchReservationExtensions", "fetchReservationEscalations",
      "fetchBusinessNameEscalations", "fetchBSEscalations", "fetchCompanyEscalations", "fetchDispensationEscalations",
      "fetchLegalDocumentEscalations", "fetchLegalDocumentsRegistry", "fetchBuildingSocietyRegistry", "fetchCompanyRegistry",
      "fetchBusinessNameRegistry", "fetchChargeRegistry", "fetchCompanyBeneficialOwnerRegistry","fetchPractitionerRegistry"]),
    select(route){
      if(this.currentRoute !== route){
        this.currentRoute = route
        router.push(route)
      }
    },
    fetchAppeals(){
      let data = {
        page:this.currentPage,
        limit:this.limit,
      }
      this.fetchReservationAppeals(data)
    },
    fetchExtensions(){
      let data = {
        statuses:"Pending",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchReservationExtensions(data)
    },
    pullReservationEscalations(){
      let data = {
        status:"Pending",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchReservationEscalations(data)
    },
    pullBusinessNameEscalations(){
      let user = jwtService.getUser()
      let data = {
        status:"Pending",
        userId: user.id,
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchBusinessNameEscalations(data)
    },
    pullIncorporationEscalations(){
      let data = {
        status:"Pending",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchCompanyEscalations(data)
    },
    pullDispensationEscalations(){
      let data = {
        status:"Pending",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchDispensationEscalations(data)
    },
    pullBSIncorporationEscalations(){
      let data = {
        status:"Pending",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchBSEscalations(data)
    },
    pullLegalDocumentEscalations(){
      let data = {
        status:"PENDING",
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchLegalDocumentEscalations(data)
    },
    pullEntityRegistry(){
      let data = {
        search:this.search,
        page:this.currentPage,
        limit:this.limit,
        offset:this.limit
      }
      //this.fetchBuildingSocietyRegistry(data)
      this.fetchCompanyRegistry(data)
      this.fetchBusinessNameRegistry(data)
    },
    pullDocumentRegistry(){
      let data = {
        search:this.search,
        page:this.currentPage,
        limit:this.limit,
        type:this.type
      }
      this.loading = true
      this.fetchLegalDocumentsRegistry(data)
    },
    pullChargesRegistry(){
      let data = {
        search:this.search,
        page:this.currentPage,
        limit:this.limit,
      }
      this.loading = true
      this.fetchChargeRegistry(data)
    },
    pullBORegistry(){
      let data = {
        q:"",
        page:this.currentPage,
        limit:this.limit,
        offset:this.limit
      }
      this.loading = true
      this.fetchCompanyBeneficialOwnerRegistry(data)
    },
      pullInsolvencyRegistry(){
          let data = {
              search:this.search,
              page:this.currentPage,
              limit:this.limit,
          }
          this.loading = true
          this.fetchPractitionerRegistry(data)
      },
    compareMenuItem(currentRoute, selectedRoute){
      if(currentRoute.includes(selectedRoute)){
        return true
      }
      return false
    },
    goHome(){
      router.push("/dashboard")
      window.location.reload()
    },
    logout(){
      sessionStorage.clear()
      let r = this.$router.resolve({
        name: "login",
      });
      window.location.assign(r.href);
    },
    canViewMenuItem(permission,group){
      return this.$can(permission,group) === true
    }
  },
  computed:{
    ...mapGetters([ "getReservationAppealsMenu", "getReservationExtensionsMenu", "getReservationEscalationsMenu",
      "getRegistrationEscalations", "getIncorporationEscalations", "getBSIncorporationEscalations", "getLegalDocumentEscalations",
      "getBusinessNameRegistry", "getCompanyRegistry", "getBuildingSocietyRegistry", "getLegalDocumentRegistry",
      "getDispensationEscalations", "getChargeRegistry", "getBeneficialOwnersCompanyRegistry","getPractitionerRegistry"]),
  },
  watch:{
    getReservationAppealsMenu(data){
      this.reservationAppeals = data.totalElements
    },
    getReservationExtensionsMenu(data){
      this.reservationExtensions = data.totalElements
    },
    getReservationEscalationsMenu(data){
      this.reservationEscalations = data.totalElements
    },
    getRegistrationEscalations(data){
      this.businessNameEscalations = data.totalElements
    },
    getIncorporationEscalations(data){
      this.incorporationEscalations = data.totalElements
    },
    getDispensationEscalations(data){
      this.dispensationEscalations = data.totalElements
    },
    getBSIncorporationEscalations(data){
      this.societyEscalations = data.totalElements
    },
    getLegalDocumentEscalations(data){
      this.legalDocumentEscalations = data.totalElements
    },
    getBusinessNameRegistry(data){
      this.entityBusiness = data.totalElements
    },
    getCompanyRegistry(data){
      this.entityCompany = data.totalElements
    },
    getBeneficialOwnersCompanyRegistry(data){
      this.beneficialOwnersCo = data.totalElements
    },
    getBuildingSocietyRegistry(data){
      this.entitySociety = data.totalElements
    },
    getLegalDocumentRegistry(data){
      this.registryDocuments = data.totalElements
    },
    getChargeRegistry(data){
      this.registryCharges = data.totalElements
    },
      getPractitionerRegistry(data) {
          this.registryPractitioners = data.totalElements
      },
  }
}
</script>

<style scoped>
@import "/../src/assets/styles/menu.css";
</style>