<template>
  <div class="mb-10">

    <b-row class="mx-auto mt-1">
    <b-col cols="6">
      <AddressComponent
          :villageDataInfo="physicalAddress">
      </AddressComponent>

      <b-form-group
          class="text-xs my-1"
          id="input-group-1"
          label="Postal Address"
          label-for="input-1">
        <b-form-input
            id="companyName"
            class="form-text text-xs noFocus text-capitalize"
            type="text"
            :disabled="true"
            :value="form.address.postalAddress"
            required>
        </b-form-input>
      </b-form-group>


    </b-col>

      <b-col cols="6">

        <b-row v-if="showPlotAndStreet">
          <b-col cols="6">

            <b-form-group
                class="text-xs my-1"
                id="input-group-1"
                label="Plot Number"
                label-for="input-1">
              <b-form-input
                  id="companyName"
                  class="form-text text-xs noFocus text-capitalize"
                  type="text"
                  :disabled="true"
                  v-model="form.address.plot"
                  required>
              </b-form-input>
            </b-form-group>
          </b-col>
          <b-col cols="6">

            <b-form-group
                class="text-xs my-1"
                id="input-group-1"
                label="street"
                label-for="input-1">
              <b-form-input
                  id="companyName"
                  class="form-text text-xs noFocus text-capitalize"
                  type="text"
                  :disabled="true"
                  v-model="form.address.street"
                  required>
              </b-form-input>
            </b-form-group>
          </b-col>
        </b-row>


          <label class="text-gray text-xs">Office of this Company is Situated at (More Description)</label>
          <b-form-group
              id="input-group-1"
              label-for="description">
            <b-form-textarea
                id="description"
                class="form-text noFocus text-xs"
                type="text"
                disabled
                v-model="form.address.description"
                rows="6"
                required>
            </b-form-textarea>
          </b-form-group>

<!--        </b-card>-->
      </b-col>
    </b-row>




  </div>
</template>

<script>
import AddressComponent from "@/views/home/services/continuous-filing/task/components/AddressComponent.vue";

export default {
  name: "AmalgamationOfficeAndRecords",
  components: {AddressComponent},
  props:{
    addressInfo:{},
  },
  data(){
    return{
      physicalAddress:{},
      loading:false,
      showPlotAndStreet:true,
      companyName:true,
      form:{
        address: {
          plot:null,
          street:null,
          postalAddress:null,
          description:null,
        },
        villageId:null,
      },
      villageList:[],
      draftAddressData:{},
      loaderPostalProgress:false,
      postalAddressStatusMsg:"",
      addressColor:null,
      boxNumber:null,
      boxStation:"",
      stationList:null,
      showPostaDialog:false,
      entityIndividualData:{},
    }
  },
  mounted() {
    this.filterDraft()
  },
  methods:{
    filterDraft(){
      let draft = this.addressInfo
      if(draft){
        this.form.address.plot = draft.plot
        this.form.address.street = draft.street
        this.form.address.description = draft.description

        if(draft.address && draft.address.village){
          this.form.villageId = draft.address.village.id
        }

        this.form.address.postalAddress = draft.postalAddress

        if(draft.postalAddress){
          let boxNumber = draft.postalAddress.split(" ");
          this.boxNumber = boxNumber[0]
          this.boxStation =  String(draft.postalAddress).replace(boxNumber[0]+" ", "")
        }



        this.physicalAddress = draft.address ? draft.address : {}
        // this.villageList = [{value: draft.villageId, text: draft.address.physicalAddress.village.name}]
      }

    },
  },
  watch:{
    addressInfo(){
      this.filterDraft()
    }
  }
}
</script>

<style scoped>


.button{
  @apply bg-blue px-2 py-1 rounded-sm text-white;
}

.button {
  @apply bg-blue text-white py-2 rounded;
}

.button:hover {
  @apply bg-blue-dark;
}


.back-button {
  @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
  @apply bg-gray text-white;
}


</style>