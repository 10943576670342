import { render, staticRenderFns } from "./BuildingScoietyManagement.vue?vue&type=template&id=4ccfc300&scoped=true&"
import script from "./BuildingScoietyManagement.vue?vue&type=script&lang=js&"
export * from "./BuildingScoietyManagement.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4ccfc300",
  null
  
)

export default component.exports