<template>
  <b-col>
    <b-row>
        <b-col cols="12" class="mx-auto">
          <b-row class="">
            <b-col>
              <b-form-group
                class="text-xs my-1"
                id="input-group-1"
                label="Country"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-xs noFocus"
                  type="text"
                  :disabled="true"
                  :value="address.country.name"
                  required>
                </b-form-input>
              </b-form-group>
             <b-form-group
                class="text-xs my-1"
                id="input-group-1"
                label="Region"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-xs noFocus"
                  type="text"
                  :disabled="true"
                  :value="address.region.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-xs my-1"
                id="input-group-1"
                label="District"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-xs noFocus text-capitalize"
                  type="text"
                  :disabled="true"
                  :value="address.district.name"
                  required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                  class="text-xs my-1"
                  id="input-group-1"
                  label="Sub County"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-xs noFocus text-capitalize"
                    type="text"
                    :disabled="true"
                    :value="address.subcounty.name"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-xs my-1"
                id="input-group-1"
                label="Parish"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-xs noFocus"
                  type="text"
                  :disabled="true"
                  :value="address.parish.name"
                  required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                class="text-xs my-1"
                id="input-group-1"
                label="Village"
                label-for="input-1">
                <b-form-input
                  id="companyName"
                  class="form-text text-xs noFocus text-capitalize"
                  type="text"
                  :disabled="true"
                  :value="address.village.name"
                  required>
                </b-form-input>
              </b-form-group>
              <!--           <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Plot"
                          label-for="input-1">
                          <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus"
                            type="text"
                            :disabled="true"
                            :value="villageDataInfo.address.plot"
                            required>
                          </b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Street"
                          label-for="input-1">
                          <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            :disabled="true"
                            :value="villageDataInfo.address.street"
                            required>
                          </b-form-input>
                        </b-form-group>
                      </b-col>
                      <b-col>
                        <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Postal Address"
                          label-for="input-1">
                          <b-form-input
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            :disabled="true"
                            :value="villageDataInfo.address.postalAddress"
                            required>
                          </b-form-input>
                        </b-form-group>
                        <b-form-group
                          class="text-sm my-1"
                          id="input-group-1"
                          label="Description"
                          label-for="input-1">
                          <b-form-textarea
                            id="companyName"
                            class="form-text text-sm noFocus text-capitalize"
                            type="text"
                            rows="4"
                            :disabled="true"
                            :value="villageDataInfo.address.description"
                            required>
                          </b-form-textarea>
                        </b-form-group>-->
            </b-col>
          </b-row>
        </b-col>

    </b-row>

  </b-col>
</template>

<script>
export default {
  name: "AddressComponent",
  props:{
    villageDataInfo:{},
  },
  data(){
    return{
      address:null
    }
  },
  mounted(){
    this.filterAddress()
  },
  methods:{
    filterAddress(){
      let draft = this.villageDataInfo
      if(draft){
        this.address = draft
      }
    }
  },
  computed:{

  },
  watch:{
    villageDataInfo(data){
     if(data){
       this.filterAddress()
     }
    },

  }
}
</script>

<style scoped>

</style>