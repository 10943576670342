<template>
  <b-row>
    <b-col cols="12">
      <b-row class="mx-1 py-1 border-b-2 border-grey" v-for="(person, idx) in persons" :key="idx">
        <b-col cols="12">
          <b-row class="py-3">
            <b-col cols="3" class="py-1">
              <span class="font-bold text-capitalize">{{person.givenName}} {{person.surName}}</span>
            </b-col>
            <b-col cols="2" class="py-1">
              <span>{{person.idNumber}}</span>
            </b-col>
            <b-col cols="5"></b-col>
            <b-col cols="2">
              <button class="float-end mx-auto bg-blue px-2 py-1 text-sm rounded-sm text-white" @click="showDetails(idx)">
                <p v-if="selectedId === idx">
                  Show Less
                </p>
                <p v-else>
                  View Details
                </p>
              </button>
            </b-col>
          </b-row>
          <b-row v-if="selectedId === idx">
            <b-col>
              <b-form-group
                v-if="person.givenName"
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Given Name"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="person.givenName"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  v-if="person.middleName"
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Middle Name"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="person.middleName"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Surname"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="person.surName"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="ID Type"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    disabled="true"
                    :value="person.idType.toLowerCase()"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="ID Number"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="person.idNumber"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Nationality"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="person.nationality"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Email Address"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="person.email"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Phone Number"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="person.phoneNumber"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  v-if="person.sex"
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Gender (at birth)"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus text-capitalize"
                    type="text"
                    disabled="true"
                    :value="person.sex.toLowerCase()"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Date of Birth"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="dateFormat(person.dateOfBirth)"
                    required>
                </b-form-input>
              </b-form-group>
              <b-form-group
                  class="text-sm my-1"
                  id="input-group-1"
                  label="Occupation"
                  label-for="input-1">
                <b-form-input
                    id="companyName"
                    class="form-text text-sm noFocus"
                    type="text"
                    disabled="true"
                    :value="person.occupation"
                    required>
                </b-form-input>
              </b-form-group>
            </b-col>
            <b-col>
              <b-row>
                <b-col v-if="person.physicalAddress">
                  <b-form-group
                      v-if="person.physicalAddress.region"
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Region"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="person.physicalAddress.region.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      v-if="person.physicalAddress.district"
                      class="text-sm my-1"
                      id="input-group-1"
                      label="District"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="person.physicalAddress.district.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      v-if="person.physicalAddress.subcounty"
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Sub county"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="person.physicalAddress.subcounty.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      v-if="person.physicalAddress.parish"
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Parish"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="person.physicalAddress.parish.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                  <b-form-group
                      v-if="person.physicalAddress.village"
                      class="text-sm my-1"
                      id="input-group-1"
                      label="Village"
                      label-for="input-1">
                    <b-form-input
                        class="form-text text-sm noFocus"
                        type="text"
                        disabled="true"
                        :value="person.physicalAddress.village.name"
                        required>
                    </b-form-input>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import moment from "moment";

export default {
  name: "BSIMembers",
  props:{
    persons:[]
  },
  data(){
    return{
      selectedId:null
    }
  },
  methods:{
    dateFormat(date){
      return moment(date).format("DD/MM/YYYY")
    },
    cleanEntityType(name){
      let n = name.replaceAll("-", " ")
      return n.charAt(0).toUpperCase() + n.slice(1)
    },
    showDetails(id){
      if(this.selectedId != null){
        this.selectedId = null
      }else{
        this.selectedId = id
      }
    },
  }
}
</script>

<style scoped>

</style>