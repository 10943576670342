import {api} from "@/app/services/api.service";

const state = {
    bsi_filings: null,
    bsi_escalations: [],
    bsi_filingstatuses: [],
    bsi_filing_task: {},
    bsi_filing_task_error: null,
    bsi_filing_details: null,
    bsi_filing_stats: {},
    bsi_filing_count: {},
    bsi_success: {},
    bsi_successAssignment: {},
    bsi_successEscalation: {},
    bsi_errorAssignment: {},
    bsi_successStatusUpdate: {},
    bsi_error: {},
};

const getters = {
    getBsiContinuousFilingSubmissions: (state) => state.bsi_filings,
    getBsiContinuousFilingEscalations: (state) => state.bsi_escalations,
    getBsiContinuousFilingTask: (state) => state.bsi_filing_task,
    getBsiContinuousFilingTaskError: (state) => state.bsi_filing_task_error,
    getBsiContinuousFilingDetails: (state) => state.bsi_filing_details,
    getBsiContinuousFilingStatistics: (state) => state.bsi_filing_stats,
    getBsiContinuousFilingStatuses: (state) => state.bsi_filingstatuses,
    getBsiContinuousFilingStatusUpdate: (state) => state.bsi_successStatusUpdate,
    getBsiContinuousFilingCount: (state) => state.bsi_filing_count,
    getBsiContinuousFilingSuccess: (state) => state.bsi_success,
    getBsiContinuousFilingEscalationSuccess: (state) => state.bsi_successEscalation,
    getBsiContinuousFilingAssignmentSuccess: (state) => state.bsi_successAssignment,
    getBsiContinuousFilingAssignmentError: (state) => state.bsi_errorAssignment,
    getBsiContinuousFilingError: (state) => state.bsi_error,
    getBsiContinuousFilingEscalationError: (state) => state.bsi_error,
};

const actions = {
    async submitBsiContinuousFilingApplicationPayment({commit}, data) {
        await api().post("/building-societies/continuous-filing/add-payment", data)
            .then((response) => {
                commit("setBsiContinuousFiling", response.data);
            })
            .catch((error) => {
                commit("setBsiContinuousFilingTaskError", error.response);
            });
    },
    async fetchBsiContinuousFilingSubmissions({commit}, data) {
        let path = "/building-societies/continuous-filing?status=" + data.statuses + "&page=" + data.page + "&pageSize=" + data.limit
        if (data.search !== "") {
            path += "&search=" + data.search
        }
        api().get(path).then(response => {
            commit('setBsiContinuousFilings', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
    async fetchBsiContinuousFilingEscalations({commit}, data) {
        api().get("/building-societies/continuous-filing/queue/my-escalations?status=" + data.status + "&page=" + data.page + "&perPage=" + data.limit).then(response => {
            commit('setBsiCFEscalations', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingEscalationError", error.response)
        });
    },
    async fetchBsiContinuousFilingStatuses({commit}, id) {
        api().get("/building-societies/continuous-filing/queue/task/" + id + "/thread").then(response => {
            commit('setBsiContinuousFilingStatuses', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
    async fetchNextBsiContinuousFiling({commit},taskType) {
        commit('setContinuousFilingToNull');
        api().get("/building-societies/continuous-filing/queue/task?type="+taskType).then(response => {
            commit('setBsiContinuousFiling', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
    async fetchBsiContinuousFilingDetails({commit}, data) {
        api().get("/building-societies/"+data.nature+"/" + data.trackingNumber).then(response => {
            commit('setBsiContinuousFilingDetails', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
    async fetchBsiContinuousFilingStats({commit},applicationType) {
        api().get("/building-societies/continuous-filing/basic-statistics?type="+applicationType).then(response => {
            commit('setBsiContinuousFilingStats', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
    async fetchBsiContinuousFilingCount({commit}) {
        api().get("/building-societies/continuous-filing/queue/count").then(response => {
            commit('setBsiContinuousFilingCount', response);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
    updateBsiContinuousFilingStatus({commit}, data) {
        api().post("/building-societies/continuous-filing/queue/update-status", data).then(response => {
            commit('setBsiContinuousFilingStatusUpdated', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingAssignmentError", error.response)
        });
    },
    assignBsiContinuousFilingTask({commit}, data) {
        api().post("/building-societies/continuous-filing/queue/re-assign", data).then(response => {
            commit('setBsiContinuousFilingAssignmentAction', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
    assignBsiEscalationContinuousFilingTask({commit}, data) {
        api().post("/building-societies/continuous-filing/escalation/queue/re-assign", data).then(response => {
            commit('setBsiContinuousFilingAssignmentAction', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
    escalateBsiContinuousFilingTask({commit}, data) {
        api().post("/building-societies/continuous-filing/queue/escalate", data).then(response => {
            commit('setBsiContinuousFilingEscalationAction', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
    reEscalateBsiContinuousFilingTask({commit}, data) {
        api().post("/building-societies/continuous-filing/queue/escalate", data).then(response => {
            commit('setBsiContinuousFilingEscalationAction', response.data);
        }).catch(error => {
            commit("setBsiContinuousFilingError", error.response)
        });
    },
};

const mutations = {
    setBsiContinuousFilings: (state, response) => {
        state.bsi_filings = response;
    },
    setBsiCFEscalations: (state, response) => {
        state.bsi_escalations = response;
    },
    setBsiContinuousFiling: (state, response) => {
        state.bsi_filing_task = response;
    },
    setBsiContinuousFilingTaskError: (state, error) => {
        state.bsi_filing_task_error = error;
    },
    setBsiContinuousFilingDetails: (state, response) => {
        state.bsi_filing_details = response;
    },
    setBsiContinuousFilingToNull: (state) => {
        state.bsi_filing = null;
    },
    setBsiContinuousFilingStatuses: (state, response) => {
        state.bsi_filingstatuses = response.data;
    },
    setBsiContinuousFilingStats: (state, response) => {
        state.bsi_filing_stats = response;
    },
    setBsiContinuousFilingCount: (state, response) => {
        state.bsi_filing_count = response.data;
    },
    setBsiContinuousFilingAction: (state, response) => {
        state.bsi_success = response;
    },
    setBsiContinuousFilingAssignmentAction: (state, response) => {
        state.bsi_successAssignment = response;
    },
    setBsiContinuousFilingStatusUpdated: (state, response) => {
        state.bsi_successStatusUpdate = response;
    },
    setBsiContinuousFilingEscalationAction: (state, response) => {
        state.bsi_successEscalation = response;
    },
    setBsiContinuousFilingError: (state, error) => {
        state.bsi_error = error;
    },
    setBsiContinuousFilingEscalationError: (state, error) => {
        state.bsi_error = error;
    },
    setBsiContinuousFilingAssignmentError: (state, error) => {
        state.bsi_errorAssignment = error;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};