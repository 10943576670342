<script>
import BuildingSocietyMembers
    from "@/views/home/services/registry/business/files/tabs/building_society_data/BuildingSocietyMembers.vue";
import LegalPersons from "@/views/home/commons/LegalPersons.vue";

export default {
    name: "BuildingSocietyManagement",
    props: {
        management: Object
    },
    components: {LegalPersons, BuildingSocietyMembers}
}
</script>

<template>
    <div>

        <b-row v-if="management"
        >
            <label class="w-1/3">Secretary</label>
            <LegalPersons class="p-1" :persons="[{individual:management.secretary,entityType:'INDIVIDUAL'}]"/>
        </b-row>

        <BuildingSocietyMembers v-if="management.managementType==='MANAGEMENT_COMMITTEE'" nature="Committee"
                                :members="management.members"/>
        <BuildingSocietyMembers v-else nature="Directors" :members="management.members"/>

    </div>
</template>

<style scoped>

</style>