import {api} from "@/app/services/api.service";

const state = {
    subscribers:null,
    shareholding:null,
    partners:null,
    directors:null,
    representatives:null,
    secretary:null,
    shares_classification:null,
    filing_compliance: null,
    shares_allotments:null,
    filing_history:null,
    bn_filing_history:null,
    send_document:null,
    send_document_error:null,
    annual_returns_status:null,
    bsi_members:null,
    bsi_documents:null,
    business_details:null,
    success:null,
    error:null,
    address:null
};

const getters = {
    getFilingCompliance: (state) => state.filing_compliance,
    getCompanySubscribers: (state) => state.subscribers,
    getCompanyRepresentatives: (state) => state.representatives,
    getCompanyShareholding: (state) => state.shareholding,
    getBusinessNameDetails: (state) => state.business_details,
    getBusinessPartners: (state) => state.partners,
    getCompanyDirectors: (state) => state.directors,
    getBuildingSocietyMembers: (state) => state.bsi_members,
    getBuildingSocietyDocuments: (state) => state.bsi_documents,
    getCompanySecretary: (state) => state.secretary,
    getCompanyAddress: (state) => state.address,
    getCompanyShareClassification: (state) => state.shares_classification,
    getCompanyFilingHistory: (state) => state.filing_history,
    getCompanyAnnualReturnsStatus: (state) => state.annual_returns_status,
    getBusinessNameFilingHistory: (state) => state.bn_filing_history,
    getSendDocumentSuccess: (state) => state.send_document,
    getSendDocumentError: (state) => state.send_document_error,
    getBusinessDetailError: (state) => state.error,
};

const actions = {
    async fetchCompanySubscribers({commit}, brn) {
        await api()
            .get("/incorporation/companies/continuous-filing/get-subscribers/"+brn)
            .then(response => {
                commit("setSubscribers", response.data);
            })
            .catch(error => {
                commit("setBusinessFileError", error.response);
            });
    },
    async fetchCompanyDirectors({commit}, brn) {
        await api()
            .get("/incorporation/companies/continuous-filing/get-directors/"+brn)
            .then(response => {
                commit("setDirectors", response.data);
            })
            .catch(error => {
                commit("setBusinessFileError", error.response);
            });
    },
    async fetchCompanyRepresentatives({commit}, brn) {
        await api()
          .get("/incorporation/companies/continuous-filing/get-representatives/"+brn)
          .then(response => {
              commit("setRepresentatives", response.data);
          })
          .catch(error => {
              commit("setBusinessFileError", error.response);
          });
    },
    async fetchCompanySecretary({commit}, brn) {
        await api()
            .get("/incorporation/companies/continuous-filing/get-secretary/"+brn)
            .then(response => {
                commit("setSecretary", response.data);
            })
            .catch(error => {
                commit("setBusinessFileError", error.response);
            });
    },
    async fetchCompanyShareClassification({commit}, brn) {
        await api()
            .get("/incorporation/companies/continuous-filing/get-share-classification/"+brn)
            .then(response => {
                commit("setShareClassification", response.data);
            })
            .catch(error => {
                commit("setBusinessFileError", error.response);
            });
    },
    async fetchCompanyShareholding({commit}, brn) {
        await api()
          .get("/incorporation/companies/continuous-filing/get-shareholdings/"+brn)
          .then(response => {
              commit("setShareholding", response.data);
          })
          .catch(error => {
              commit("setBusinessFileError", error.response);
          });
    },
    async fetchBusinessNameDetailsByBRN({commit}, brn) {
        await api()
            .get("/business-registration/check/"+brn)
            .then(response => {
                commit("setBusinessNameDetails", response.data);
            })
            .catch(error => {
                commit("setBusinessFileError", error.response);
            });
    },
    async fetchBusinessNamePartners({commit}, brn) {
        await api()
            .get("/business-registration/details/partners?brn="+brn)
            .then(response => {
                commit("setPartners", response.data);
            })
            .catch(error => {
                commit("setBusinessFileError", error.response);
            });
    },
    async fetchCompanyAddress({commit}, brn) {
        await api()
          .get("/incorporation/companies/continuous-filing/get-address/"+brn)
          .then(response => {
              commit("setAddressInfo", response.data);
          })
          .catch(error => {
              commit("setBusinessFileError", error.response);
          });
    },
    async fetchCompanyFilingHistory({commit}, brn) {
        await api()
          .get("/incorporation/companies/continuous-filing/filing-history/"+brn+"/narrations")
          .then(response => {
              commit("setFilingHistory", response.data);
          })
          .catch(error => {
              commit("setBusinessFileError", error.response);
          });
    },
    async fetchRegistryCompanyAnnualReturns({commit}, brn) {
        await api()
          .get("/incorporation/annual-returns/"+brn)
          .then(response => {
              commit("setAnnualReturnsStatus", response.data);
          })
          .catch(error => {
              commit("setBusinessFileError", error.response);
          });
    },
    async fetchBuildingSocietyRegisteredDocuments({commit}, brn) {
        await api()
            .get("building-societies/societies/registry/get-registered-documents/"+brn)
            .then(response => {
                commit("setBuildingSocietyDocuments", response.data);
            })
            .catch(error => {
                commit("setBusinessFileError", error.response);
            });
    },
    async fetchBusinessNameFilingHistory({commit}, brn) {
        await api()
          .get("/business-registration/filing/history/"+brn)
          .then(response => {
              commit("setBNFilingHistory", response.data);
          })
          .catch(error => {
              commit("setBusinessFileError", error.response);
          });
    },
    async fetchFilingCompliance({ commit }, brn) {
        await api()
          .get("/incorporation/companies/continuous-filing/get-compliance/" + brn)
          .then(response => {
              commit("setFilingCompliance", response.data);
          })
          .catch(error => {
              commit("setFilingError", error.response);
          });
    },
    async sendCompanyDocumentToClient({ commit }, data) {
        await api()
          .post("/incorporation/companies/continuous-filing/resend-client-document", data)
          .then(response => {
              commit("setSendDocument", response.data);
          })
          .catch(error => {
              commit("setSendDocumentError", error.response);
          });
    },
    async sendBusinessNameDocumentToClient({ commit }, data) {
        await api()
          .post("/business-registration/resend-document-copy", data)
          .then(response => {
              commit("setSendDocument", response.data);
          })
          .catch(error => {
              commit("setSendDocumentError", error.response);
          });
    },
    async sendBusinessNameCertificateToClient({ commit }, data) {
        await api()
          .post("/business-registration/resend-certificate", data)
          .then(response => {
              commit("setSendDocument", response.data);
          })
          .catch(error => {
              commit("setSendDocumentError", error.response);
          });
    },
};

const mutations = {
    setSubscribers: (state, response) => {
        state.subscribers = response;
    },
    setPartners: (state, response) => {
        state.partners = response;
    },
    setDirectors: (state, response) => {
        state.directors = response;
    },
    setRepresentatives: (state, response) => {
        state.representatives = response;
    },
    setFilingCompliance: (state, response) => {
        state.filing_compliance = response;
    },
    setSendDocument: (state, response) => {
        state.send_document = response;
    },
    setSendDocumentError: (state, response) => {
        state.send_document_error = response;
    },
    setShareholding: (state, response) => {
        state.shareholding = response;
    },
    setSecretary: (state, response) => {
        state.secretary = response;
    },
    setShareClassification: (state, response) => {
        state.shares_classification = response;
    },
    setFilingHistory: (state, response) => {
        state.filing_history = response;
    },
    setAnnualReturnsStatus: (state, response) => {
        state.annual_returns_status = response;
    },
    setBuildingSocietyMembers: (state, response) => {
        state.bsi_members = response;
    },
    setBuildingSocietyDocuments: (state, response) => {
        state.bsi_documents = response;
    },
    setBNFilingHistory: (state, response) => {
        state.bn_filing_history = response;
    },
    setBusinessFileError: (state, response) => {
        state.error = response.data;
    },
    setAddressInfo: (state, response) => {
        state.address = response;
    },
    setBusinessNameDetails: (state, response) => {
        state.business_details = response;
    },
};

export default {
    state,
    getters,
    actions,
    mutations,
};
