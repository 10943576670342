<template>
  <div class="mb-2">
    <form @submit.prevent="onSubmit">
<!--      <b-row>
        <b-col>
          <span class="bg-blue px-2 py-1 rounded text-white text-sm">#AmalgamationCompanies</span>
        </b-col>
      </b-row>-->
      <b-row>
        <b-col cols="12" lg="6" class="ml-3">
          <form>

            <div class="mb-3">
              <label class="text-gray text-xs">Amalgamation Entity/Company</label>
              <b-form-group>
                <b-form-input
                    maxlength="14"
                    @keypress="onlyNumberValidator($event)"
                    style="color: #505050;"
                    id="companyName"
                    disabled
                    class="form-text noFocus text-xs"
                    type="text"
                    v-model="form.amalgamationEntityBrn"
                    required>
                </b-form-input>
              </b-form-group>
            </div>


            <div class="mb-2 text-gray text-xs">
              <label class="">Companies To Be Amalgamated</label>
              <div v-for="(lst, idx) in form.companiesToBeAmalgamated" :key="idx">
                <table class="w-100">
                  <tr>
                    <td>{{(idx + 1)}}</td>
                    <td>
                      <b-form-group>
                        <b-form-input
                            maxlength="14"
                            @keypress="onlyNumberValidator($event)"
                            style="color: #505050;"
                            id="companyName"
                            disabled
                            class="form-text noFocus text-xs"
                            type="text"
                            placeholder="BRN"
                            v-model="lst.brn"
                            required>
                        </b-form-input>
                      </b-form-group>
                    </td>
                  </tr>
                </table>
              </div>
            </div>





          </form>
        </b-col>
      </b-row>



    </form>
  </div>
</template>

<script>

import {api} from "@/app/services/api.service";

export default {
  name: "AmalgamationMetaData",
  props:{
    draftData:{}
  },
  data(){
    return{
      loading:false,
      searchLoading:false,
      brn: null,
      trackingNo: null,
      form: {
        companiesToBeAmalgamated: [
          {
            brn: null
          }
        ],
        amalgamationEntityBrn: null,
      }

    }
  },
  mounted() {
    this.filterDraft()
    // this.form.amalgamationEntityBrn = this.$userProfile.brn
  },
  methods:{
    filterDraft(){
      let data = this.draftData;
      // this.form.newNominalNumberOfShares = metaData.newNominalNumberOfShares ? metaData.newNominalNumberOfShares : null
      // this.form.newNominalShareCapital = metaData.newNominalShareCapital ?  metaData.newNominalShareCapital : null
      // this.form.newNominalShareClassifications = metaData.newNominalShareClassifications ?  this.returnClassClassification(metaData.newNominalShareClassifications) : this.newClassifications
      // this.routine()

      if (data) {
        // this.loading = false;
        // this.draftData = data;
        // this.trackingNo = data.trackingNo;
        let metaData = data.AMALGAMATION_METADATA.data;
        this.form.amalgamationEntityBrn = metaData.amalgamationEntityBrn
        let info = metaData.companiesToBeAmalgamated.map(item => ({ brn: item.brn }));
        info = info.filter(item => item.brn !== this.form.amalgamationEntityBrn);
        this.form.companiesToBeAmalgamated = info
      }


    },
    addCompanyBrn(){
      this.form.companiesToBeAmalgamated.push({
        brn: null
      })
    },
    prev(){
      this.$emit("backTab");
    },
    onSubmit(){
        this.loading = true
      let formData = {}
      formData.form = this.form
      formData.trackingNo = this.trackingNo
        this.saveMetaData(formData)
    },
    async saveMetaData(data) {
      await api().post("/incorporation/companies/continuous-filing/file-amalgamation/submit-metadata", data)
          .then((response) => {
            if(response) {
              let trackingNo = response.data.data
              sessionStorage.setItem("tracking-number", trackingNo)
              this.$emit("nextTab", trackingNo);
              this.loading = false;
            }
          })
          .catch((error) => {
            if(error && error.response) {
              this.loading = false;
              this.errorToast("Error", error.response.data.message);
            }
          });
    },

  },
  watch:{
    draftData(data) {
      if (data) {
        this.filterDraft()
      }
    },
  /*  getFilingDraftDataInfo(data) {
      if (data) {
        this.loading = false;
        this.draftData = data;
        this.trackingNo = data.trackingNo;
        let metaData = data.embeddedChanges.AMALGAMATION_METADATA.data;
        this.form.amalgamationEntityBrn = metaData.amalgamationEntityBrn
        let info = metaData.companiesToBeAmalgamated.map(item => ({ brn: item.brn }));
        info = info.filter(item => item.brn !== this.form.amalgamationEntityBrn);
        this.form.companiesToBeAmalgamated = info
      }
    }*/
  }
}
</script>

<style scoped>

.resolution {
  @apply border border-header_grey shadow px-2 pt-3 pb-5 my-2 rounded mb-4;
}

.inert-button {
  @apply  bg-gray text-white py-2 rounded;
}

.button {
  @apply bg-blue text-white py-2 rounded;
}

.button:hover {
  @apply bg-blue-dark;
}

.back-button {
  @apply border border-gray text-gray py-2 px-4 rounded;
}

.back-button:hover {
  @apply bg-gray text-white;
}

.alertStyle {
  border: 1px solid red;
}
</style>