import { render, staticRenderFns } from "./BSIMembers.vue?vue&type=template&id=4a16fff8&scoped=true&"
import script from "./BSIMembers.vue?vue&type=script&lang=js&"
export * from "./BSIMembers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a16fff8",
  null
  
)

export default component.exports