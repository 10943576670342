<template>
  <div>
    <b-row class="my-3">
      <b-col cols="12">
        <b-row v-if="application != null">
          <b-col cols="12">
            <b-row class="mt-2">
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray"
                       style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Secretary</td>
                  </tr>
                  </thead>
                  <tbody>
                  <LegalPersons class="p-1" :persons="[{individual:application.secretary,entityType:'INDIVIDUAL'}]"/>
                  </tbody>
                </table>
              </b-col>
            </b-row>

              <b-row class="mt-2" v-if="application.directors.length > 0">
              <b-col cols="12">
                <table class="min-w-full leading-normal mt-1 text-gray"
                       style="border: solid 1px #dedede;">
                  <thead>
                  <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Directors</td>
                  </tr>
                  </thead>
                  <tbody>
                   <BSIMembers class="p-1" :persons="application.directors"/>
                  </tbody>
                </table>
              </b-col>
            </b-row>

              <b-row class="mt-2" v-if="application.committeeMembers.length > 0">
                  <b-col cols="12">
                      <table class="min-w-full leading-normal mt-1 text-gray"
                             style="border: solid 1px #dedede;">
                          <thead>
                          <tr class="bg-gray-pale">
                              <td colspan="12" class="px-2 py-2 font-bold">Committee Members</td>
                          </tr>
                          </thead>
                          <tbody>
                          <BSIMembers class="p-1" :persons="application.committeeMembers"/>
                          </tbody>
                      </table>
                  </b-col>
              </b-row>


<!--            <b-row>-->
<!--              <b-col cols="12" class="mt-3">-->
<!--                <table class="min-w-full leading-normal mt-1 text-gray"-->
<!--                       style="border: solid 1px #dedede;">-->
<!--                  <thead>-->
<!--                  <tr class="bg-gray-pale">-->
<!--                    <td colspan="12" class="px-2 py-2 font-bold">Society Address</td>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                    <b-row class="mx-1 py-1">-->
<!--                      <b-col>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Street"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus text-capitalize"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.address.address.street"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
<!--                      <b-col>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Plot"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus text-capitalize"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.address.address.plot"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
<!--                      <b-col>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Postal Address"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus text-capitalize"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.address.address.postalAddress"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
<!--                    </b-row>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--                <table class="min-w-full leading-normal text-gray"-->
<!--                       style="border: solid 1px #dedede;" v-if="application.meetingPlace.meetingPlacePhysicalAddress">-->
<!--                  <thead>-->
<!--                  <tr class="bg-gray-pale">-->
<!--                    <td colspan="12" class="px-2 py-2 font-bold">Meeting Place</td>-->
<!--                  </tr>-->
<!--                  </thead>-->
<!--                  <tbody>-->
<!--                    <b-row class="mx-1 py-1">-->
<!--                      <b-col>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Region"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.meetingPlace.meetingPlacePhysicalAddress.region.name"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="District"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus text-capitalize"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.meetingPlace.meetingPlacePhysicalAddress.district.name.toLowerCase()"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Sub County"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus text-capitalize"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.meetingPlace.meetingPlacePhysicalAddress.subcounty.name"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
<!--                      <b-col>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Parish"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.meetingPlace.meetingPlacePhysicalAddress.parish.name"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Village"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus text-capitalize"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.meetingPlace.meetingPlacePhysicalAddress.village.name"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Street"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus text-capitalize"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.meetingPlace.meetingPlace.street"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
<!--                      <b-col>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Plot"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus text-capitalize"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.meetingPlace.meetingPlace.plot"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                        <b-form-group-->
<!--                            class="text-sm my-1"-->
<!--                            id="input-group-1"-->
<!--                            label="Postal Address"-->
<!--                            label-for="input-1">-->
<!--                          <b-form-input-->
<!--                              id="companyName"-->
<!--                              class="form-text text-sm noFocus text-capitalize"-->
<!--                              type="text"-->
<!--                              disabled="true"-->
<!--                              :value="application.meetingPlace.meetingPlace.postalAddress"-->
<!--                              required>-->
<!--                          </b-form-input>-->
<!--                        </b-form-group>-->
<!--                      </b-col>-->
<!--                    </b-row>-->
<!--                  </tbody>-->
<!--                </table>-->
<!--              </b-col>-->
<!--            </b-row>-->
            <b-row>
              <b-col cols="6" class="mt-4">
                <table class="min-w-full leading-normal mt-1 text-blue-dark"
                       style="border: solid 1px #bdd7f1;">
                  <thead>
                  <tr class="bg-blue-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Applicant Details</td>
                  </tr>
                  <tr>
                    <th class="px-2 py-1">Name</th>
                    <th class="px-2 py-1">Email</th>
                    <th class="px-2 py-1">Phone</th>
                  </tr>
                  </thead>
                  <tbody>
                  <tr style="border: solid 1px #bdd7f1;">
                    <td class="px-2 py-1 text-capitalize">{{application.applicant.givenName}} {{application.applicant.surname}}</td>
                    <td class="px-2 py-1">{{application.applicant.email}}</td>
                    <td class="px-2 py-1">{{application.applicant.phoneNumber}}</td>
                  </tr>
                  </tbody>
                </table>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import moment from "moment";
import {mapGetters} from "vuex";
import BSIMembers from "@/views/home/services/bsi_incorporation/BSIMembers";
import LegalPersons from "@/views/home/commons/LegalPersons.vue";

export default {
  name: "BsiMetaDataUpdateFormDetails",
  components: {LegalPersons, BSIMembers},
  props: {
    application: Object,
    default: null
  },
    mounted() {
     this.currentApplication()
    },
    methods:{
    dateFormat(date){
      return moment(date).format("DD-MM-YYYY")
    },
    cleanName(name){
      let n = name.replaceAll("_", " ")
      return n.toLowerCase()
    },
      currentApplication() {
          if (this.application === null) {
              return this.getBsiContinuousFilingDetails.data
          } else {
              return this.application
          }
      },
  },
  computed:{
    ...mapGetters(["getBsiContinuousFilingDetails"]),

  },
}
</script>

<style scoped>
th, td{
  font-size: 12px;
}
</style>