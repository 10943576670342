<template>
    <div>
    <b-row class="mb-2">
        <b-col>
            <b-badge class="bg-green mx-1">Incoming Filings <i class="fa fa-arrow-right text-white"></i></b-badge>
            <b-badge class="bg-blue mx-1">
                {{ cleanName(data.filingType) }}
            </b-badge>
            <b-badge class="bg-blue mx-1" v-for="(file,idx) in data.changes" :key="idx">
                {{ cleanName(file.type) }}
            </b-badge>
        </b-col>
    </b-row>
        <b-row v-if="data.filingType ==='RESOLUTION'">
        <b-col>
            <table class="min-w-full leading-normal mt-1 text-gray" style="border: solid 1px #dedede;">
                <thead>
                <tr class="bg-gray-pale">
                    <td colspan="12" class="px-2 py-2 font-bold">Resolution Details</td>
                </tr>
                </thead>
                <tbody>
                <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1 text-capitalize font-bold">Registration Number</td>
                    <td class="px-2 py-1 text-capitalize">{{ data.brn }}</td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1 text-capitalize font-bold">Organ of Company</td>
                    <td class="px-2 py-1 text-capitalize">{{ cleanName(data.organ) }}</td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1 text-capitalize font-bold">Resolution Name</td>
                    <td class="px-2 py-1 text-capitalize">
                        {{ cleanName(data.type) }}
                    </td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1 text-capitalize font-bold">Subject</td>
                    <td class="px-2 py-1 text-capitalize">{{ data.subject }}</td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1 text-capitalize font-bold">Date of Meeting</td>
                    <td class="px-2 py-1 text-capitalize">
                        {{ dateFormat(data.dateOfMeeting) }}
                    </td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1 text-capitalize font-bold">Venue of Meeting</td>
                    <td class="px-2 py-1 text-capitalize">
                        <span v-if="data.venue">{{ data.venue }}</span>
                        <span v-else>N/A</span>
                    </td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1 text-capitalize font-bold">Filing Date</td>
                    <td class="px-2 py-1 text-capitalize">
                        {{ dateFormat(data.createdAt) }}
                    </td>
                </tr>
                <tr style="border: solid 1px #dedede;">
                    <td class="px-2 py-1 text-capitalize font-bold">Presented by</td>
                    <td class="px-2 py-1 text-capitalize">{{ data.applicant.givenName }}
                    </td>
                </tr>
                </tbody>
            </table>
        </b-col>
    </b-row>

        <b-row v-if="data.filingType==='MANAGEMENT_METADATA'">
            <b-col>
                <label class="text-blue text-lg">Update Directors/Management committee</label>
            </b-col>
        </b-row>
        <BsiMetaDataUpdateFormDetails
                v-if="data.filingType==='MANAGEMENT_METADATA'"
                :application="data"
        />


        <div v-if="data.filingType === 'RESOLUTION' && data.changes.length > 0  ">
            <b-row  v-if="data.embeddedChanges.CHANGE_OF_ADDRESS">
                <b-col>
                    <label class="text-blue text-lg">Change of Address</label>
                </b-col>
            </b-row>
            <bsi-change-of-address
                    v-if="data.embeddedChanges.CHANGE_OF_ADDRESS"
                    :data="data.embeddedChanges.CHANGE_OF_ADDRESS.data"
                    :brn="data.brn"
            />
            <b-row  v-if="data.embeddedChanges.CHANGE_IN_MEMBERSHIP">
                <b-col>
                    <label class="text-blue text-lg">Update Membership</label>
                </b-col>
            </b-row>
            <bsi-change-of-membership
                    v-if="data.embeddedChanges.CHANGE_IN_MEMBERSHIP"
                    :data="data.embeddedChanges.CHANGE_IN_MEMBERSHIP.data"
                    :brn="data.brn"
            />

            <b-row  v-if="data.embeddedChanges.ALTERATION_OF_RULES">
                <b-col>
                    <label class="text-blue text-lg">Altered rules </label>
                </b-col>
            </b-row>
            <label  v-if="data.embeddedChanges.ALTERATION_OF_RULES">
                <p>
                    {{data.embeddedChanges.ALTERATION_OF_RULES.data.descriptionOfChanges}}
                </p>

            </label>
        </div>
    </div>
</template>

<script>


import moment from "moment";
import BsiChangeOfAddress from "@/views/home/services/continuous-filing/task/components/BsiChangeOfAddress.vue";
import BsiChangeOfMembership from "@/views/home/services/continuous-filing/task/components/BsiChangeOfMembership.vue";
import BsiMetaDataUpdateFormDetails
    from "@/views/home/services/continuous-filing/task/components/BsiMetaDataUpdateFormDetails.vue";

export default {
  name: "BsiMultiChanges",
  components: {
      BsiMetaDataUpdateFormDetails,
      BsiChangeOfMembership,
      BsiChangeOfAddress
  },
  props:{
    data:Object,
  },
    methods:{
        dateFormat(date){
            return moment(date).format("DD-MM-YYYY")
        },
        cleanName(name){
            if(name){
                let n = name.replaceAll("_", " ")
                return n.toLowerCase()
            }else{
                return "N/A"
            }
        },
    },
};
</script>

<style scoped>

</style>