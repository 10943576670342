import { render, staticRenderFns } from "./BsiChangeOfAddress.vue?vue&type=template&id=68675c8d&scoped=true&"
import script from "./BsiChangeOfAddress.vue?vue&type=script&lang=js&"
export * from "./BsiChangeOfAddress.vue?vue&type=script&lang=js&"
import style0 from "./BsiChangeOfAddress.vue?vue&type=style&index=0&id=68675c8d&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68675c8d",
  null
  
)

export default component.exports